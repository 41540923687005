import { ENABLE_LOGGER } from 'config';
import { MAINTENANCE } from 'const/Router';
import { DowntimeMapper } from 'helpers';
import {
  IFormData,
  IMaintenance,
  IMaintenanceStatus,
  IMaintenanceStore,
  INewMaintenance,
  IRootStore
} from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { extendedPalette } from 'theme';

import { BaseStore } from './BaseStore';

export class MaintenanceStore extends BaseStore implements IMaintenanceStore {
  public maintenance: IMaintenance[] = [];
  public maintenanceStatusMap: Map<string, IMaintenanceStatus> = new Map();
  public currentMaintenance: IFormData = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      maintenance: observable,
      maintenanceStatusMap: observable,
      currentMaintenance: observable,
      deleteMaintenance: action.bound,
      getMaintenance: action.bound,
      createMaintenance: action.bound,
      getMaintenanceById: action.bound,
      initializeHighlightedRowStyles: action.bound,
      updateMaintenance: action.bound,
      getMaintenanceStatus: action.bound,
      isMaintenanceLoading: computed,
      isCurrentMaintenanceLoading: computed
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public async getMaintenance(): Promise<void> {
    this.setLoading('getMaintenance');
    try {
      const maintenanceItems = await this.rootStore.requester.maintenanceService.getAll();
      if (maintenanceItems) {
        const activeIds = DowntimeMapper.toActiveMaintenanceIds(maintenanceItems);
        this.rootStore.tableDataManager.updateHighlightedRowsIds(activeIds);

        runInAction(() => {
          this.maintenance = maintenanceItems;
          this.setSuccess('getMaintenance');
        });
      }
    } catch (e) {
      this.setError('getMaintenance');
      this.errorHandler(e);
    }
  }

  public async deleteMaintenance(id: string): Promise<void> {
    this.setLoading('deleteMaintenance');
    try {
      await this.rootStore.requester.maintenanceService.delete(id);
      await this.getMaintenance();

      runInAction(() => {
        this.setSuccess('deleteMaintenance');
      });

      this.rootStore.routeStore.navigate(MAINTENANCE);
    } catch (e) {
      this.setError('deleteMaintenance');
      this.errorHandler(e);
    }
  }

  public async createMaintenance(formData: IFormData): Promise<void> {
    this.setLoading('createMaintenance');
    try {
      const data = DowntimeMapper.toNewMaintenance(formData);
      await this.rootStore.requester.maintenanceService.create<INewMaintenance>(data);
      await this.getMaintenance();

      runInAction(() => {
        this.setSuccess('createMaintenance');
      });

      this.rootStore.routeStore.navigate(MAINTENANCE);
    } catch (e) {
      this.setError('createMaintenance');
      this.errorHandler(e);
    }
  }

  public async getMaintenanceById(id: string): Promise<void> {
    this.setLoading('getMaintenanceById');
    try {
      const maintenance = (await this.rootStore.requester.maintenanceService.getById(id)) as IMaintenance;
      const formData = DowntimeMapper.toFormData(maintenance);
      runInAction(() => {
        this.currentMaintenance = formData;
        this.setSuccess('getMaintenanceById');
      });
    } catch (e) {
      this.rootStore.routeStore.navigate(MAINTENANCE);
      this.setError('getMaintenanceById');
      this.errorHandler(e);
    }
  }

  public async updateMaintenance(formDate: IFormData, id: number): Promise<void> {
    this.setLoading('updateMaintenance');
    try {
      const data = DowntimeMapper.toUpdatedMaintenance(formDate, id);
      await this.rootStore.requester.maintenanceService.update(id.toString(), data);

      runInAction(() => {
        this.setSuccess('updateMaintenance');
      });

      this.rootStore.routeStore.navigate(MAINTENANCE);
    } catch (e) {
      this.setError('updateMaintenance');
      this.errorHandler(e);
    }
  }

  public async getMaintenanceStatus(): Promise<void> {
    this.setLoading('getMaintenanceStatus');
    try {
      const response = await this.rootStore.requester.maintenanceService.getStatus();

      if (response) {
        if (this.maintenanceStatusMap.size) this.maintenanceStatusMap.clear();

        const normalizedResponse = DowntimeMapper.toStatus(response);
        for (const status of normalizedResponse) {
          this.maintenanceStatusMap.set(status.name, status);
        }
      }
      this.setSuccess('getMaintenanceStatus');
    } catch (e) {
      this.setError('getMaintenanceStatus');
      this.errorHandler(e);
    }
  }

  public initializeHighlightedRowStyles(): void {
    this.rootStore.tableDataManager.setRowStyles({
      color: '#fff',
      backgroundColor: extendedPalette.error
    });
  }

  public get isMaintenanceLoading(): boolean {
    return (
      this.getAsyncStatus('getMaintenance').loading ||
      this.getAsyncStatus('deleteMaintenance').loading ||
      this.getAsyncStatus('createMaintenance').loading
    );
  }

  public get isCurrentMaintenanceLoading(): boolean {
    return this.getAsyncStatus('getMaintenanceById').loading;
  }
}
