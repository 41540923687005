import { DEFAULT_SELECT_VALUE } from 'const';
import { ESelectOptionsData } from 'enums';
import { IBaseEntity } from 'interfaces';
import { SelectData } from 'mui-rff';

type TType =
  | ESelectOptionsData.CARRIER
  | ESelectOptionsData.CUSTOMER
  | ESelectOptionsData.CUSTOMER_SUPPLIER
  | ESelectOptionsData.DISTRIBUTION_CENTER
  | ESelectOptionsData.ORGANIZATION_TYPE
  | ESelectOptionsData.ORGANIZATION_NAME
  | ESelectOptionsData.SUPPLIER
  | ESelectOptionsData.VEHICLE_TYPE;

const prepareLabel = (item: IBaseEntity, type: TType): string => {
  switch (type) {
    case ESelectOptionsData.ORGANIZATION_TYPE:
      return item.id.toString();
    case ESelectOptionsData.VEHICLE_TYPE:
      return item.description;
    case ESelectOptionsData.CARRIER:
    case ESelectOptionsData.CUSTOMER_SUPPLIER:
      return item.orgName;
    default:
      return item.name;
  }
};

const prepareValue = (item: IBaseEntity, type: TType): string => {
  switch (type) {
    case ESelectOptionsData.VEHICLE_TYPE:
      return item.displayCode;
    case ESelectOptionsData.DISTRIBUTION_CENTER:
      return item.name;
    case ESelectOptionsData.CARRIER:
    case ESelectOptionsData.CUSTOMER_SUPPLIER:
      return item.orgId?.toString() || '';
    default:
      return item.id;
  }
};

export const createSelectOptionsData = (
  dataMap: Map<string, IBaseEntity>,
  type: TType,
  withoutDefault = false
): SelectData[] => {
  const data = [...dataMap.values()];
  const selectData: SelectData[] = withoutDefault ? [] : [DEFAULT_SELECT_VALUE];

  for (const item of data) {
    selectData.push({
      value: prepareValue(item, type),
      label: prepareLabel(item, type)
    });
  }

  return selectData;
};
