import { AxiosInstance, AxiosResponse } from 'axios';
import { ITenant } from 'interfaces';

import { BaseApiService } from './BaseApiService';

export class TenantService extends BaseApiService<ITenant> {
  constructor(instance: AxiosInstance, url: string) {
    super(instance, url);
  }

  public async getUserTenants(userId: number): Promise<ITenant[]> {
    return await this.instance.get(`${this.url}/${userId}`).then((res: AxiosResponse<ITenant[]>) => {
      return res.data;
    });
  }
}
