import { EStockFilter } from 'enums';
import { IStockFilterDTO } from 'interfaces';

const filters: Record<string, IStockFilterDTO> = {
  [EStockFilter.VIEW_STOCK]: {},
  [EStockFilter.BLOCK_STOCK]: {
    owner: 'SUPPLIER'
  },
  [EStockFilter.CODE_DISPENSATION]: {
    lastShipmentDate: ['TODAY', 'TOMORROW']
  },
  [EStockFilter.QUARANTAINE_MANAGEMENT]: {
    isQuarantinable: true,
    owner: 'SUPPLIER'
  }
};

export const createStockFilter = (type: string, supplierId?: string): IStockFilterDTO => {
  if (supplierId && !+supplierId) return;

  return supplierId ? { supplierId: +supplierId } : filters[type];
};
