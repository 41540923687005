export const debounce = (func: () => void, timeout: number): any => {
  let timer: any;
  return (...args: []): any => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
