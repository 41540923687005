import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { IItemLvList, IItemManagementStore, IMasterData, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { BaseStore } from './BaseStore';

export class ItemManagementStore extends BaseStore implements IItemManagementStore {
  public updatedAt: number = null;
  public masterData: Map<string, IMasterData> = new Map();

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      updatedAt: observable,
      masterData: observable,
      getMasterData: action.bound,
      updateMasterData: action.bound,
      downloadMasterData: action.bound,
      isMasterDataLoading: computed,
      isUpdateMasterDataLoading: computed
    });
  }

  public async getMasterData(): Promise<void> {
    this.setLoading('getMasterData');
    try {
      const masterData = await this.rootStore.requester.itemManagementService.getMasterData();
      if (masterData) {
        runInAction(() => {
          this.updatedAt = Date.now();

          this.masterData.clear();
          for (const item of masterData) {
            this.masterData.set(item.colloGtin.toString(), item);
          }

          this.setSuccess('getMasterData');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getMasterData');
    }
  }

  public get isMasterDataLoading(): boolean {
    return this.getAsyncStatus('getMasterData').loading;
  }

  public get isUpdateMasterDataLoading(): boolean {
    return this.getAsyncStatus('updateMasterData').loading;
  }

  public async updateMasterData(rows: IMasterData[], options: Partial<IMasterData>): Promise<void> {
    this.setLoading('updateMasterData');
    const updatedRows: IItemLvList[] = rows.map(row => {
      // eslint-disable-next-line @typescript-eslint/ban-types
      const optional = (
        key: keyof IMasterData,
        isString: boolean = false
      ): Record<string, string | number | boolean | Function> => {
        const isAvailable = options.hasOwnProperty(key);
        if (!isAvailable) return;
        const value = options[key] ?? row[key];
        return { [key]: isString && value === '' ? row[key] : value };
      };

      return {
        activityCode: row.activityCode,
        itemCode: row.itemCode,
        itemLvCode: row.itemLvCode,
        retour: options.retour !== '' ? options.retour : row.retour,
        ...optional('quarantined'),
        ...optional('tht_tgt', true),
        ...optional('bioProduct'),
        ...optional('eegProduct'),
        ...optional('batchNumber')
      };
    });
    try {
      const masterData = await this.rootStore.requester.itemManagementService.updateMasterDate({
        itemLvList: updatedRows
      });
      await this.getMasterData();

      if (masterData) {
        runInAction(() => {
          this.setSuccess('updateMasterData');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('updateMasterData');
    }
  }

  public async downloadMasterData(): Promise<void> {
    this.setLoading('updateMasterData');

    try {
      const data = await this.rootStore.requester.itemManagementService.downloadMasterDate();
      const blob = new Blob([data], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(blob, `export_master_data_${dayjs().format('YYYY-MM-DD')}.csv`);

      runInAction(() => {
        this.setSuccess('downloadMasterData');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('downloadMasterData');
    }
  }
}
