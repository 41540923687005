export const enum EStatus {
  DISPATCH = 'DISPATCH',
  FORECAST = 'FORECAST',
  FIN = 'FIN',
  CAI = 'CAI',
  CAN = 'CAN',
  ALLOWED = 'ALLOWED',
  PLH = 'PLH',
  SDF = 'SDF'
}
