import { ENABLE_LOGGER } from 'config';
import { ESelectOptionsData } from 'enums';
import { CustomerMapper, createSelectOptionsData } from 'helpers';
import { IBaseEntity, ICustomerStore, ICustomerSupplier, IError, IFormSubmitData, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { SelectData } from 'mui-rff';

import { BaseStore } from './BaseStore';

export class CustomerStore extends BaseStore implements ICustomerStore {
  public customersMap: Map<string, IBaseEntity> = new Map();
  public carriersMap: Map<string, ICustomerSupplier> = new Map();
  public customerSuppliersMap: Map<string, ICustomerSupplier> = new Map();
  public isLoading = true;
  public error: IError = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      customersMap: observable,
      carriersMap: observable,
      customerSuppliersMap: observable,
      isLoading: observable,
      error: observable,
      isLoadingCustomers: computed,
      isLoadingCarriers: computed,
      isLoadingSuppliers: computed,
      createCarrier: action.bound,
      createSupplier: action.bound,
      getCustomers: action.bound,
      getCarriers: action.bound,
      getAllCarriers: action.bound,
      getCarriersSelectData: action.bound,
      getCustomerSuppliers: action.bound,
      getCustomersSelectData: action.bound,
      getCustomerSupliersSelectData: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get isLoadingCustomers(): boolean {
    return this.getAsyncStatus('getCustomers').loading;
  }

  public get isLoadingCarriers(): boolean {
    return this.getAsyncStatus('getCarriers').loading;
  }

  public get isLoadingSuppliers(): boolean {
    return this.getAsyncStatus('getCustomerSuppliers').loading;
  }

  public async createCarrier(data: IFormSubmitData): Promise<void> {
    this.setLoading('createCarrier');
    try {
      const currentTenant = this.rootStore.tenantStore.currentTenantId;
      const supplierId = this.rootStore.authStore.user.tenantOrganizationInfo[currentTenant].organizationId.toString();
      const { body, orgId } = CustomerMapper.toCustomerCarrierDTO(data);
      await this.rootStore.requester.suppliersService.createCarrier(body, supplierId, orgId);
      this.getCarriers();
      this.setSuccess('createCarrier');
    } catch (e) {
      this.errorHandler(e);
      this.setError('createCarrier');
    }
  }

  public async createSupplier(data: IFormSubmitData): Promise<void> {
    this.setLoading('createSupplier');
    try {
      const { currentCustomerId } = this.rootStore.customerManager;
      const { body, orgId, supplierId } = CustomerMapper.toCustomerSupplierDTO(data, currentCustomerId);
      await this.rootStore.requester.customerService.createSupplier(body, orgId, supplierId);
      this.getCustomerSuppliers();
      this.setSuccess('createSupplier');
    } catch (e) {
      this.errorHandler(e);
      this.setError('createSupplier');
    }
  }

  public async getCustomers(): Promise<void> {
    this.setLoading('getCustomers');
    try {
      const response = await this.rootStore.requester.customerService.getAll();
      runInAction(() => {
        for (const supplier of response) {
          this.customersMap.set(supplier.id.toString(), supplier);
        }
        this.setSuccess('getCustomers');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getCustomers');
    }
  }

  public async getCarriers(): Promise<void> {
    this.setLoading('getCarriers');
    try {
      const currentTenant = this.rootStore.tenantStore.currentTenantId;
      const supplierId = this.rootStore.authStore.user.tenantOrganizationInfo[currentTenant].organizationId.toString();
      const response = await this.rootStore.requester.suppliersService.getAllCarriers(supplierId);
      runInAction(() => {
        if (this.carriersMap.size) this.carriersMap = new Map();

        for (const carrier of response.authorizedOrgs) {
          this.carriersMap.set(carrier.orgId.toString(), CustomerMapper.toCustomerSupplier(carrier));
        }
        this.setSuccess('getCarriers');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getCarriers');
    }
  }

  public async getAllCarriers(): Promise<void> {
    this.setLoading('getCarriers');
    try {
      const response = await this.rootStore.requester.carrierService.getAll();
      runInAction(() => {
        if (this.customersMap.size) this.customersMap = new Map();

        for (const carrier of response) {
          this.customersMap.set(carrier.id.toString(), carrier);
        }
        this.setSuccess('getCarriers');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getCarriers');
    }
  }

  public async getCustomerSuppliers(isCodeDispensation: boolean = false): Promise<void> {
    this.setLoading('getCustomerSuppliers');
    try {
      const { currentCustomerId } = this.rootStore.customerManager;
      const { currentTenantId } = this.rootStore.tenantStore;
      const orgId =
        this.rootStore.userStore.userProfile.tenantOrganizationInfo[currentTenantId].organizationId.toString();
      const response = await this.rootStore.requester.customerService.getSuppliers(
        isCodeDispensation ? orgId : currentCustomerId
      );
      runInAction(() => {
        if (this.customerSuppliersMap.size) this.customerSuppliersMap = new Map();

        for (const supplier of response.authorizedOrgs) {
          this.customerSuppliersMap.set(supplier.orgId.toString(), CustomerMapper.toCustomerSupplier(supplier));
        }
        this.setSuccess('getCustomerSuppliers');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getCustomerSuppliers');
    }
  }

  public getCustomersSelectData(): SelectData[] {
    return createSelectOptionsData(this.customersMap, ESelectOptionsData.CUSTOMER, true);
  }

  public getCarriersSelectData(): SelectData[] {
    return createSelectOptionsData(this.customersMap, ESelectOptionsData.CARRIER);
  }

  public getCustomerSupliersSelectData(): SelectData[] {
    return createSelectOptionsData(this.customerSuppliersMap, ESelectOptionsData.CUSTOMER_SUPPLIER);
  }
}
