import { AxiosInstance, AxiosResponse } from 'axios';
import { EditOrderDTO, LoadBaseDTO, LoadDTO, MoveOrderDTO, OrderDTO, PlaceholderDTO, UpdateOrdersDTO } from 'dtos';
import { IBaseObject } from 'interfaces';

import { BaseApiService } from './BaseApiService';
export class TransportService extends BaseApiService<LoadBaseDTO> {
  private additionalUrl: string = null;

  constructor(instance: AxiosInstance, url: string, additionalUrl: string) {
    super(instance, url);
    this.additionalUrl = additionalUrl;
  }

  public async getLoads(params?: IBaseObject): Promise<LoadDTO[]> {
    return await this.instance.get(this.url, { params }).then(({ data }: AxiosResponse<any>) => data);
  }

  public async createPlaceholder(body: PlaceholderDTO): Promise<PlaceholderDTO> {
    return await this.instance
      .post(`${this.url}/placeholder`, body)
      .then(({ data }: AxiosResponse<PlaceholderDTO>) => data);
  }

  public async getByIdAndDate(loadDate: string, loadCode: string): Promise<LoadDTO> {
    return await this.instance
      .get(`${this.url}/${loadDate}/${loadCode}`)
      .then(({ data }: AxiosResponse<LoadDTO>) => data);
  }

  public async getUnlinkedOrders(params?: IBaseObject): Promise<OrderDTO[]> {
    return await this.instance
      .get(this.additionalUrl, { params })
      .then(({ data }: AxiosResponse<any>) => data?.orphans || []);
  }

  public async updateLoad(body: LoadBaseDTO): Promise<LoadDTO> {
    return await this.instance.put(this.url, body).then(({ data }: AxiosResponse<LoadDTO>) => data);
  }

  public async deleteLoad(loadDate: string, loadCode: string): Promise<OrderDTO[]> {
    return await this.instance
      .delete(`${this.url}/${loadDate}/${loadCode}`)
      .then(({ data }: AxiosResponse<any>) => data?.orphans || []);
  }

  public async moveOrder(body: MoveOrderDTO): Promise<LoadDTO> {
    return await this.instance.post(`${this.url}/orders/move`, body).then(({ data }: AxiosResponse<any>) => data);
  }

  public async moveOrderFromUnlinked(body: MoveOrderDTO): Promise<LoadDTO> {
    return await this.instance.post(`${this.additionalUrl}/move`, body).then(({ data }: AxiosResponse<any>) => data);
  }

  public async unlinkOrder(body: MoveOrderDTO): Promise<LoadDTO> {
    return await this.instance.post(this.additionalUrl, body).then(({ data }: AxiosResponse<any>) => data);
  }

  public async cancelOrder(body: MoveOrderDTO | EditOrderDTO): Promise<LoadDTO | OrderDTO> {
    return await this.instance.put(`${this.additionalUrl}/cancel`, body).then(({ data }: AxiosResponse<any>) => data);
  }

  public async updateOrder(body: EditOrderDTO): Promise<OrderDTO> {
    return await this.instance.put(`${this.additionalUrl}/update`, body).then(({ data }: AxiosResponse<any>) => data);
  }

  public async updateOrders(body: UpdateOrdersDTO): Promise<LoadDTO> {
    return await this.instance.put(`${this.url}/orders`, body).then(({ data }: AxiosResponse<LoadDTO>) => data);
  }
}
