import { AuthNewSupplierDTO } from 'dtos';
import { ICustomerSupplier, IFormSubmitData, IMapper } from 'interfaces';

interface ISupplierCreate {
  body: AuthNewSupplierDTO;
  orgId: string;
  supplierId?: string;
}

export class CustomerMapper implements IMapper<ICustomerSupplier> {
  static toCustomerSupplier(data: ICustomerSupplier): ICustomerSupplier {
    return {
      ...data,
      isEditLastShipmentDate: data.authorizations.includes('AUTH_EDIT_LAST_SHIPMENT_DATE'),
      isEditSalvationArmy: data.authorizations.includes('AUTH_EDIT_SALVATION_ARMY'),
      isPlanAppointments: data.authorizations.includes('AUTH_PLAN_APPOINTMENTS')
    };
  }

  static toCustomerSupplierDTO({ formData }: IFormSubmitData, currentCustomerId: string): ISupplierCreate {
    return {
      body: {
        authorizations: formData.authorizations as string[]
      },
      orgId: currentCustomerId,
      supplierId: formData.orgId?.toString()
    };
  }

  static toCustomerCarrierDTO({ formData }: IFormSubmitData): ISupplierCreate {
    return {
      body: {
        authorizations: formData.authorizations as string[]
      },
      orgId: formData.orgId?.toString()
    };
  }
}
