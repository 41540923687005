import { AxiosInstance, AxiosResponse } from 'axios';
import { ProfileDTO, UserDTO } from 'dtos';

import { BaseApiService } from './BaseApiService';

export class UserService extends BaseApiService<UserDTO> {
  private additionalUrl: string = null;

  constructor(instance: AxiosInstance, url: string, additionalUrl: string) {
    super(instance, url, {
      createUrl: `${additionalUrl}/users`
    });
    this.additionalUrl = additionalUrl;
  }

  public async getProfile(): Promise<ProfileDTO> {
    return await this.instance
      .get(`${this.additionalUrl}/userProfile`)
      .then(({ data }: AxiosResponse<ProfileDTO>) => data);
  }

  public async updateProfile(body: ProfileDTO): Promise<ProfileDTO> {
    return await this.instance
      .put(`${this.additionalUrl}/userProfile`, body)
      .then(({ data }: AxiosResponse<ProfileDTO>) => data);
  }

  public async deleteUser(id: string): Promise<void> {
    return await this.instance
      .delete(`${this.additionalUrl}/users/${id}`)
      .then(({ data }: AxiosResponse<void>) => data);
  }
}
