import { IExtendedPalette } from 'interfaces';

export const extendedPalette: IExtendedPalette = {
  frenchGray: '#babac0',
  santasGray: '#a0a0a5',
  wildSand: '#f4f4f4',
  gxoOrange: '#ff3a00',
  darkOrange: '#e52900',
  darkGrey: '#2e3033',
  gxoBlack: '#1c1c1c',
  lightGray: '#f0f0f0',
  gray: '#e0e0e0',
  darkBlue: '#0f1016',
  greyBlue: '#2E3344',
  grey: '#a3a1ad',
  hoveredBlue: '#2e2c3d',
  paper: 'rgba(255, 255, 255, 0.85)',
  transparentRed: '#dc2f34cc',
  cardTitle: '#333',
  borderColor: '#dee5e7',
  alto: '#d8d8d8',
  tableHead: 'rgba(25, 118, 210, 0.12)',
  chartSuccess: '#27c24c',
  chartWarning: '#fad733',
  chartLayer: '#dbdbdb',
  silver: '#cccccc',
  badge: '#337ab7',
  white: '#ffffff',
  success: '#5ab05f',
  warning: '#fad733',
  error: '#cc0000',
  profileName: '#9f5557',
  modalTitleColor: '#5d5d5f',
  modalBorder: '#777776',
  dangerHighlight: '#fce7d6',
  slotbookingGreen: '#70ad46',
  slotbookingDarkGreen: '#528235',
  slotbookingBlue: '#1872c1',
  slotbookingYellow: '#fcc000',
  slotbookingRed: '#f80701',
  slotbookingGrey: '#d9d9d9',
  slotbookingDefault: 'transparent',
  slotbookingTitle: '#e27806'
};
