export { DEFAULT_SELECT_VALUE as DEFAULT_SELECT_VALUE } from './select-data';

export const SEPARATOR: string = '_';
export const LOCAL_STORAGE_PREFIX = 'GXO';
export const AUTH_HEADER = 'x-auth-token';
export const LIST_ITEM_HEIGHT: number = 130;
export const NEW_PASSWORD_REQUIRED: string = 'NEW_PASSWORD_REQUIRED';
export const APP_BASE_TITLE: string = 'GXO';
export const AUTO_HIDE_DURATION: number = 6000;
export const TM_LOADS_IN_VIEW: number = 5;
