import { API_URL, IS_DEV } from 'config';

// Mocks
export const EXAMPLE_MOCK_URL: string = '/api/example.json';

// API
export interface IMakeAPIUrls {
  BASE_API_URL: string;
  CARRIER_BASE_URL: string;
  CUSTOMERS_BASE_URL: string;
  CUSTOMERS_URL_WITHOUT_TENANT: string;
  DISTRIBUTION_CENTERS_BASE_URL: string;
  ORGANIZATION_BASE_URL: string;
  ORGANIZATION_TENANT_URL: string;
  TRANSPORT_BASE_URL: string;
  UNLINKED_ORDERS_BASE_URL: string;
  USER_BASE_URL: string;
  APPOINTMENTS_BASE_URL: string;
  APPOINTMENTS_URL_WITHOUT_TENANT: string;
  SUPPLIERS_BASE_URL: string;
  SUPPLIERS_URL_WITHOUT_TENANT: string;
  CONTACT_BASE_URL: string;
  DOWNLOADS_BASE_URL: string;
  NEWS_BASE_URL: string;
  TENANT_BASE_URL: string;
  MASTERDATA_BASE_URL: string;
  VEHICLE_TYPES_BASE_URL: string;
  LOGISTICAL_GUIDANCE_BASE_URL: string;
  STOCK_BASE_URL: string;
  REPORTS_BASE_URL: string;
  REPORTS_URL_WITHOUT_TENANT: string;
  MAILINGLISTS_BASE_URL: string;
  MAILINGLISTS_WITHOUT_TENANT_URL: string;
  MAINTENANCE_BASE_URL: string;
  MAINTENANCE_STATUS_URL: string;
  SLOTBOOKING_BASE_URL: string;
}

export const makeAPIUrls = (tenantId: number): IMakeAPIUrls => {
  return {
    BASE_API_URL: IS_DEV ? '/api' : API_URL,
    CARRIER_BASE_URL: IS_DEV ? `/api/${tenantId}/carriers` : `${API_URL}/${tenantId}/carriers`,
    CUSTOMERS_BASE_URL: IS_DEV ? `/api/${tenantId}/customers` : `${API_URL}/${tenantId}/customers`,
    CUSTOMERS_URL_WITHOUT_TENANT: IS_DEV ? '/api/customers' : `${API_URL}/customers`,
    DISTRIBUTION_CENTERS_BASE_URL: IS_DEV
      ? `/api/${tenantId}/distributionCenters`
      : `${API_URL}/${tenantId}/distributionCenters`,
    ORGANIZATION_BASE_URL: IS_DEV ? '/api/organizations' : `${API_URL}/organizations`,
    TRANSPORT_BASE_URL: IS_DEV ? `/api/${tenantId}/transport/loads` : `${API_URL}/${tenantId}/transport/loads`,
    UNLINKED_ORDERS_BASE_URL: IS_DEV ? `/api/${tenantId}/orphans` : `${API_URL}/${tenantId}/orphans`,
    ORGANIZATION_TENANT_URL: IS_DEV ? `/api/${tenantId}/organizations` : `${API_URL}/${tenantId}/organizations`,
    USER_BASE_URL: IS_DEV ? `/api/${tenantId}/users` : `${API_URL}/${tenantId}/users`,
    APPOINTMENTS_BASE_URL: IS_DEV ? `/api/${tenantId}/appointments` : `${API_URL}/${tenantId}/appointments`,
    APPOINTMENTS_URL_WITHOUT_TENANT: IS_DEV ? '/api/appointments' : `${API_URL}/appointments`,
    SUPPLIERS_BASE_URL: IS_DEV ? `/api/${tenantId}/suppliers` : `${API_URL}/${tenantId}/suppliers`,
    SUPPLIERS_URL_WITHOUT_TENANT: IS_DEV ? '/api/suppliers' : `${API_URL}/suppliers`,
    CONTACT_BASE_URL: IS_DEV ? `/api/${tenantId}/contact` : `${API_URL}/${tenantId}/contact`,
    DOWNLOADS_BASE_URL: IS_DEV ? `/api/${tenantId}/documents` : `${API_URL}/${tenantId}/documents`,
    NEWS_BASE_URL: IS_DEV ? `/api/${tenantId}/newsitems` : `${API_URL}/${tenantId}/newsitems`,
    TENANT_BASE_URL: IS_DEV ? '/api/tenants' : `${API_URL}/tenants`,
    MASTERDATA_BASE_URL: IS_DEV ? `/api/${tenantId}/masterdata` : `${API_URL}/${tenantId}/masterdata`,
    VEHICLE_TYPES_BASE_URL: IS_DEV
      ? `/api/${tenantId}/loadingVehicleTypes`
      : `${API_URL}/${tenantId}/loadingVehicleTypes`,
    LOGISTICAL_GUIDANCE_BASE_URL: IS_DEV
      ? `/api/${tenantId}/logisticalguidance`
      : `${API_URL}/${tenantId}/logisticalguidance`,
    STOCK_BASE_URL: IS_DEV ? `/api/${tenantId}/stock` : `${API_URL}/${tenantId}/stock`,
    REPORTS_BASE_URL: IS_DEV ? `/api/${tenantId}/reports` : `${API_URL}/${tenantId}/reports`,
    REPORTS_URL_WITHOUT_TENANT: IS_DEV ? '/api/reports' : `${API_URL}/reports`,
    MAILINGLISTS_BASE_URL: IS_DEV ? `/api/${tenantId}/mailinglists` : `${API_URL}/${tenantId}/mailinglists`,
    MAILINGLISTS_WITHOUT_TENANT_URL: IS_DEV ? '/api/mailinglists' : `${API_URL}/mailinglists`,
    MAINTENANCE_BASE_URL: IS_DEV ? `/api/${tenantId}/maintenance` : `${API_URL}/${tenantId}/maintenance`,
    MAINTENANCE_STATUS_URL: IS_DEV ? '/api/maintenance/ongoing' : `${API_URL}/maintenance/ongoing`,
    SLOTBOOKING_BASE_URL: IS_DEV
      ? `/api/${tenantId}/appointments/weekTimeslots`
      : `${API_URL}/${tenantId}/appointments/weekTimeslots`
  };
};
