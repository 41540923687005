import dayjs from 'dayjs';
import { ReceiptsDetailsDTO } from 'dtos';
import { EDate } from 'enums';
import { saveAs } from 'file-saver';
import { ReportsMapper } from 'helpers';
import { IReportsStore, IRootStore } from 'interfaces';
import { action, computed, makeObservable, runInAction } from 'mobx';

import { BaseStore } from './BaseStore';

export class ReportsStore extends BaseStore implements IReportsStore {
  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      downloadStockSupplier: action.bound,
      downloadStockCarrier: action.bound,
      downloadStockTransactions: action.bound,
      downloadReceiptDetails: action.bound,
      downloadDespatchedLoads: action.bound,
      downloadCarrierAppointments: action.bound,
      isLoadingReport: computed
    });
  }

  public async downloadStockSupplier(): Promise<void> {
    this.setLoading('downloadStockSupplier');
    try {
      const owner = ReportsMapper.toStockOwner('SUPPLIER');
      const csvData = await this.rootStore.requester.stockService.downloadStockData(owner);
      const blob = new Blob([csvData], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(blob, `Voorraad - eigenaar leverancier export_stock_${dayjs().format(EDate.YYYY_MM_DD_HHMMSS)}.csv`);

      runInAction(() => {
        this.setSuccess('downloadStockSupplier');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('downloadStockSupplier');
    }
  }

  public async downloadStockCarrier(): Promise<void> {
    this.setLoading('downloadStockCarrier');
    try {
      const params = ReportsMapper.toStockOwner('AHC');
      const csvData = await this.rootStore.requester.stockService.downloadStockData(params);
      const blob = new Blob([csvData], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(blob, `Voorraad - eigenaar Albert Heijn export_stock_${dayjs().format(EDate.YYYY_MM_DD_HHMMSS)}.csv`);

      runInAction(() => {
        this.setSuccess('downloadStockCarrier');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('downloadStockCarrier');
    }
  }

  public async downloadStockTransactions(date: Date): Promise<void> {
    this.setLoading('downloadStockTransactions');
    try {
      const params = ReportsMapper.toStockTransactions(date);

      const csvData = await this.rootStore.requester.stockService.downloadStockTransactions(params);
      const blob = new Blob([csvData], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(
        blob,
        `Voorraadmutaties - eigenaar Leverancier export_stock_transactions_${dayjs().format(
          EDate.YYYY_MM_DD_HHMMSS
        )}.csv`
      );

      runInAction(() => {
        this.setSuccess('downloadStockTransactions');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('downloadStockTransactions');
    }
  }

  public async downloadReceiptDetails(date: Date, reference: string): Promise<void> {
    this.setLoading('downloadReceiptDetails');
    try {
      const filters = ReportsMapper.toReceiptDetails(date, reference);

      const csvData = await this.rootStore.requester.reportsService.downloadReports<ReceiptsDetailsDTO>(2, filters);
      const blob = new Blob([csvData], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(
        blob,
        `Voorraadmutaties - eigenaar Leverancier export_stock_transactions_${dayjs().format(
          EDate.YYYY_MM_DD_HHMMSS
        )}.csv`
      );

      runInAction(() => {
        this.setSuccess('downloadReceiptDetails');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('downloadReceiptDetails');
    }
  }

  public async downloadDespatchedLoads(startDate: Date, endDate: Date): Promise<void> {
    this.setLoading('downloadStockTransactions');
    try {
      const filters = ReportsMapper.toDespatchedLoads(startDate, endDate);
      const csvData = await this.rootStore.requester.reportsService.downloadReports(3, filters);
      const blob = new Blob([csvData], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(
        blob,
        `Voorraadmutaties - eigenaar Leverancier export_stock_transactions_${dayjs().format(
          EDate.YYYY_MM_DD_HHMMSS
        )}.csv`
      );

      runInAction(() => {
        this.setSuccess('downloadStockTransactions');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('downloadStockTransactions');
    }
  }
  /////
  public async downloadCarrierAppointments(startDate: Date, endDate: Date): Promise<void> {
    this.setLoading('downloadCarrierAppointments');
    try {
      const csvData = await this.rootStore.requester.appointmentsService.downloadAppointments(
        dayjs(startDate).format(EDate.YYYY_MM_DD),
        dayjs(endDate).format(EDate.YYYY_MM_DD)
      );

      const blob = new Blob([csvData], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(blob, `Carrier Appointment - ${dayjs().format(EDate.YYYY_MM_DD_HHMMSS)}.csv`);

      runInAction(() => {
        this.setSuccess('downloadCarrierAppointments');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('downloadCarrierAppointments');
    }
  }

  public get isLoadingReport(): boolean {
    return (
      this.getAsyncStatus('downloadStockSupplier').loading ||
      this.getAsyncStatus('downloadStockCarrier').loading ||
      this.getAsyncStatus('downloadStockTransactions').loading ||
      this.getAsyncStatus('downloadReceiptDetails').loading ||
      this.getAsyncStatus('downloadDespatchedLoads').loading
    );
  }
}
