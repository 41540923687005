import { createBrowserHistory } from 'history';
import { IRootStore } from 'interfaces';
import { action, makeObservable, observable } from 'mobx';

import { BaseStore } from './BaseStore';

const history = createBrowserHistory({
  window
});

export class RouteStore extends BaseStore {
  public history = history;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      history: observable,
      navigate: action.bound
    });
  }

  public navigate(url: string): void {
    this.history.push(url);
  }
}
