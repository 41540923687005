import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Notification } from 'components/Notification';
import { useStores } from 'hooks/use-stores';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { theme } from 'theme';

export const muiCache = createCache({
  key: 'mui',
  prepend: true
});

export const Root: React.FC<any> = observer(({ children }: any): JSX.Element => {
  const {
    rootStore: {
      notificationStore: { notification, isOpen, closeMessage }
    }
  } = useStores();

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {children}

        <Notification notification={notification} open={isOpen} handleClose={closeMessage} />
      </ThemeProvider>
    </CacheProvider>
  );
});
