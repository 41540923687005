import { ENABLE_LOGGER } from 'config';
import isEqual from 'fast-deep-equal';
import { IBaseEntity, ICustomerSupplier, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseManager } from './BaseManager';

export class CustomerManager extends BaseManager {
  public currentCustomerId: string = null;
  public currentCustomerSupplierId: string = null;
  public isDeleted: boolean = false;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      currentCustomerId: observable,
      currentCustomerSupplierId: observable,
      isDeleted: observable,
      currentCustomer: computed,
      currentCustomerSupplier: computed,
      setCurrentCustomerId: action.bound,
      setCurrentCustomerSupplierId: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);

    reaction(
      () => this.currentCustomer?.id,
      (value, previousValue, reaction) => {
        if (ENABLE_LOGGER) console.log({ previousValue, value, reaction });
      },
      {
        equals: isEqual
      }
    );
  }

  public get currentCustomer(): IBaseEntity {
    return this.rootStore.customerStore.customersMap.get(this.currentCustomerId) || null;
  }

  public get currentCustomerSupplier(): ICustomerSupplier {
    return this.rootStore.customerStore.customerSuppliersMap.get(this.currentCustomerSupplierId) || null;
  }

  public setCurrentCustomerId(id: string): void {
    this.currentCustomerId = id;
  }

  public setCurrentCustomerSupplierId(id: string): void {
    this.currentCustomerSupplierId = id;
  }
}
