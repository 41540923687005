import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';

const resources = {
  translations: {
    en // import default locale file
  }
};

const instance = i18next.createInstance();

instance
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    compatibilityJSON: 'v3',
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    defaultNS: 'translations',
    // keySeparator: false, // we do not use keys in form messages.welcome
    ns: ['translations'],
    nsSeparator: '',
    interpolation: {
      skipOnVariables: false,
      escapeValue: false // react already safes from xss
    }
  });

export const i18instance = instance;
