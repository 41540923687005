import { AxiosInstance, AxiosResponse } from 'axios';
import { BaseDTO } from 'dtos';

import { BaseApiService } from './BaseApiService';

export class OrganizationService extends BaseApiService<BaseDTO> {
  private additionalUrl: string = null;
  constructor(instance: AxiosInstance, url: string, additionalUrl: string) {
    super(instance, url, { getAllUrl: `${url}/types` });
    this.additionalUrl = additionalUrl;
  }

  public async getByTypeId(id: string, tenantId: number): Promise<BaseDTO[]> {
    return await this.instance
      .get(`${this.additionalUrl}/${tenantId}/organizations/${id}`)
      .then(({ data }: AxiosResponse<BaseDTO[]>) => data);
  }
}
