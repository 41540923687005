import { faArrowsLeftRight } from '@fortawesome/free-solid-svg-icons/faArrowsLeftRight';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faRectangleList } from '@fortawesome/free-solid-svg-icons/faRectangleList';
import { faTachometer } from '@fortawesome/free-solid-svg-icons/faTachometer';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { ETenant } from 'enums';
import { checkRoles, checkTenants } from 'helpers';
import { IMenu } from 'interfaces';

import {
  authorizationCarrierRoles,
  authorizationSupplierRoles,
  calendarRoles,
  codeDispensationRoles,
  dashboardRoles,
  itemManagementRoles,
  logisticalGuidanceRoles,
  mailingListsRoles,
  mailingsListsSupplierRoles,
  maintenanceRoles,
  reportsRoles,
  slotbookingRoles,
  stockManagementRoles,
  transportManagementRoles,
  userManagementRoles
} from './roles';
import {
  AUTHORIZATION_CARRIER,
  AUTHORIZATION_SUPPLIER,
  CALENDAR_ROUTE,
  CODE_DISPENSATION,
  ITEM_MANAGEMENT_ROUTE,
  LOAD_OVERVIEW,
  LOGISTICAL_GUIDANCE,
  MAILINGLISTS,
  MAILINGLISTS_SUPPLIER,
  MAINTENANCE,
  REPORTS,
  ROOT_ROUTE,
  SELECT_SUPPLIER,
  SLOTBOOKING,
  STOCK_MANAGEMENT,
  TRANSPORT_MANAGEMENT,
  UNLINKED_ORDERS,
  USER_MANAGEMENT
} from './Router';

export const createMenu = (roles: string[], currentTenantId: number): IMenu[] => [
  ...(checkRoles(roles, dashboardRoles)
    ? [
        {
          label: 'DASHBOARD',
          path: ROOT_ROUTE,
          icon: faTachometer
        }
      ]
    : []),
  ...(checkRoles(roles, calendarRoles)
    ? [
        {
          label: 'AGENDA',
          path: CALENDAR_ROUTE,
          icon: faCalendar
        }
      ]
    : []),
  ...(checkRoles(roles, slotbookingRoles)
    ? [
        {
          label: 'SLOTBOOKING',
          path: SLOTBOOKING,
          icon: faCalendar
        }
      ]
    : []),
  ...(checkRoles(roles, itemManagementRoles)
    ? [
        {
          label: 'ITEM_MANAGEMENT',
          path: ITEM_MANAGEMENT_ROUTE,
          icon: faTasks
        }
      ]
    : []),
  ...(checkRoles(roles, stockManagementRoles)
    ? [
        {
          label: 'STOCK_MANAGEMENT',
          path: checkRoles(roles, codeDispensationRoles) ? SELECT_SUPPLIER : STOCK_MANAGEMENT,
          icon: faLayerGroup
        }
      ]
    : []),
  ...(checkRoles(roles, codeDispensationRoles)
    ? [
        {
          label: 'CODE_DISPENSATION',
          path: CODE_DISPENSATION,
          icon: faArrowsLeftRight
        }
      ]
    : []),
  ...(checkRoles(roles, logisticalGuidanceRoles)
    ? [
        {
          label: 'LOGISTICAL_GUIDANCE',
          path: LOGISTICAL_GUIDANCE,
          icon: faChartPie
        }
      ]
    : []),
  ...(checkRoles(roles, transportManagementRoles)
    ? [
        {
          label: 'TRANSPORT_MANAGEMENT',
          icon: faTruck,
          items: [
            {
              label: 'LOAD_OVERVIEW',
              path: LOAD_OVERVIEW
            },
            {
              label: 'TRANSPORT_MANAGEMENT',
              path: TRANSPORT_MANAGEMENT
            },
            {
              label: 'ORPHANS',
              path: UNLINKED_ORDERS
            }
          ]
        }
      ]
    : []),
  ...(checkRoles(roles, userManagementRoles)
    ? [
        {
          label: 'USERMANAGEMENT',
          icon: faUsers,
          items: [
            ...(checkRoles(roles, authorizationSupplierRoles)
              ? [
                  {
                    label: 'AUTHORIZATION_SUPPLIER',
                    path: AUTHORIZATION_SUPPLIER
                  }
                ]
              : []),
            ...(checkRoles(roles, authorizationCarrierRoles)
              ? [
                  {
                    label: 'AUTHORIZATION_CARRIER',
                    path: AUTHORIZATION_CARRIER
                  }
                ]
              : []),
            {
              label: 'USER_MANAGEMENT',
              path: USER_MANAGEMENT
            }
          ]
        }
      ]
    : []),
  ...(checkRoles(roles, maintenanceRoles)
    ? [
        {
          label: 'MAINTENANCE',
          icon: faBook,
          path: MAINTENANCE
        }
      ]
    : []),
  ...(checkRoles(roles, reportsRoles)
    ? [
        {
          label: 'REPORTS',
          icon: faBook,
          path: REPORTS
        }
      ]
    : []),
  ...(checkRoles(roles, mailingListsRoles) && checkTenants(roles, currentTenantId, [ETenant.NIEUWEGEIN])
    ? [
        {
          label: 'MAILINGLISTS',
          icon: faRectangleList,
          path: MAILINGLISTS
        }
      ]
    : []),
  ...(checkRoles(roles, mailingsListsSupplierRoles) && checkTenants(roles, currentTenantId, [ETenant.NIEUWEGEIN])
    ? [
        {
          label: 'MAILINGLISTS',
          icon: faRectangleList,
          path: MAILINGLISTS_SUPPLIER
        }
      ]
    : [])
];
