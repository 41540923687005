import { AxiosInstance, AxiosResponse } from 'axios';
import { CustomerSuppliersDTO } from 'dtos';
import { IBaseEntity } from 'interfaces';

import { BaseApiService } from './BaseApiService';

export class CarrierService extends BaseApiService<IBaseEntity> {
  constructor(instance: AxiosInstance, url: string) {
    super(instance, url);
  }

  public async getSuppliers(id: string): Promise<CustomerSuppliersDTO> {
    return await this.instance.get(`${this.url}/${id}/suppliers`).then((res: AxiosResponse<CustomerSuppliersDTO>) => {
      return res.data;
    });
  }
}
