import dayjs from 'dayjs';
import { StockItemDTO, UpdateStockDTO } from 'dtos';
import { EDate } from 'enums';
import { IFormSubmitData, IMapper, IStockItem, IStockSubgroup } from 'interfaces';

export class StockMapper implements IMapper<IStockItem> {
  static toState({
    activityCode,
    batch,
    bestBeforeDate,
    blockedByCustomer,
    blockedBySupplier,
    colloGtin,
    description,
    isQuarantinable,
    lastShipmentDate,
    owner,
    quarantined,
    sscc,
    subgroups,
    disabled
  }: StockItemDTO): IStockItem {
    return {
      activityCode,
      batch,
      colloGtin,
      description,
      isQuarantinable,
      owner,
      sscc,
      available: subgroups?.reduce((prev, current) => prev + current.available, 0) || 0,
      bestBeforeDate: dayjs(bestBeforeDate).format(EDate.DD_MM_YY),
      blockedByCustomer: blockedByCustomer || 0,
      blockedBySupplier: blockedBySupplier || 0,
      lastShipmentDate: dayjs(lastShipmentDate).format(EDate.DD_MM_YY),
      quarantined: quarantined || 0,
      total: subgroups?.reduce((prev, current) => prev + current.total, 0) || 0,
      disabled,
      holdCode: '',
      subRows: subgroups.map(subgroup => ({
        ...subgroup,
        disabled: subgroup.disabled || disabled,
        bestBeforeDate: dayjs(subgroup.bestBeforeDate).format(EDate.DD_MM_YY),
        lastShipmentDate: dayjs(subgroup.lastShipmentDate).format(EDate.DD_MM_YY),
        sscc: subgroup.sscc || 'IN DISPATCH'
      }))
    };
  }

  static toUpdateStockDTO({ formData }: IFormSubmitData, items: IStockSubgroup[], supplierId?: string): UpdateStockDTO {
    return {
      blocked: formData.blocked ? formData.blocked === 'block' : undefined,
      quarantined: formData.quarantined ? formData.quarantined === 'block' : undefined,
      lastShipmentDate: formData.lastShipmentDate
        ? dayjs(formData.lastShipmentDate.toString()).format(EDate.YYYY_MM_DD)
        : undefined,

      ipgItems: items.map(item => ({
        activityCode: item.activityCode,
        ipgNumber: item.ipgNumber
      })),
      supplierId: supplierId
    };
  }
}
