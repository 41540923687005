import { APIRequester } from 'api';
import { APP_BASE_TITLE } from 'const';
import { EStorageKeys } from 'enums';
import {
  IAPIRequester,
  IAppointmentsStore,
  IAuthStore,
  ICustomerStore,
  IDashboardStore,
  IDistributionCenterStore,
  IItemManagementStore,
  ILoadStore,
  ILocalStorageStore,
  ILogisticalGuidanceStore,
  IMailinglistsStore,
  IMaintenanceStore,
  INotificationStore,
  IOrganizationStore,
  IReportsStore,
  IRootStore,
  ISlotbookingStore,
  IStockStore,
  ISupplierStore,
  ITenantStore,
  IUserStore,
  IVehicleTypeStore
} from 'interfaces';
import { action, makeObservable, observable } from 'mobx';

import { AppointmentsStore } from './AppointmentsStore';
import { AuthStore } from './AuthStore';
import { CustomerStore } from './CustomerStore';
import { DashboardStore } from './DashboardStore';
import { DistributionCenterStore } from './DistributionCenterStore';
import { ItemManagementStore } from './ItemManagementStore';
import { LoadStore } from './LoadStore';
import { LocalizationStore } from './LocalizationStore';
import { LocalStorageStore } from './LocalStorageStore';
import { LogisticalGuidanceStore } from './LogisticalGuidanceStore';
import { MailinglistsStore } from './MailinglistsStore';
import { MaintenanceStore } from './MaintenanceStore';
import {
  BaseManager,
  CustomerManager,
  LoadManager,
  MenuManager,
  SupplierManager,
  TableDataManager,
  UserManager
} from './managers';
import { NotificationStore } from './NotificationStore';
import { OrganizationStore } from './OrganizationStore';
import { ReportsStore } from './ReportsStore';
import { RouteStore } from './RouteStore';
import { SlotbookingStore } from './SlotbookingStore';
import { StockStore } from './StockStore';
import { SupplierStore } from './SupplierStore';
import { TenantStore } from './TenantStore';
import { UserStore } from './UserStore';
import { VehicleTypeStore } from './VehicleTypeStore';

/**
 * Class representing Root Store container that collects
 * all stores and injected as property in root component
 * provider after initialization.
 * @class
 */
export class RootStore implements IRootStore {
  public authStore: IAuthStore;
  public customerStore: ICustomerStore;
  public localStorageStore: ILocalStorageStore;
  public routeStore: RouteStore;
  public requester: IAPIRequester;
  public localizationStore: LocalizationStore;
  public notificationStore: INotificationStore;
  public organizationStore: IOrganizationStore;
  public baseManager: BaseManager;
  public userStore: IUserStore;
  public menuManager: MenuManager;
  public tableDataManager: TableDataManager;
  public userManager: UserManager;
  public loadManager: LoadManager;
  public supplierManager: SupplierManager;
  public customerManager: CustomerManager;
  public title: string = APP_BASE_TITLE;
  public dashboardStore: IDashboardStore;
  public appointmentsStore: IAppointmentsStore;
  public stockStore: IStockStore;
  public supplierStore: ISupplierStore;
  public tenantStore: ITenantStore;
  public itemManagementStore: IItemManagementStore;
  public loadStore: ILoadStore;
  public distributionCenterStore: IDistributionCenterStore;
  public vehicleTypeStore: IVehicleTypeStore;
  public logisticalGuidanceStore: ILogisticalGuidanceStore;
  public reportsStore: IReportsStore;
  public mailinglistsStore: IMailinglistsStore;
  public maintenanceStore: IMaintenanceStore;
  public slotbookingStore: ISlotbookingStore;

  constructor() {
    this.localStorageStore = new LocalStorageStore();
    this.requester = new APIRequester();
    // root store composition
    this.authStore = new AuthStore(this);
    this.customerStore = new CustomerStore(this);
    this.routeStore = new RouteStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.distributionCenterStore = new DistributionCenterStore(this);
    this.notificationStore = new NotificationStore(this);
    this.localizationStore = new LocalizationStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.loadStore = new LoadStore(this);
    this.userStore = new UserStore(this);
    this.appointmentsStore = new AppointmentsStore(this);
    this.stockStore = new StockStore(this);
    this.supplierStore = new SupplierStore(this);
    this.tenantStore = new TenantStore(this);
    this.itemManagementStore = new ItemManagementStore(this);
    this.vehicleTypeStore = new VehicleTypeStore(this);
    this.logisticalGuidanceStore = new LogisticalGuidanceStore(this);
    this.reportsStore = new ReportsStore(this);
    this.mailinglistsStore = new MailinglistsStore(this);
    this.maintenanceStore = new MaintenanceStore(this);
    this.slotbookingStore = new SlotbookingStore(this);
    // managers
    this.baseManager = new BaseManager(this);
    this.tableDataManager = new TableDataManager(this);
    this.menuManager = new MenuManager(this);
    this.userManager = new UserManager(this);
    this.loadManager = new LoadManager(this);
    this.supplierManager = new SupplierManager(this);
    this.customerManager = new CustomerManager(this);
    // initial actions
    this.localizationStore.changeLanguage(this.localStorageStore.getItem(EStorageKeys.CURRENT_LANG) as string);
    this.authStore.getMe(true);

    makeObservable(this, {
      title: observable,
      setPageTitle: action.bound
    });
  }

  public setPageTitle(title: string): void {
    this.title = title;
  }
}
