import { ISelectProps } from 'interfaces';

export const parseUniqueValues = <T>(data: T[], key: string): ISelectProps[] => {
  const uniqueItems: Set<string> = new Set();
  data.forEach(item => {
    const currentItem: string = item[key];
    if (!uniqueItems.has(currentItem)) uniqueItems.add(currentItem);
  });
  const options: ISelectProps[] = [];
  for (const value of uniqueItems.values()) {
    options.push({
      value,
      label: value
    });
  }
  return options;
};
