import { AxiosInstance, AxiosResponse } from 'axios';
import { INewsItem } from 'interfaces/models/INewsItem';

import { BaseApiService } from './BaseApiService';

export class NewsService extends BaseApiService<INewsItem> {
  constructor(readonly instance: AxiosInstance, readonly url: string) {
    super(instance, url, {
      getAllUrl: `${url}?includeImage=true`
    });
  }

  public async updateNewsItem(news: FormData): Promise<INewsItem> {
    const newsItem = await this.instance.post(this.url, news).then((res: AxiosResponse<INewsItem>) => {
      return res.data;
    });

    return newsItem;
  }
}
