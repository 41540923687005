import { extendedPalette } from '../theme';

export const calculateTimeColor = (time: string): string => {
  if (!time) return '';

  const hours = +time.split(':')[0];
  if (hours > 0) return extendedPalette.success;

  const minutes = +time.split(':')[1];

  // if minutes are less then 15 and minutes equals 0 add tm-error class
  // if minutes are less then 15 and minutes are bigger then 0 add tm-warn class
  // if minutes are more then 15 add tm-success class
  return minutes < 15 ? (minutes === 0 ? extendedPalette.error : extendedPalette.warning) : extendedPalette.success;
};
