import dayjs from 'dayjs';
import { EditOrderDTO, LoadBaseDTO, LoadDTO, MoveOrderDTO, PlaceholderDTO, UpdateOrdersDTO } from 'dtos';
import { EDate } from 'enums';
import { IFormSubmitData, ILoad, IMapper, IOrder } from 'interfaces';

interface IUpdateData {
  storeCode?: string;
  dispatchConsigneeCode?: string;
  btl?: string;
}
export class LoadMapper implements IMapper<ILoad> {
  static toState(data: LoadDTO): ILoad {
    return {
      ...data,
      id: data.loadCode,
      firstDeliveryTimeField: `${data.firstDeliveryDate} ${data.firstDeliveryTime}`,
      plannedLoadingTimeField: `${data.plannedLoadingDate} ${data.plannedLoadingTime}`,
      isDirectLoad: data.firstDeliveryDate === '1970-01-01' && data.firstDeliveryTime === '01:00',
      date: dayjs(data.plannedLoadingDate).format(EDate.DDD_DD_MM),
      defOrders: `${data.countOfFinalOrders || 0} / ${data.countOfOrders || 0}`
    };
  }

  static toDto({ formData }: IFormSubmitData): LoadBaseDTO {
    const plannedLoadingDateRaw = formData.plannedLoadingDate as Date;
    const plannedLoadingDate = !!plannedLoadingDateRaw
      ? dayjs(plannedLoadingDateRaw).format(EDate.YYYY_MM_DD)
      : undefined;
    const plannedLoadingTimeRaw = formData.plannedLoadingTimeField as Date;
    const plannedLoadingTime = !!plannedLoadingTimeRaw ? dayjs(plannedLoadingTimeRaw).format(EDate.HH_MM) : undefined;
    const firstDeliveryDateRaw = formData.firstDeliveryDate as Date;
    const firstDeliveryDate = !!firstDeliveryDateRaw ? dayjs(firstDeliveryDateRaw).format(EDate.YYYY_MM_DD) : undefined;
    const firstDeliveryTimeRaw = formData.firstDeliveryTimeField as Date;
    const firstDeliveryTime = (!!firstDeliveryTimeRaw && !formData.isDirectLoad)
      ? dayjs(firstDeliveryTimeRaw).format(EDate.HH_MM)
      : plannedLoadingTime;
    const loadDateRaw = formData.loadDate as Date;
    const loadDate = !!loadDateRaw ? dayjs(loadDateRaw).format(EDate.YYYY_MM_DD_CUT) : undefined;

    return {
      firstDeliveryDate: !formData.isDirectLoad ? firstDeliveryDate : undefined, // "2022-04-08"
      firstDeliveryTime, // "13:10"
      loadCode: formData.loadCode?.toString(),
      loadDate,
      loadDesignation: formData.loadDesignation.toString(),
      plannedLoadingDate, // "2022-04-06"
      plannedLoadingTime, // "13:10"
      transportTypeCode: formData.transportTypeCode.toString() // "LZV"
    };
  }

  static toPlaceholderDto(
    { formData }: IFormSubmitData,
    loadCode: string,
    loadDate: string,
    date: string
  ): PlaceholderDTO {
    return {
      loadCode,
      loadDate,
      placeholder: {
        btl: dayjs(formData.btl as Date).format(EDate.HH_MM),
        date,
        endConsigneeCode: formData.endConsigneeCode.toString(),
        endConsigneeDeliveryTime: dayjs(formData.endConsigneeDeliveryTime as Date).format(EDate.HH_MM),
        storeCode: formData.storeCode.toString()
      }
    };
  }

  static toMoveOrderDTO(currentLoad: ILoad, targetLoad: ILoad, orders: IOrder[]): MoveOrderDTO {
    return {
      srcLoadDate: currentLoad.loadDate,
      srcLoadCode: currentLoad.loadCode,
      targetLoadDate: targetLoad.loadDate,
      targetLoadCode: targetLoad.loadCode,
      movedOrders: orders.map(order => ({
        orderPreparationNumber: order.preparationNumber,
        orderPreparationYear: order.preparationYear,
        orderProNumber: order.proNumber,
        orderProYear: order.proYear
      }))
    };
  }

  static toMoveOrphansDTO(currentLoad: ILoad, orders: IOrder[]): MoveOrderDTO {
    return {
      loadDate: currentLoad.loadDate,
      loadCode: currentLoad.loadCode,
      orphansDate: currentLoad.loadDate,
      movedOrphans: orders.map(order => ({
        proYear: order.proYear,
        proNumber: order.proNumber,
        preparationYear: order.preparationYear,
        preparationNumber: order.preparationNumber
      }))
    };
  }

  static toUpdateOrders(loadCode: string, loadDate: string, orders: IOrder[]): UpdateOrdersDTO {
    return {
      loadCode,
      loadDate,
      orders: orders.map(order => ({
        ...order,
        final: order.isFinal,
        toGo: order.isToGo,
        isFinal: undefined,
        isToGo: undefined
      }))
    };
  }

  static toEditOrdersDTO({ formData }: IFormSubmitData, orders: IOrder[], loadDate: string): EditOrderDTO {
    let updatedOrders = orders.map(order => ({
      ...order,
      final: order.isFinal,
      toGo: order.isToGo,
      checked: undefined,
      isFinal: undefined,
      isToGo: undefined
    }));

    if (!formData.operation) {
      const updateData: IUpdateData = {
        ...(formData.storeCode ? { storeCode: formData.storeCode.toString() } : {}),
        ...(formData.dispatchConsigneeCode ? { dispatchConsigneeCode: formData.dispatchConsigneeCode.toString() } : {}),
        ...(formData.btl
          ? {
              btl: dayjs(formData.btl as Date).format(EDate.HH_MM)
            }
          : {})
      };

      updatedOrders = updatedOrders.map(order => ({
        ...order,
        ...updateData
      }));
    }

    return {
      ...(formData.operation ? { cancelOrphans: updatedOrders } : { orphans: updatedOrders }),
      ...(formData.operation ? { loadDate } : {}),
      ...(formData.operation ? { operation: formData.operation.toString() } : {})
    };
  }
}
