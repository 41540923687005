import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { StockDTO, StockItemDTO, UpdateStockDTO } from 'dtos';
import { IBaseObject } from 'interfaces';

import { BaseApiService } from './BaseApiService';

export class StockService extends BaseApiService<StockItemDTO> {
  constructor(readonly instance: AxiosInstance, readonly url: string) {
    super(instance, url, {
      updateUrl: url
    });
  }

  public async getStockItems(body: IBaseObject): Promise<StockItemDTO[]> {
    return await this.instance.post(this.url, body).then(({ data }: AxiosResponse<StockDTO>) => data.stock);
  }

  public async updateStockItems(body: UpdateStockDTO): Promise<UpdateStockDTO> {
    return await this.instance.post(`${this.url}/block`, body).then(({ data }: AxiosResponse<UpdateStockDTO>) => data);
  }

  public async updateQuarantineItems(body: UpdateStockDTO): Promise<UpdateStockDTO> {
    return await this.instance
      .post(`${this.url}/quarantine`, body)
      .then(({ data }: AxiosResponse<UpdateStockDTO>) => data);
  }

  public async updateLastShipmentDate(body: UpdateStockDTO): Promise<UpdateStockDTO> {
    return await this.instance
      .post(`${this.url}/lastShipmentDate`, body)
      .then(({ data }: AxiosResponse<UpdateStockDTO>) => data);
  }

  public async downloadStockData(options?: AxiosRequestConfig): Promise<string> {
    return this.instance.get(`${this.url}/download`, options).then((res: AxiosResponse<string>) => {
      return res.data;
    });
  }

  public async downloadStockTransactions(options: AxiosRequestConfig): Promise<string> {
    return this.instance.get(`${this.url}/transactions/download`, options).then((res: AxiosResponse<string>) => {
      return res.data;
    });
  }
}
