import { ENABLE_LOGGER } from 'config';
import { createMenu } from 'const/Menu';
import { ROOT_ROUTE } from 'const/Router';
import { EStorageKeys } from 'enums';
import isEqual from 'fast-deep-equal';
import { IAuth, IMenu, INavigation, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseManager } from './BaseManager';

export class MenuManager extends BaseManager {
  public menuMap: Map<string, INavigation> = new Map();
  public route: string = null;
  public param: string = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      menuMap: observable,
      route: observable,
      currentMenu: computed,
      setDefaultMenu: action.bound,
      setMenu: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);

    reaction(
      () => this.currentMenu?.route,
      (value, previousValue, reaction) => {
        if (ENABLE_LOGGER) console.log({ previousValue, value, reaction });
      },
      {
        equals: isEqual
      }
    );

    this.setDefaultMenu();

    reaction(
      () => this.rootStore.tenantStore.currentTenantId,
      () => {
        this.setDefaultMenu();
      },
      {
        equals: isEqual
      }
    );
  }

  public get currentMenu(): INavigation {
    return this.menuMap.get(this.route);
  }

  public setDefaultMenu(): void {
    const currentTenantId = this.rootStore.tenantStore.currentTenantId;
    const user = this.rootStore.localStorageStore.getItem(EStorageKeys.USER) as IAuth;
    const menu = createMenu(user?.roles || [], currentTenantId);
    this.setMenu(menu, ROOT_ROUTE);
  }

  public setMenu(menu: IMenu[], route: string, parent: string = null, param: string = null): void {
    runInAction(() => {
      this.menuMap.set(route, { menu, route, param, parent });
      this.route = route;
    });
  }
}
