import dayjs from 'dayjs';
import { MaintenanceStatusDTO } from 'dtos';
import { EDate } from 'enums';
import { IFormData, IMaintenance, IMaintenanceStatus, INewMaintenance } from 'interfaces';

export class DowntimeMapper {
  static toNewMaintenance(formData: IFormData): INewMaintenance {
    return {
      startDate: dayjs(formData.startDate as Date).format(EDate.YYYY_MM_DD),
      startTime: dayjs(formData.startTime as Date).format(EDate.HH_MM),
      endDate: dayjs(formData.endDate as Date).format(EDate.YYYY_MM_DD),
      endTime: dayjs(formData.endTime as Date).format(EDate.HH_MM)
    };
  }

  static toActiveMaintenanceIds(list: IMaintenance[]): number[] {
    return list.reduce((prev, curr) => {
      const currItemDate = dayjs(`${curr.startDate} ${curr.startTime}`, EDate.YYYY_MM_DD_HH_MM).locale('nl');
      if (currItemDate.isBefore(dayjs().locale('nl'))) {
        return [...prev, curr.id];
      }
      return prev;
    }, []);
  }

  static toUpdatedMaintenance(formDate: IFormData, id: number): IMaintenance {
    return {
      ...this.toNewMaintenance(formDate),
      id
    };
  }

  static toFormData(maintenance: IMaintenance): IFormData {
    return {
      startDate: dayjs(maintenance.startDate, EDate.YYYY_MM_DD).locale('nl').toDate(),
      startTime: dayjs(`${maintenance.startDate} ${maintenance.startTime}`).locale('nl').toDate(),
      endDate: dayjs(maintenance.endDate, EDate.YYYY_MM_DD).locale('nl').toDate(),
      endTime: dayjs(`${maintenance.endDate} ${maintenance.endTime}`).locale('nl').toDate()
    };
  }

  static toStatus(data: MaintenanceStatusDTO): IMaintenanceStatus[] {
    let result: IMaintenanceStatus[] = [];
    Object.keys(data).map(key => {
      data[key][0].name = key.toLowerCase();
      result = [...result, ...data[key]];
    });

    return result;
  }
}
