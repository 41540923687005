import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { EStorageKeys } from 'enums';
import { DEFAULT_LOCALE, LIST_OF_LOCALE, localeNormalizer } from 'helpers';
import { IRootStore } from 'interfaces';
import { i18instance } from 'localization';
import { action, computed, makeObservable, observable } from 'mobx';

import { BaseStore } from './BaseStore';

export class LocalizationStore extends BaseStore {
  private dayjs: typeof dayjs = dayjs;
  public defaultLocale: string = navigator.language || DEFAULT_LOCALE;
  public currentLocale: string = DEFAULT_LOCALE;
  public langName: string = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    this.initDayjsInstance();

    makeObservable(this, {
      defaultLocale: observable,
      currentLocale: observable,
      langName: observable,
      dayjsInstance: computed,
      changeLanguage: action.bound,
      setLanguage: action.bound
    });
  }

  public get dayjsInstance(): typeof dayjs {
    return this.dayjs;
  }

  public async changeLanguage(lang: string = this.defaultLocale): Promise<void> {
    const langName = localeNormalizer(lang);
    try {
      if (!i18instance.hasResourceBundle(langName, 'translation')) {
        this.setLanguage(langName);
        this.langName = langName;
        const { default: translation } = await this.loadLocaleFile(langName);
        await i18instance.addResourceBundle(langName, 'translations', translation, true, true);
      }
      await i18instance.changeLanguage(langName);
    } catch (e) {
      console.error(e);
    } finally {
      this.rootStore.localStorageStore.setItem(EStorageKeys.CURRENT_LANG, langName);
    }
  }

  public setLanguage(lang: string): void {
    this.currentLocale = lang;
    const currentLocale = LIST_OF_LOCALE[lang] || DEFAULT_LOCALE;

    this.dayjs.locale(currentLocale);
  }

  private initDayjsInstance(): void {
    this.dayjs.extend(localizedFormat);
    this.dayjs.extend(advancedFormat);
    this.dayjs.extend(relativeTime);
    this.dayjs.extend(localeData);
    this.dayjs.extend(updateLocale);
    this.dayjs.extend(customParseFormat);
    this.dayjs.extend(utc);
    this.dayjs.extend(timezone);
    this.dayjs.tz.setDefault('Europe/Amsterdam');
  }

  private loadLocaleFile = (lang: string): Promise<Record<string, string>> =>
    import(`localization/locales/${lang}.json`);
}
