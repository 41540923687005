import { ENABLE_LOGGER } from 'config';
import isEqual from 'fast-deep-equal';
import { IRootStore, IUser } from 'interfaces';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseManager } from './BaseManager';

export class UserManager extends BaseManager {
  public currentUserId: string = null;
  public isDeleted: boolean = false;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      currentUserId: observable,
      isDeleted: observable,
      currentUser: computed,
      setCurrentUserId: action.bound,
      onDelete: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);

    reaction(
      () => this.currentUser?.id,
      (value, previousValue, reaction) => {
        if (ENABLE_LOGGER) console.log({ previousValue, value, reaction });
      },
      {
        equals: isEqual
      }
    );
  }

  public get currentUser(): IUser {
    return this.rootStore.userStore.usersMap.get(this.currentUserId) || null;
  }

  public setCurrentUserId(id: string): void {
    this.currentUserId = id;
  }

  public onDelete(id?: string): void {
    this.rootStore.userStore.usersMap.delete(id || this.currentUserId);
    this.isDeleted = true;
  }
}
