import axios, { AxiosInstance, HeadersDefaults } from 'axios';
import { AUTH_HEADER } from 'const';
import { IMakeAPIUrls, makeAPIUrls } from 'const/ApiUrls';
import { IAPIRequester } from 'interfaces';

import {
  AppointmentsService,
  AuthService,
  CalendarService,
  CarrierService,
  ContactService,
  CustomerService,
  DistributionCenterService,
  DownloadsService,
  ItemManagementService,
  LogisticalGuidanceService,
  MailinglistsService,
  MaintenanceService,
  NewsService,
  OrganizationService,
  ReportsService,
  SlotbookingService,
  StockService,
  SuppliersService,
  TenantService,
  TransportService,
  UserService,
  VehicleTypeService
} from './services';

interface IBatchRequest {
  method: string;
  uri: string;
  headers: HeadersDefaults;
}

/**
 * Class representing API requester
 * @class APIRequester
 */
export class APIRequester implements IAPIRequester {
  protected instance: AxiosInstance;
  public authService: AuthService;
  public carrierService: CarrierService;
  public customerService: CustomerService;
  public organizationService: OrganizationService;
  public userService: UserService;
  public contactService: ContactService;
  public newsService: NewsService;
  public appointmentsService: AppointmentsService;
  public suppliersService: SuppliersService;
  public calendarService: CalendarService;
  public downloadsService: DownloadsService;
  public tenantService: TenantService;
  public itemManagementService: ItemManagementService;
  public apiUrls: IMakeAPIUrls;
  public distributionCenterService: DistributionCenterService;
  public stockService: StockService;
  public transportService: TransportService;
  public vehicleTypeService: VehicleTypeService;
  public logisticalGuidanceService: LogisticalGuidanceService;
  public reportsService: ReportsService;
  public mailinglistsService: MailinglistsService;
  public maintenanceService: MaintenanceService;
  public slotbookingService: SlotbookingService;

  constructor() {
    this.instance = axios.create();
    this.createInstance();
  }

  private createInstance(tenantId?: number): void {
    this.apiUrls = makeAPIUrls(tenantId);
    this.authService = new AuthService(this.instance, this.apiUrls.BASE_API_URL);
    this.carrierService = new CarrierService(this.instance, this.apiUrls.CARRIER_BASE_URL);
    this.customerService = new CustomerService(
      this.instance,
      this.apiUrls.CUSTOMERS_BASE_URL,
      this.apiUrls.CUSTOMERS_URL_WITHOUT_TENANT
    );
    this.organizationService = new OrganizationService(
      this.instance,
      this.apiUrls.ORGANIZATION_BASE_URL,
      this.apiUrls.BASE_API_URL
    );
    this.userService = new UserService(this.instance, this.apiUrls.USER_BASE_URL, this.apiUrls.BASE_API_URL);
    this.appointmentsService = new AppointmentsService(
      this.instance,
      this.apiUrls.APPOINTMENTS_BASE_URL,
      this.apiUrls.APPOINTMENTS_URL_WITHOUT_TENANT
    );
    this.suppliersService = new SuppliersService(
      this.instance,
      this.apiUrls.SUPPLIERS_BASE_URL,
      this.apiUrls.SUPPLIERS_URL_WITHOUT_TENANT
    );
    this.calendarService = new CalendarService(this.instance, this.apiUrls.BASE_API_URL);
    this.contactService = new ContactService(this.instance, this.apiUrls.CONTACT_BASE_URL);
    this.newsService = new NewsService(this.instance, this.apiUrls.NEWS_BASE_URL);
    this.downloadsService = new DownloadsService(this.instance, this.apiUrls.DOWNLOADS_BASE_URL);
    this.tenantService = new TenantService(this.instance, this.apiUrls.TENANT_BASE_URL);
    this.itemManagementService = new ItemManagementService(this.instance, this.apiUrls.MASTERDATA_BASE_URL);
    this.distributionCenterService = new DistributionCenterService(
      this.instance,
      this.apiUrls.DISTRIBUTION_CENTERS_BASE_URL
    );
    this.stockService = new StockService(this.instance, this.apiUrls.STOCK_BASE_URL);
    this.transportService = new TransportService(
      this.instance,
      this.apiUrls.TRANSPORT_BASE_URL,
      this.apiUrls.UNLINKED_ORDERS_BASE_URL
    );
    this.vehicleTypeService = new VehicleTypeService(this.instance, this.apiUrls.VEHICLE_TYPES_BASE_URL);
    this.logisticalGuidanceService = new LogisticalGuidanceService(
      this.instance,
      this.apiUrls.LOGISTICAL_GUIDANCE_BASE_URL
    );
    this.reportsService = new ReportsService(
      this.instance,
      this.apiUrls.REPORTS_BASE_URL,
      this.apiUrls.REPORTS_URL_WITHOUT_TENANT
    );
    this.mailinglistsService = new MailinglistsService(
      this.instance,
      this.apiUrls.MAILINGLISTS_BASE_URL,
      this.apiUrls.MAILINGLISTS_WITHOUT_TENANT_URL
    );
    this.maintenanceService = new MaintenanceService(
      this.instance,
      this.apiUrls.MAINTENANCE_BASE_URL,
      this.apiUrls.MAINTENANCE_STATUS_URL
    );
    this.slotbookingService = new SlotbookingService(this.instance, this.apiUrls.SLOTBOOKING_BASE_URL);
  }

  public setToken(token?: string): void {
    this.instance.defaults.headers[AUTH_HEADER] = token;
  }

  public removeToken(): void {
    this.instance.defaults.headers[AUTH_HEADER] = '';
  }

  public createBatchRequest(uri: string): IBatchRequest {
    return {
      method: 'GET',
      uri,
      headers: this.instance.defaults.headers
    };
  }

  public updateAPIUrls(tenantId: number): void {
    this.createInstance(tenantId);
  }
}
