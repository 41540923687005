export const enum ESelectOptionsData {
  CARRIER = 'carrier',
  CUSTOMER = 'customer',
  CUSTOMER_SUPPLIER = 'customer-supplier',
  DISTRIBUTION_CENTER = 'distribution-center',
  ORGANIZATION_NAME = 'organization-name',
  ORGANIZATION_TYPE = 'organization-type',
  SUPPLIER = 'supplier',
  VEHICLE_TYPE = 'vehicle-type'
}
