import { AxiosInstance, AxiosResponse } from 'axios';
import { IItemLvList, IItemlvListResponse, IMasterData, IMasterDataResponse } from 'interfaces';

export class ItemManagementService {
  constructor(readonly instance: AxiosInstance, readonly url: string) {}

  public async getMasterData(): Promise<IMasterData[]> {
    return this.instance.get(this.url).then((res: AxiosResponse<IMasterDataResponse>) => {
      return res.data.masterData;
    });
  }

  public async updateMasterDate(data: { itemLvList: IItemLvList[] }): Promise<IItemlvListResponse[]> {
    return this.instance.post(this.url, data).then((res: AxiosResponse<{ itemLvList: IItemlvListResponse[] }>) => {
      return res.data.itemLvList;
    });
  }

  public async downloadMasterDate(): Promise<string> {
    return this.instance.get(`${this.url}/download`).then((res: AxiosResponse<string>) => {
      return res.data;
    });
  }
}
