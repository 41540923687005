import { AxiosInstance, AxiosResponse } from 'axios';
import { AuthNewSupplierDTO, CustomerSuppliersDTO } from 'dtos';
import { IBaseEntity } from 'interfaces';

import { BaseApiService } from './BaseApiService';

export class CustomerService extends BaseApiService<IBaseEntity> {
  private additionalUrl: string = null;
  constructor(instance: AxiosInstance, url: string, additionalUrl: string) {
    super(instance, url);
    this.additionalUrl = additionalUrl;
  }

  public async createSupplier(
    body: AuthNewSupplierDTO,
    orgId: string,
    supplierId: string
  ): Promise<CustomerSuppliersDTO> {
    return await this.instance
      .post(`${this.additionalUrl}/${orgId}/suppliers/${supplierId}`, body)
      .then(({ data }: AxiosResponse<CustomerSuppliersDTO>) => data);
  }

  public async getSuppliers(id: string): Promise<CustomerSuppliersDTO> {
    return await this.instance.get(`${this.url}/${id}/suppliers`).then((res: AxiosResponse<CustomerSuppliersDTO>) => {
      return res.data;
    });
  }
}
