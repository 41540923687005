export interface IConfig {
  [key: string]: string | undefined;
}

interface IAppConfig {
  ENABLE_LOGGER: boolean;
  LOG_LEVEL: string;
  BASE_PATH: string;
  API_URL: string;
  CONTENT_URL_BASE: string;
  REPORTS_SSE: boolean;
}

// for test suite only
const testConfig: IAppConfig = {
  ENABLE_LOGGER: true,
  LOG_LEVEL: 'INFO',
  BASE_PATH: '/',
  API_URL: 'http://test.com',
  CONTENT_URL_BASE: 'http://test.com',
  REPORTS_SSE: false
};

export interface IWindow extends Window {
  appConfig?: IAppConfig;
}

const { NODE_ENV }: IConfig = process.env;

const { ENABLE_LOGGER, LOG_LEVEL, BASE_PATH, API_URL, CONTENT_URL_BASE, REPORTS_SSE } =
  NODE_ENV !== 'test' ? (window as IWindow).appConfig : testConfig;

const IS_DEV = NODE_ENV === 'development';


export { IS_DEV, API_URL, LOG_LEVEL, ENABLE_LOGGER, BASE_PATH, CONTENT_URL_BASE, NODE_ENV, REPORTS_SSE };
