import { AxiosInstance } from 'axios';
import { IContact } from 'interfaces/models/IContact';

import { BaseApiService } from './BaseApiService';

export class ContactService extends BaseApiService<IContact> {
  constructor(readonly instance: AxiosInstance, readonly url: string) {
    super(instance, url, {
      updateUrl: url
    });
  }
}
