import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { forwardRef, memo } from 'react';

interface IProps {
  color?: 'inherit' | 'primary' | 'secondary';
  opaque?: boolean;
  size?: number;
  extendClasses?: any;
}

export const LoadingMask = memo(
  forwardRef((props: IProps, ref: React.Ref<unknown>): JSX.Element => {
    const theme = useTheme();
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          zIndex: 999
        }}
        ref={ref}
      >
        <CircularProgress
          {...props}
          sx={{
            margin: theme.spacing(2)
          }}
        />
      </Box>
    );
  })
);
