import { LoadingMask } from 'components/LoadingMask';
import React, { Suspense } from 'react';

export const LazyLoader =
  (Component: React.LazyExoticComponent<any>) =>
  (props: any): JSX.Element => {
    return (
      <Suspense fallback={<LoadingMask />}>
        <Component {...props} />
      </Suspense>
    );
  };
