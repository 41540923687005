export const enum EUserRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN_TRANSPORTER = 'ADMIN_TRANSPORTER',
  USER_TRANSPORTER = 'USER_TRANSPORTER',
  ADMIN_CARRIER = 'ADMIN_CARRIER',
  USER_CARRIER = 'USER_CARRIER',
  ADMIN_SUPPLIER = 'ADMIN_SUPPLIER',
  USER_SUPPLIER = 'USER_SUPPLIER',
  ADMIN_CUSTOMER = 'ADMIN_CUSTOMER',
  USER_CUSTOMER = 'USER_CUSTOMER',
  AUTH_PLAN_APPOINTMENTS = 'AUTH_PLAN_APPOINTMENTS'
}
