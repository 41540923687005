import { ENABLE_LOGGER } from 'config';
import { TM_LOADS_IN_VIEW } from 'const';
import { LOAD_OVERVIEW } from 'const/Router';
import { LoadDTO, OrderDTO } from 'dtos';
import { EDate, EDragAndDropType, EStorageKeys, ETransportPages } from 'enums';
import isEqual from 'fast-deep-equal';
import { LoadMapper, makeNotification } from 'helpers';
import {
  IDragAndDropOptions,
  IError,
  IFilterData,
  IFormSubmitData,
  ILoad,
  ILoadStore,
  IOrder,
  IRootStore
} from 'interfaces';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { SelectData } from 'mui-rff';

import { BaseStore } from './BaseStore';

const INITIAL_LOADS_FILTER_DATA: IFilterData = {
  status: [],
  number: [],
  name: [],
  vehicle: [],
  store: []
};

export class LoadStore extends BaseStore implements ILoadStore {
  private dragSrcOptions: IDragAndDropOptions;
  private visibleLoadsCounter: number = TM_LOADS_IN_VIEW;
  private dndSourceLoadId: string | null = null;
  private transportManagementPage:
    | ETransportPages.LOAD_OVERVIEW
    | ETransportPages.TRANSPORT_MANAGEMENT
    | ETransportPages.UNLINKED_ORDERS
    | null = null;

  public editableOrdersMap: Map<number, IOrder> = new Map();
  public date: number = null;
  public loadsMap: Map<string, ILoad> = new Map();
  public filteredLoadsMap: Map<string, ILoad> = new Map();
  public dragItemsMap: Map<number, IOrder> = new Map();
  public unlinkedOrdersMap: Map<number, IOrder> = new Map();
  public filteredOrdersMap: Map<number, IOrder> = new Map();
  public selectedUnlinkedOrdersCounter: number = 0;
  public isLoading = true;
  public shouldShowOCMTime: boolean = true;
  public error: IError = null;
  public filterString: string;
  public filterObject: IFilterData;
  public visibleLoadsMap: Map<string, ILoad> = new Map();
  public leftSideLoadsMap: Map<string, ILoad> = new Map();
  public rightSideLoadsMap: Map<string, ILoad> = new Map();
  public leftSidePinnedItem: ILoad = null;
  public rightSidePinnedItem: ILoad = null;
  public unlinkedOrdersModalOpen: boolean = false;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    const { dayjsInstance } = this.rootStore.localizationStore;
    const dateFromStorage = this.rootStore.localStorageStore.getItem(EStorageKeys.LOAD_DATE) as string;

    if (dateFromStorage) {
      this.date = dayjsInstance(dateFromStorage).tz('Europe/Amsterdam').valueOf();
    } else {
      this.date = dayjsInstance().tz('Europe/Amsterdam').valueOf();
    }

    const filterObjectFromStorage = this.rootStore.localStorageStore.getItem(
      EStorageKeys.LOAD_FILTER_OBJECT
    ) as IFilterData;
    this.filterObject = filterObjectFromStorage ?? INITIAL_LOADS_FILTER_DATA;

    const filterStringFromStorage = this.rootStore.localStorageStore.getItem(EStorageKeys.LOAD_FILTER_OBJECT) as string;
    this.filterString = filterStringFromStorage ?? '';

    makeObservable(this, {
      editableOrdersMap: observable,
      loadsMap: observable,
      filteredLoadsMap: observable,
      dragItemsMap: observable,
      unlinkedOrdersMap: observable,
      filteredOrdersMap: observable,
      selectedUnlinkedOrdersCounter: observable,
      date: observable,
      isLoading: observable,
      shouldShowOCMTime: observable,
      error: observable,
      filterObject: observable,
      filterString: observable,
      visibleLoadsMap: observable,
      leftSideLoadsMap: observable,
      rightSideLoadsMap: observable,
      leftSidePinnedItem: observable,
      rightSidePinnedItem: observable,
      unlinkedOrdersModalOpen: observable,
      isLoadingTransports: computed,
      isLoadingUnlinkedOrders: computed,
      isLoadingMoveOrdersData: computed,
      isLoadingCreateLoad: computed,
      setTransportManagementPage: action.bound,
      getLoads: action.bound,
      getLoadById: action.bound,
      createLoad: action.bound,
      createPlaceholder: action.bound,
      updateLoad: action.bound,
      deleteLoad: action.bound,
      setDate: action.bound,
      setEditableOrders: action.bound,
      updateEditableOrder: action.bound,
      submitEditableOrders: action.bound,
      filterLoads: action.bound,
      resetFilter: action.bound,
      getUnlinkedOrders: action.bound,
      getVisibleLoads: action.bound,
      switchLoads: action.bound,
      setOCMTime: action.bound,
      pinItem: action.bound,
      unpinItem: action.bound,
      onManualOrderUnlink: action.bound,
      onDrag: action.bound,
      onDrop: action.bound,
      addDragItem: action.bound,
      checkDragItemSelection: action.bound,
      selectAllUnlinkedOrders: action.bound,
      selectSingleUnlinkedOrder: action.bound,
      editUnlinkedOrders: action.bound,
      setVisibleLoadsCounter: action.bound,
      toggleUnlinkedOrdersModal: action.bound
    });

    reaction(
      () => ({
        filterObject: this.filterObject,
        filterString: this.filterString
      }),
      ({ filterObject, filterString }) => {
        this.rootStore.localStorageStore.setItem(EStorageKeys.LOAD_FILTER_OBJECT, filterObject);
        this.rootStore.localStorageStore.setItem(EStorageKeys.LOAD_FILTER_STRING, filterString);
      },
      {
        equals: isEqual
      }
    );

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get isLoadingTransports(): boolean {
    return this.getAsyncStatus('getLoads').loading;
  }

  public get isLoadingManualUnlink(): boolean {
    return this.getAsyncStatus('onManualOrderUnlink').loading || this.getAsyncStatus('getLoadById').loading;
  }

  public get isLoadingUnlinkedOrders(): boolean {
    return this.getAsyncStatus('getUnlinkedOrders').loading;
  }

  public get isLoadingMoveOrdersData(): boolean {
    return this.getAsyncStatus('onDrop').loading;
  }
  public get isLoadingCreateLoad(): boolean {
    return this.getAsyncStatus('createLoad').loading;
  }

  public setTransportManagementPage(
    value: ETransportPages.LOAD_OVERVIEW | ETransportPages.TRANSPORT_MANAGEMENT | ETransportPages.UNLINKED_ORDERS
  ): void {
    this.transportManagementPage = value;
  }

  public setVisibleLoadsCounter(): void {
    const leftPinnedCounter: number = this.leftSidePinnedItem ? 1 : 0;
    const rightPinnedCounter: number = this.rightSidePinnedItem ? 1 : 0;
    const unlinkedOrdersModalCounter: number = this.unlinkedOrdersModalOpen ? 1 : 0;
    this.visibleLoadsCounter = TM_LOADS_IN_VIEW - leftPinnedCounter - rightPinnedCounter - unlinkedOrdersModalCounter;
  }

  public toggleUnlinkedOrdersModal(): void {
    this.unlinkedOrdersModalOpen = !this.unlinkedOrdersModalOpen;
    this.setVisibleLoadsCounter();
    this.getVisibleLoads(this.filterString !== '', true);
  }

  public async getLoads(getAvailable?: boolean): Promise<void> {
    this.setLoading('getLoads');
    try {
      const { dayjsInstance } = this.rootStore.localizationStore;
      const deliveryDate = dayjsInstance(this.date).tz('Europe/Amsterdam').format(EDate.YYYY_MM_DD);
      const response = await this.rootStore.requester.transportService.getLoads({ deliveryDate });
      runInAction(() => {
        if (this.loadsMap.size) this.loadsMap.clear();

        for (const load of response) {
          const { timeGapHrs, timeGapMins } = this.getTimeGap(load);

          if (!getAvailable) {
            this.loadsMap.set(load.loadCode, LoadMapper.toState(load));
          }

          if (getAvailable && (timeGapHrs > 0 || timeGapMins > 0)) {
            this.loadsMap.set(load.loadCode, LoadMapper.toState(load));
          }
        }
        this.setSuccess('getLoads');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getLoads');
    }
  }

  public async getUnlinkedOrders(): Promise<void> {
    this.setLoading('getUnlinkedOrders');
    try {
      const { dayjsInstance } = this.rootStore.localizationStore;
      const date = dayjsInstance(this.date).format(EDate.YYYY_MM_DD);
      const response = await this.rootStore.requester.transportService.getUnlinkedOrders({ date });
      runInAction(() => {
        for (const order of response) {
          this.unlinkedOrdersMap.set(order.proNumber, order);
        }
        this.setSuccess('getUnlinkedOrders');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getUnlinkedOrders');
    }
  }

  public async getLoadById(loadDate: string, loadCode: string): Promise<void> {
    this.setLoading('getLoadById');
    try {
      const response = (await this.rootStore.requester.transportService.getByIdAndDate(loadDate, loadCode)) as LoadDTO;
      runInAction(() => {
        this.loadsMap.set(response.loadCode, LoadMapper.toState(response));
        this.setSuccess('getLoadById');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getLoadById');
    }
  }

  public async createLoad(data: IFormSubmitData): Promise<void> {
    this.setLoading('createLoad');
    try {
      const body = LoadMapper.toDto(data);
      const response = await this.rootStore.requester.transportService.create(body);
      if (response) {
        this.getLoads();
        this.setSuccess('createLoad');
        this.rootStore.routeStore.navigate(`${LOAD_OVERVIEW}/edit/${response.loadDate}/${response.loadCode}`);
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('createLoad');
    }
  }

  // @TODO: This request doesn't work
  public async createPlaceholder(data: IFormSubmitData): Promise<void> {
    this.setLoading('createPlaceholder');
    try {
      const { loadCode, loadDate } = this.rootStore.loadManager.currentLoad;
      const { dayjsInstance } = this.rootStore.localizationStore;
      const date = dayjsInstance(this.date).format(EDate.YYYY_MM_DD);
      const body = LoadMapper.toPlaceholderDto(data, loadCode, loadDate, date);
      const response = await this.rootStore.requester.transportService.createPlaceholder(body);
      if (response) {
        this.getLoads();
        this.setSuccess('createPlaceholder');
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('createPlaceholder');
    }
  }

  public async updateLoad(data: IFormSubmitData): Promise<void> {
    this.setLoading('updateLoad');
    try {
      const body = LoadMapper.toDto(data);
      const response = await this.rootStore.requester.transportService.updateLoad(body);
      if (response) {
        this.getLoads();
        this.setSuccess('updateLoad');
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('updateLoad');
    }
  }

  public async deleteLoad(loadDate: string, loadCode: string): Promise<void> {
    this.setLoading('deleteLoad');
    try {
      await this.rootStore.requester.transportService.deleteLoad(loadDate, loadCode);
      this.rootStore.loadManager.onDelete(loadCode);
      this.getLoads();
      this.getVisibleLoads();
      this.setSuccess('deleteLoad');
    } catch (e) {
      this.errorHandler(e);
      this.setError('deleteLoad');
    }
  }

  public setDate(date: number): void {
    this.date = date;
    this.rootStore.localStorageStore.setItem(EStorageKeys.LOAD_DATE, date);

    this.loadsMap = new Map();
    this.unlinkedOrdersMap = new Map();

    if (this.leftSidePinnedItem) this.unpinItem('left');
    if (this.rightSidePinnedItem) this.unpinItem('right');

    this.getUnlinkedOrders();

    if (this.transportManagementPage) {
      this.filterObject.name.length || this.filterObject.vehicle.length
        ? this.getVisibleLoads(true)
        : this.getVisibleLoads();
    } else {
      this.getLoads();
    }
  }

  public setEditableOrders(orders: IOrder[]): void {
    if (this.editableOrdersMap.size > 0) this.editableOrdersMap.clear();

    for (const order of orders) {
      this.editableOrdersMap.set(order.proNumber, order);
    }
  }

  public updateEditableOrder(order: IOrder): void {
    this.editableOrdersMap.set(order.proNumber, order);
  }

  public async submitEditableOrders(loadCode: string, loadDate: string): Promise<void> {
    try {
      this.setLoading('submitEditableOrders');
      const orders = [...this.editableOrdersMap.values()];
      const payload = LoadMapper.toUpdateOrders(loadCode, loadDate, orders);
      const response = await this.rootStore.requester.transportService.updateOrders(payload);
      if (response) {
        this.getLoadById(loadDate, loadCode);
        const notification = makeNotification('ORDERS_MOVED', 'success');
        this.notificationHandler(notification);
      }
      this.setSuccess('submitEditableOrders');
    } catch (e) {
      this.errorHandler(e);
      this.setError('submitEditableOrders');
    }
  }

  public filterLoads(value: SelectData[] | string[], key: string): void {
    const newData: IFilterData = {
      ...this.filterObject,
      [key]: value
    };
    this.filterObject = newData;

    const result = Object.values(newData)
      .map(val => val.join(' '))
      .filter(Boolean);
    this.filterString = result.join(' ');

    switch (this.transportManagementPage) {
      case ETransportPages.TRANSPORT_MANAGEMENT:
        if (this.leftSidePinnedItem) this.unpinItem('left');
        if (this.rightSidePinnedItem) this.unpinItem('right');
        this.getVisibleLoads(true);
        break;
      case ETransportPages.UNLINKED_ORDERS:
        this.filterUnlinkedLoads();
      case ETransportPages.LOAD_OVERVIEW:
      default:
        this.overviewFilter();
        break;
    }
  }

  public resetFilter(): void {
    this.filterObject = INITIAL_LOADS_FILTER_DATA;
    this.filterString = '';
    this.filteredLoadsMap = new Map();
    if (this.transportManagementPage) this.getVisibleLoads(true);
  }

  private getStartIndexes(loads: Map<string, ILoad>) {
    let visibleStartIndex;

    if (this.rootStore.loadManager.currentLoadId) {
      const currentLoadIndex = [...loads.keys()].indexOf(this.rootStore.loadManager.currentLoadId);
      this.rootStore.loadManager.currentLoadId = null;
      visibleStartIndex = currentLoadIndex;
    } else {
      visibleStartIndex = Math.floor((loads.size - this.visibleLoadsCounter) / 2);
    }

    return {
      visibleStartIndex,
      rightStartIndex: visibleStartIndex + this.visibleLoadsCounter
    };
  }

  public async getVisibleLoads(withFilter: boolean = false, refresh = true): Promise<void> {
    try {
      if (refresh) {
        this.leftSideLoadsMap = new Map();
        this.rightSideLoadsMap = new Map();
        this.visibleLoadsMap = new Map();
      }

      const loads = await this.getLoadsList(withFilter);

      if (loads.size <= this.visibleLoadsCounter) {
        this.visibleLoadsMap = loads;
        return;
      }

      if (this.visibleLoadsMap.size) {
        if (this.leftSidePinnedItem || this.rightSidePinnedItem) {
          this.calculateVisibleLoads();
          return;
        }
      }
      

      const { visibleStartIndex, rightStartIndex } = this.getStartIndexes(loads);

      let index = 0;

      runInAction(() => {
        for (const [key, load] of loads) {
          if (index < visibleStartIndex) {
            this.leftSideLoadsMap.set(key, load);
          }

          if (index >= visibleStartIndex && index < rightStartIndex) {
            this.visibleLoadsMap.set(key, load);
          }

          if (index >= rightStartIndex) {
            this.rightSideLoadsMap.set(key, load);
          }
          index += 1;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  public switchLoads(direction: 'left' | 'right'): void {
    if (direction === 'left') {
      let [key, load] = this.visibleLoadsMap.entries().next().value;
      this.leftSideLoadsMap.set(key, load);
      this.visibleLoadsMap.delete(key);
      if (this.rightSideLoadsMap.size) {
        [key, load] = this.rightSideLoadsMap.entries().next().value;
        this.visibleLoadsMap.set(key, load);
        this.rightSideLoadsMap.delete(key);
      }

      if (this.visibleLoadsMap.get(this.rightSidePinnedItem?.loadCode)) {
        this.unpinItem('right');
      }
    } else {
      let [key, load] = [...this.visibleLoadsMap][this.visibleLoadsMap.size - 1];
      let tempData = new Map();
      if (this.visibleLoadsMap.size === this.visibleLoadsCounter) {
        runInAction(() => {
          this.visibleLoadsMap.delete(key);

          tempData = this.rightSideLoadsMap;
          this.rightSideLoadsMap = new Map();
          this.rightSideLoadsMap.set(key, load);

          for (const [tempKey, tempLoad] of tempData) {
            this.rightSideLoadsMap.set(tempKey, tempLoad);
          }
        });
      }

      if (this.leftSideLoadsMap.size) {
        [key, load] = [...this.leftSideLoadsMap][this.leftSideLoadsMap.size - 1];
        this.leftSideLoadsMap.delete(key);
        tempData = this.visibleLoadsMap;
        this.visibleLoadsMap = new Map();
        this.visibleLoadsMap.set(key, load);
        for (const [tempKey, tempLoad] of tempData) {
          this.visibleLoadsMap.set(tempKey, tempLoad);
        }
      }

      if (this.visibleLoadsMap.get(this.leftSidePinnedItem?.loadCode)) {
        this.unpinItem('left');
      }
    }
  }

  public pinItem(side: 'left' | 'right', load: ILoad): void {
    if (side === 'left') {
      const prevPinned = this.leftSidePinnedItem;

      if (prevPinned) {
        this.unpinItem(side);
      }

      this.leftSidePinnedItem = load;
      this.setVisibleLoadsCounter();

      const [lastLoadKey, lastLoad] = [...this.visibleLoadsMap][this.visibleLoadsMap.size - 1];
      runInAction(() => {
        this.visibleLoadsMap.delete(lastLoadKey);

        const tempData = this.rightSideLoadsMap;
        this.rightSideLoadsMap = new Map();
        this.rightSideLoadsMap.set(lastLoadKey, lastLoad);

        for (const [tempKey, tempLoad] of tempData) {
          this.rightSideLoadsMap.set(tempKey, tempLoad);
        }
      });
    } else {
      const prevPinned = this.rightSidePinnedItem;

      if (prevPinned) {
        this.unpinItem(side);
      }

      this.rightSidePinnedItem = load;
      this.setVisibleLoadsCounter();

      const [lastLoadKey, lastLoad] = [...this.visibleLoadsMap][this.visibleLoadsMap.size - 1];
      runInAction(() => {
        this.visibleLoadsMap.delete(lastLoadKey);

        const tempData = this.rightSideLoadsMap;
        this.rightSideLoadsMap = new Map();
        this.rightSideLoadsMap.set(lastLoadKey, lastLoad);

        for (const [tempKey, tempLoad] of tempData) {
          this.rightSideLoadsMap.set(tempKey, tempLoad);
        }
      });
    }
  }

  public unpinItem(side: 'left' | 'right'): void {
    if (side === 'left') {
      this.leftSidePinnedItem = null;
      this.setVisibleLoadsCounter();
      const [key, load] = this.rightSideLoadsMap.entries().next().value;
      this.visibleLoadsMap.set(key, load);
      this.rightSideLoadsMap.delete(key);
    } else {
      this.rightSidePinnedItem = null;
      this.setVisibleLoadsCounter();
      const [key, load] = this.rightSideLoadsMap.entries().next().value;
      this.visibleLoadsMap.set(key, load);
      this.rightSideLoadsMap.delete(key);
    }
  }

  public setOCMTime(value: boolean): void {
    this.shouldShowOCMTime = value;
  }

  public onDrag(options: IDragAndDropOptions): void {
    this.dragSrcOptions = options;
  }

  public async onManualOrderUnlink(loadDate: string, loadCode: string, order: IOrder): Promise<void> {
    try {
      this.setLoading('onManualOrderUnlink');
      const currentLoad = this.loadsMap.get(loadCode);
      const payload = LoadMapper.toMoveOrphansDTO(currentLoad, [order]);
      const response = await this.rootStore.requester.transportService.unlinkOrder(payload);
      if (response) {
        this.getLoadById(loadDate, loadCode);
        const notification = makeNotification('UNLINKING_SUCCESSFUL', 'success');
        this.notificationHandler(notification);
      }
      this.setSuccess('onManualOrderUnlink');
    } catch (e) {
      this.errorHandler(e);
      this.setError('onManualOrderUnlink');
    }
  }

  public async onDrop(orders: IOrder[], options: IDragAndDropOptions, requestData: boolean = true): Promise<void> {
    try {
      this.setLoading('onDrop');

      switch (options.type) {
        case EDragAndDropType.UNLINKED:
          if (this.dragSrcOptions.type === options.type) {
            this.setSuccess('onDrop');
            return;
          }

          if (this.dragSrcOptions.type === EDragAndDropType.CANCELLED) {
            this.setSuccess('onDrop');
            return;
          }

          if (this.dragSrcOptions.type === EDragAndDropType.LOAD) {
            const currentLoadOptions: Record<string, boolean> = {
              isLeftPinned: this.leftSidePinnedItem?.loadCode === this.dragSrcOptions.sourceId,
              isRightPinned: this.rightSidePinnedItem?.loadCode === this.dragSrcOptions.sourceId,
              isNotPinned: !!this.visibleLoadsMap.get(this.dragSrcOptions.sourceId)
            };
            const currentLoad = currentLoadOptions.isLeftPinned
              ? this.leftSidePinnedItem
              : currentLoadOptions.isRightPinned
              ? this.rightSidePinnedItem
              : currentLoadOptions.isNotPinned
              ? this.visibleLoadsMap.get(this.dragSrcOptions.sourceId)
              : null;
            const payload = LoadMapper.toMoveOrphansDTO(currentLoad, orders);
            let tempOrdersMap: Map<number, IOrder> = new Map();
            for (const item of currentLoad.orders) {
              tempOrdersMap.set(item.proNumber, item);
            }
            for (const order of orders) {
              tempOrdersMap.delete(order.proNumber);
              this.unlinkedOrdersMap.set(order.proNumber, order);
            }
            const tempOrders = [...tempOrdersMap.values()];
            const rollies: number = tempOrders.reduce((prev, current) => prev + current.rollies, 0);
            const updatedCurrentLoad = {
              ...currentLoad,
              countOfOrders: tempOrders.length,
              loadFactor: ((rollies / currentLoad.transportTypeCapacity) * 100).toFixed(2),
              dispatchConsigneeCode: tempOrders[0]?.dispatchConsigneeCode || null,
              rollies,
              emptyRollies: currentLoad.transportTypeCapacity - rollies,
              orders: tempOrders
            };
            if (currentLoadOptions.isLeftPinned) {
              this.leftSidePinnedItem = updatedCurrentLoad;
            } else if (currentLoadOptions.isRightPinned) {
              this.rightSidePinnedItem = updatedCurrentLoad;
            } else if (currentLoadOptions.isNotPinned) {
              this.visibleLoadsMap.set(this.dragSrcOptions.sourceId, updatedCurrentLoad);
            }

            if (requestData) {
              const response = await this.rootStore.requester.transportService.unlinkOrder(payload);
              if (!response) throw new Error();
            }
            tempOrdersMap = null;
          }

          break;
        case EDragAndDropType.CANCELLED:
          if (this.dragSrcOptions.type === options.type) {
            this.setSuccess('onDrop');
            return;
          }

          break;
        case EDragAndDropType.LOAD:
          if (this.dragSrcOptions.sourceId === options.targetId) {
            this.setSuccess('onDrop');
            return;
          }

          if (this.dragSrcOptions.type === EDragAndDropType.LOAD) {
            const currentLoadOptions: Record<string, boolean> = {
              isLeftPinned: this.leftSidePinnedItem?.loadCode === this.dragSrcOptions.sourceId,
              isRightPinned: this.rightSidePinnedItem?.loadCode === this.dragSrcOptions.sourceId,
              isNotPinned: !!this.visibleLoadsMap.get(this.dragSrcOptions.sourceId)
            };
            const targetLoadOptions: Record<string, boolean> = {
              isLeftPinned: this.leftSidePinnedItem?.loadCode === options.targetId,
              isRightPinned: this.rightSidePinnedItem?.loadCode === options.targetId,
              isNotPinned: !!this.visibleLoadsMap.get(options.targetId)
            };
            const currentLoad = currentLoadOptions.isLeftPinned
              ? this.leftSidePinnedItem
              : currentLoadOptions.isRightPinned
              ? this.rightSidePinnedItem
              : currentLoadOptions.isNotPinned
              ? this.visibleLoadsMap.get(this.dragSrcOptions.sourceId)
              : null;
            const targetLoad = targetLoadOptions.isLeftPinned
              ? this.leftSidePinnedItem
              : targetLoadOptions.isRightPinned
              ? this.rightSidePinnedItem
              : targetLoadOptions.isNotPinned
              ? this.visibleLoadsMap.get(options.targetId)
              : null;
            if (!currentLoad || !targetLoad) throw new Error();
            const payload = LoadMapper.toMoveOrderDTO(currentLoad, targetLoad, orders);
            let tempOrdersMap: Map<number, IOrder> = new Map();
            for (const item of currentLoad.orders) {
              tempOrdersMap.set(item.proNumber, item);
            }
            for (const order of orders) {
              tempOrdersMap.delete(order.proNumber);
            }
            let tempOrders = [...tempOrdersMap.values()];
            let rollies: number = tempOrders.reduce((prev, current) => prev + current.rollies, 0);
            const updatedCurrentLoad = {
              ...currentLoad,
              countOfOrders: tempOrders.length,
              loadFactor: ((rollies / currentLoad.transportTypeCapacity) * 100).toFixed(2),
              dispatchConsigneeCode: tempOrders[0]?.dispatchConsigneeCode || null,
              rollies,
              emptyRollies: currentLoad.transportTypeCapacity - rollies,
              orders: tempOrders
            };
            if (currentLoadOptions.isLeftPinned) {
              this.leftSidePinnedItem = updatedCurrentLoad;
            } else if (currentLoadOptions.isRightPinned) {
              this.rightSidePinnedItem = updatedCurrentLoad;
            } else if (currentLoadOptions.isNotPinned) {
              this.visibleLoadsMap.set(currentLoad.loadCode, updatedCurrentLoad);
            }

            tempOrdersMap = new Map();
            for (const item of targetLoad.orders) {
              tempOrdersMap.set(item.proNumber, item);
            }
            for (const order of orders) {
              tempOrdersMap.set(order.proNumber, order);
            }
            tempOrders = [...tempOrdersMap.values()];
            rollies = tempOrders.reduce((prev, current) => prev + current.rollies, 0);
            let updatedTargetLoad = {
              ...targetLoad,
              countOfOrders: tempOrders.length,
              loadFactor: ((rollies / targetLoad.transportTypeCapacity) * 100).toFixed(2),
              dispatchConsigneeCode: tempOrders[0]?.dispatchConsigneeCode || null,
              rollies,
              emptyRollies: targetLoad.transportTypeCapacity - rollies,
              orders: tempOrders
            };
            if (targetLoadOptions.isLeftPinned) {
              this.leftSidePinnedItem = updatedTargetLoad;
            } else if (targetLoadOptions.isRightPinned) {
              this.rightSidePinnedItem = updatedTargetLoad;
            } else if (targetLoadOptions.isNotPinned) {
              this.visibleLoadsMap.set(targetLoad.loadCode, updatedTargetLoad);
            }

            if (requestData) {
              const response = await this.rootStore.requester.transportService.moveOrder(payload);
              if (!response) throw new Error();

              updatedTargetLoad = LoadMapper.toState(response);
              if (targetLoadOptions.isLeftPinned) {
                this.leftSidePinnedItem = updatedTargetLoad;
              } else if (targetLoadOptions.isRightPinned) {
                this.rightSidePinnedItem = updatedTargetLoad;
              } else if (targetLoadOptions.isNotPinned) {
                this.visibleLoadsMap.set(targetLoad.loadCode, updatedTargetLoad);
              }
            }
            tempOrdersMap = null;
          }

          if (this.dragSrcOptions.type === EDragAndDropType.CANCELLED) {
            this.setSuccess('onDrop');
            return;
          }

          if (this.dragSrcOptions.type === EDragAndDropType.UNLINKED) {
            const targetLoadOptions: Record<string, boolean> = {
              isLeftPinned: this.leftSidePinnedItem?.loadCode === options.targetId,
              isRightPinned: this.rightSidePinnedItem?.loadCode === options.targetId,
              isNotPinned: !!this.visibleLoadsMap.get(options.targetId)
            };
            const targetLoad = targetLoadOptions.isLeftPinned
              ? this.leftSidePinnedItem
              : targetLoadOptions.isRightPinned
              ? this.rightSidePinnedItem
              : targetLoadOptions.isNotPinned
              ? this.visibleLoadsMap.get(options.targetId)
              : null;

            const payload = LoadMapper.toMoveOrphansDTO(targetLoad, orders);

            const tempOrders = [...targetLoad.orders, ...orders];
            const rollies: number = tempOrders.reduce((prev, current) => prev + current.rollies, 0);
            let updatedTargetLoad = {
              ...targetLoad,
              countOfOrders: tempOrders.length,
              loadFactor: ((rollies / targetLoad.transportTypeCapacity) * 100).toFixed(2),
              dispatchConsigneeCode: tempOrders[0]?.dispatchConsigneeCode || null,
              rollies,
              emptyRollies: targetLoad.transportTypeCapacity - rollies,
              orders: tempOrders
            };
            if (targetLoadOptions.isLeftPinned) {
              this.leftSidePinnedItem = updatedTargetLoad;
            } else if (targetLoadOptions.isRightPinned) {
              this.rightSidePinnedItem = updatedTargetLoad;
            } else if (targetLoadOptions.isNotPinned) {
              this.visibleLoadsMap.set(options.targetId, updatedTargetLoad);
            }
            for (const order of orders) {
              this.unlinkedOrdersMap.delete(order.proNumber);
            }

            if (requestData) {
              const response = await this.rootStore.requester.transportService.moveOrderFromUnlinked(payload);
              if (!response) throw new Error();

              updatedTargetLoad = LoadMapper.toState(response);
              if (targetLoadOptions.isLeftPinned) {
                this.leftSidePinnedItem = updatedTargetLoad;
              } else if (targetLoadOptions.isRightPinned) {
                this.rightSidePinnedItem = updatedTargetLoad;
              } else if (targetLoadOptions.isNotPinned) {
                this.visibleLoadsMap.set(options.targetId, updatedTargetLoad);
              }
            }
          }

          break;
        default:
          this.setSuccess('onDrop');
          return;
      }

      this.dragItemsMap.clear();
      this.dragSrcOptions = null;
      this.dndSourceLoadId = null;
      this.setSuccess('onDrop');
    } catch (e) {
      const target = {
        ...this.dragSrcOptions,
        targetId: this.dragSrcOptions.sourceId
      };
      this.dragSrcOptions = {
        ...options,
        sourceId: options.targetId
      };
      this.onDrop(orders, target, false);

      this.errorHandler(e);
      this.setError('onDrop');
      const notification = makeNotification('OBM time is too late', 'error');
      this.notificationHandler(notification);
    }
  }

  public addDragItem(item: IOrder, isNew: boolean, loadId: string): void {
    if (this.dndSourceLoadId !== loadId) this.dragItemsMap.clear();

    this.dndSourceLoadId = loadId;
    if (isNew) {
      this.dragItemsMap.set(item.proNumber, item);
    } else {
      this.dragItemsMap.delete(item.proNumber);
      if (this.dragItemsMap.size === 0) {
        this.dragItemsMap.clear();
        this.dndSourceLoadId === null;
      }
    }
  }

  public checkDragItemSelection(id: number): boolean {
    return !!this.dragItemsMap.get(id);
  }

  public selectAllUnlinkedOrders(checked: boolean): void {
    for (const [key, order] of this.unlinkedOrdersMap) {
      this.unlinkedOrdersMap.set(key, {
        ...order,
        checked
      });
    }

    this.getSelectedOrdersCount();
  }

  public selectSingleUnlinkedOrder(orderId: number, checked: boolean): void {
    const order = this.unlinkedOrdersMap.get(orderId);
    this.unlinkedOrdersMap.set(orderId, {
      ...order,
      checked
    });

    this.getSelectedOrdersCount();
  }

  // @TODO: This request doesn't work
  public async editUnlinkedOrders(data: IFormSubmitData): Promise<void> {
    try {
      this.setLoading('editUnlinkedOrders');
      const selected = this.getSelectedOrders();
      const { dayjsInstance } = this.rootStore.localizationStore;
      const payload = LoadMapper.toEditOrdersDTO(data, selected, dayjsInstance(this.date).format(EDate.YYYY_MM_DD));

      let response: OrderDTO;
      if (data.formData.operation) {
        response = (await this.rootStore.requester.transportService.cancelOrder(payload)) as OrderDTO;
      } else {
        response = await this.rootStore.requester.transportService.updateOrder(payload);
      }
      if (response) {
        this.getUnlinkedOrders();
        this.setSuccess('editUnlinkedOrders');
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('editUnlinkedOrders');
    }
  }

  private calculateVisibleLoads(): void {
    if (this.unlinkedOrdersModalOpen) {
      const [key, load] = [...this.visibleLoadsMap][this.visibleLoadsMap.size - 1];
      runInAction(() => {
        this.visibleLoadsMap.delete(key);
        const tempData = this.rightSideLoadsMap;
        this.rightSideLoadsMap = new Map();
        this.rightSideLoadsMap.set(key, load);

        for (const [tempKey, tempLoad] of tempData) {
          this.rightSideLoadsMap.set(tempKey, tempLoad);
        }
      });
    } else {
      const [key, load] = this.rightSideLoadsMap.entries().next().value;
      runInAction(() => {
        this.visibleLoadsMap.set(key, load);
        this.rightSideLoadsMap.delete(key);
      });
    }
  }

  private filterUnlinkedLoads(): void {
    let temp: Map<number, IOrder> = new Map();
    temp = this.unlinkedOrdersMap;

    if (!!this.filterObject.name.length) {
      temp = this.applyFilterToOrders(this.filterObject.name, temp);
    }

    this.filteredOrdersMap = temp;
  }

  private overviewFilter(): void {
    let temp: Map<string, ILoad> = new Map();

    temp = this.loadsMap;
    if (!!this.filterObject.vehicle.length) {
      temp = this.applyFilterToLoads(this.filterObject.vehicle, 'transportTypeCode', temp);
    }

    if (!!this.filterObject.name.length) {
      temp = this.applyFilterToLoads(this.filterObject.name, 'dispatchConsigneeCode', temp);
    }

    if (!!this.filterObject.number.length) {
      temp = this.applyFilterToLoads(this.filterObject.number, 'loadDesignation', temp, {
        isSingleFilter: true
      });
    }

    if (!!this.filterObject.status.length) {
      temp = this.applyFilterToLoads(this.filterObject.status, 'status', temp);
    }

    if (!!this.filterObject.store.length) {
      temp = this.applyFilterToLoads(this.filterObject.store, 'storeCode', temp, {
        isOrder: true,
        isSingleFilter: true
      });
    }

    this.filteredLoadsMap = temp;
  }

  private getSelectedOrders(): IOrder[] {
    return [...this.unlinkedOrdersMap.values()].filter(order => order.checked);
  }

  private getSelectedOrdersCount(): void {
    const selected = this.getSelectedOrders();
    this.selectedUnlinkedOrdersCounter = selected.length;
  }

  private getTimeGap(load: ILoad | LoadDTO): { timeGapHrs: number; timeGapMins: number } {
    const { dayjsInstance } = this.rootStore.localizationStore;
    const timeGap = dayjsInstance(`${load.plannedLoadingDate} ${load.cutOffTime}`);

    return {
      timeGapHrs: timeGap.get('hours'),
      timeGapMins: timeGap.get('minutes')
    };
  }

  private async getLoadsList(withFilter: boolean): Promise<Map<string, ILoad>> {
    if (withFilter) {
      await this.getLoads(true);
      let temp: Map<string, ILoad> = new Map();

      temp = this.loadsMap;

      if (!!this.filterObject.vehicle.length) {
        temp = this.applyFilterToLoads(this.filterObject.vehicle, 'transportTypeCode', temp);
      }

      if (!!this.filterObject.name.length) {
        temp = this.applyFilterToLoads(this.filterObject.name, 'dispatchConsigneeCode', temp);
      }

      if (!!this.filterObject.number.length) {
        temp = this.applyFilterToLoads(this.filterObject.number, 'loadDesignation', temp, { isSingleFilter: true });
      }
      return temp;
    } else {
      this.loadsMap.clear();
      if (!this.loadsMap.size) await this.getLoads(true);
      return this.loadsMap;
    }
  }

  private applyFilterToOrders(filter: SelectData[], ordersMap: Map<number, IOrder>): Map<number, IOrder> {
    const temp: Map<number, IOrder> = new Map();
    for (const [key, order] of ordersMap) {
      if (filter.some(item => item.value === order.dispatchConsigneeCode)) {
        temp.set(key, order);
      }
    }
    return temp;
  }

  private applyFilterToLoads(
    rawFilter: SelectData[] | string[],
    filteredKey: string,
    loadsMap: Map<string, ILoad>,
    options: Record<string, boolean> = {
      isOrder: false,
      isSingleFilter: false
    }
  ): Map<string, ILoad> {
    const { isOrder, isSingleFilter } = options;
    const temp: Map<string, ILoad> = new Map();
    const filter: string[] = isSingleFilter
      ? (rawFilter as string[])
      : (rawFilter as SelectData[]).map(item => item.value as string);

    for (const [key, load] of loadsMap) {
      if (isOrder && isSingleFilter) {
        const filteredOrders: IOrder[] = load.orders.filter(order => order[filteredKey].includes(filter));

        if (!!filteredOrders.length) {
          temp.set(key, load);
        }
      }

      if (
        (!isSingleFilter && !isOrder && filter.includes(load[filteredKey])) ||
        (isSingleFilter && !isOrder && load[filteredKey].includes(filter))
      ) {
        temp.set(key, load);
      }
    }

    return temp;
  }
}
