import { ENABLE_LOGGER } from 'config';
import { ESelectOptionsData } from 'enums';
import { DistributionCenterMapper, createSelectOptionsData } from 'helpers';
import { IDistributionCenter, IDistributionCenterStore, IError, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { SelectData } from 'mui-rff';

import { BaseStore } from './BaseStore';

export class DistributionCenterStore extends BaseStore implements IDistributionCenterStore {
  public distributionCentersMap: Map<string, IDistributionCenter> = new Map();
  public isLoading = true;
  public error: IError = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      distributionCentersMap: observable,
      isLoading: observable,
      error: observable,
      isLoadingDistributionCenters: computed,
      getDistributionCenters: action.bound,
      getDistributionCentersSelectData: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get isLoadingDistributionCenters(): boolean {
    return this.getAsyncStatus('getDistributionCenters').loading;
  }

  public async getDistributionCenters(): Promise<void> {
    this.setLoading('getDistributionCenters');
    try {
      const response = await this.rootStore.requester.distributionCenterService.getAll();
      runInAction(() => {
        for (const distributionCenter of response) {
          this.distributionCentersMap.set(
            distributionCenter.id.toString(),
            DistributionCenterMapper.toState(distributionCenter)
          );
        }
        this.setSuccess('getDistributionCenters');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getDistributionCenters');
    }
  }

  public getDistributionCentersSelectData(withoutDefault: boolean = false): SelectData[] {
    return createSelectOptionsData(this.distributionCentersMap, ESelectOptionsData.DISTRIBUTION_CENTER, withoutDefault);
  }
}
