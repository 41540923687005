import { ENABLE_LOGGER } from 'config';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { EDate } from 'enums';
import { SlotbookingMapper } from 'helpers';
import { IError, IRootStore, ISlotbooking, ISlotbookingStore } from 'interfaces';
import { localeMap } from 'localization';
import { action, computed, makeObservable, observable } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseStore } from './BaseStore';

dayjs.extend(weekOfYear);

export class SlotbookingStore extends BaseStore implements ISlotbookingStore {
  public date: number = dayjs().locale('nl', { weekStart: 1 }).valueOf();
  public startDate: string = this.getDate('startOf');
  public endDate: string = this.getDate('endOf');
  public slotbookingItemsMap: Map<string, ISlotbooking> = new Map();
  public isLoading = true;
  public error: IError = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      slotbookingItemsMap: observable,
      startDate: observable,
      endDate: observable,
      isLoading: observable,
      error: observable,
      isLoadingSlotbooking: computed,
      weekCount: computed,
      weekStartDate: computed,
      weekEndDate: computed,
      getSlotbookingItems: action.bound,
      setDate: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get isLoadingSlotbooking(): boolean {
    return this.getAsyncStatus('getSlotbookingItems').loading;
  }

  public get weekCount(): number {
    return dayjs(this.startDate).week();
  }

  public get weekStartDate(): string {
    const locale = localeMap[this.rootStore.localizationStore.langName];
    return dayjs(this.startDate).locale(locale).format(EDate.DD_MMMM_YYYY);
  }

  public get weekEndDate(): string {
    const locale = localeMap[this.rootStore.localizationStore.langName];
    return dayjs(this.endDate).locale(locale).format(EDate.DD_MMMM_YYYY);
  }

  public async getSlotbookingItems(): Promise<void> {
    this.setLoading('getSlotbookingItems');
    try {
      const { startDate, endDate } = this;
      const requestOptions = {
        params: {
          startDate,
          endDate,
          vehicleTypeCode: 'LZV' // hardcoded parameter as WMS requires it to be set in the request
        }
      };
      const response = await this.rootStore.requester.slotbookingService.getAll(requestOptions);
      if (response) {
        const mappedResponse: ISlotbooking[] = SlotbookingMapper.toState(response);
        for (const item of mappedResponse) {
          this.slotbookingItemsMap.set(item.id as string, item);
        }
        this.setSuccess('getSlotbookingItems');
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getSlotbookingItems');
    }
  }

  public setDate(date: number): void {
    this.date = date;
    this.startDate = this.getDate('startOf');
    this.endDate = this.getDate('endOf');
    this.getSlotbookingItems();
  }

  private getDate(type: 'startOf' | 'endOf', date: number = this.date): string {
    const timestamp = dayjs(date).locale('nl', { weekStart: 1 })[type]('week').valueOf();

    return dayjs(timestamp).format(EDate.YYYY_MM_DD);
  }
}
