import { DistributionCenterDTO } from 'dtos';
import { IDistributionCenter, IMapper } from 'interfaces';

export class DistributionCenterMapper implements IMapper<IDistributionCenter> {
  static toState(data: DistributionCenterDTO): IDistributionCenter {
    return {
      description: data.description,
      id: data.id.toString(),
      name: data.code
    };
  }
}
