import { ENABLE_LOGGER } from 'config';
import { INotification, INotificationStore, IRootStore } from 'interfaces';
import { action, makeObservable, observable } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseStore } from './BaseStore';

export class NotificationStore extends BaseStore implements INotificationStore {
  public notification: INotification = null;
  public isOpen: boolean = false;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      notification: observable,
      isOpen: observable,
      showMessage: action.bound,
      closeMessage: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public showMessage(data: INotification): void {
    this.notification = data;
    this.isOpen = true;
  }

  public closeMessage(): void {
    this.notification = null;
    this.isOpen = false;
  }
}
