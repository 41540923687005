import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IBaseObject, IBaseService, IUrlOverrides } from 'interfaces';

export class BaseApiService<Entity extends IBaseObject> implements IBaseService<Entity> {
  constructor(readonly instance: AxiosInstance, readonly url: string, readonly urlOverrides: IUrlOverrides = {}) {}

  // Get list of instances
  public async getAll(options?: AxiosRequestConfig): Promise<Entity[]> {
    const apiUrl = this.urlOverrides.getAllUrl || this.url;
    return await this.instance.get(apiUrl, options).then(({ data }: AxiosResponse<Entity[]>) => data);
  }

  // Get single instance by its ID
  public async getById(id: string): Promise<Entity | Entity[]> {
    const apiUrl = this.urlOverrides.getByIdUrl || `${this.url}/${id}`;
    return await this.instance.get(apiUrl).then(({ data }: AxiosResponse<Entity>) => data);
  }

  // Create new instance
  public async create<T>(body: Entity | T): Promise<Entity> {
    const apiUrl = this.urlOverrides.createUrl || this.url;
    return await this.instance.post(apiUrl, body).then(({ data }: AxiosResponse<Entity>) => data);
  }

  // Update existing instance by its ID
  public async update(id: string, body: Entity): Promise<Entity> {
    const apiUrl = this.urlOverrides.updateUrl || `${this.url}/${id}`;
    return await this.instance.put(apiUrl, body).then(({ data }: AxiosResponse<Entity>) => data);
  }

  // Update existing instance by its ID
  public async delete(id: string): Promise<void> {
    const apiUrl = this.urlOverrides.deleteUrl || `${this.url}/${id}`;
    return await this.instance.delete(apiUrl).then(({ data }: AxiosResponse<void>) => data);
  }
}
