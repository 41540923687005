import { ENABLE_LOGGER, NODE_ENV } from 'config';
import { globalAdminRoles } from 'const/roles';
import { EStorageKeys } from 'enums';
import { checkRoles } from 'helpers';
import { IRootStore, ITenant, ITenantStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { PlainData } from 'simple-crypto-js';

import { BaseStore } from './BaseStore';

export class TenantStore extends BaseStore implements ITenantStore {
  public currentTenantId: number = null;
  public allTenants: ITenant[] = [];
  public userTenants: ITenant[] = [];

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      currentTenantId: observable,
      allTenants: observable,
      userTenants: observable,
      tenantName: computed,
      getAllTenants: action.bound,
      getUserTenants: action.bound,
      getAvailableTenants: action.bound,
      setCurrentTenantId: action.bound,
      clearCurrentTenant: action.bound,
      getTenantFromStore: action.bound,
      getTenantsList: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get tenantName(): string {
    const tenants = this.userTenants || (this.getTenantFromStore() as ITenant[]);
    return tenants.find(tenant => tenant.id === this.currentTenantId)?.title;
  }

  public async getAllTenants(userId: number): Promise<void> {
    this.setLoading('getAllTenants');
    try {
      const userTenants = await this.rootStore.requester.tenantService.getAll();
      this.applyTenants(userTenants, userId);
      this.setSuccess('getAllTenants');
    } catch (e) {
      this.errorHandler(e);
      this.setError('getAllTenants');
    }
  }

  public async getUserTenants(userId: number): Promise<void> {
    this.setLoading('getUserTenants');
    try {
      const userTenants = await this.rootStore.requester.tenantService.getUserTenants(userId);

      this.applyTenants(userTenants, userId);
      this.setSuccess('getUserTenants');
    } catch (e) {
      this.errorHandler(e);
      this.setError('getUserTenants');
    }
  }

  public async getAvailableTenants(userId: number): Promise<void> {
    const { roles } = this.rootStore.authStore.user;
    const isSuperAdmin = checkRoles(roles, globalAdminRoles);

    if (isSuperAdmin) {
      await this.rootStore.tenantStore.getAllTenants(userId);
    } else {
      await this.rootStore.tenantStore.getUserTenants(userId);
    }
  }

  public setCurrentTenantId(tenantId: number, isRefresh = true): void {
    this.currentTenantId = tenantId;
    this.rootStore.requester.updateAPIUrls(tenantId);
    this.rootStore.localStorageStore.setItem(
      `${EStorageKeys.CURRENT_TENANT}${this.rootStore.userStore.userProfile.id}`,
      tenantId
    );

    if (NODE_ENV !== 'test' && isRefresh) document.location.reload();
  }

  public getTenantFromStore(userIdProp?: number): PlainData {
    const userId = userIdProp || this.rootStore.userStore.userProfile?.id;
    if (userId) {
      return this.rootStore.localStorageStore.getItem(`${EStorageKeys.CURRENT_TENANT}${userId}`);
    }
  }

  public getTenantsList(): ITenant[] {
    return this.rootStore.localStorageStore.getItem(EStorageKeys.TENANTS) as ITenant[];
  }

  public clearCurrentTenant(): void {
    this.currentTenantId = null;
  }

  private applyTenants(userTenants: ITenant[], userId: number): void {
    const isPresentedInList = userTenants.some(tenant => tenant.id === this.currentTenantId);

    if (!isPresentedInList) {
      runInAction(() => {
        this.currentTenantId = null;
      });

      this.rootStore.localStorageStore.removeItem(`${EStorageKeys.CURRENT_TENANT}${userId}`);
    }

    if (!this.currentTenantId) {
      const { maintenanceOngoing } = this.rootStore.authStore.user;
      const allowedTenants = userTenants.filter(tenant => !maintenanceOngoing.includes(tenant.id));
      if (allowedTenants.length === 0) throw new Error('10');

      const newTenantId = Number(this.getTenantFromStore(userId)) || allowedTenants[0].id;

      runInAction(() => {
        this.currentTenantId = newTenantId;
      });
      this.rootStore.requester.updateAPIUrls(newTenantId);
      this.rootStore.localStorageStore.setItem(`${EStorageKeys.CURRENT_TENANT}${userId}`, newTenantId);
      this.rootStore.localStorageStore.setItem(EStorageKeys.TENANTS, userTenants);
    }

    runInAction(() => {
      this.userTenants = userTenants;
    });
  }
}
