import { ENABLE_LOGGER } from 'config';
import dayjs from 'dayjs';
import { UpdateStockDTO } from 'dtos';
import { EDate, EStockFilter } from 'enums';
import { saveAs } from 'file-saver';
import { StockMapper, createStockFilter } from 'helpers';
import {
  IError,
  IFormSubmitData,
  IRootStore,
  IStockFilterDTO,
  IStockItem,
  IStockStore,
  IStockSubgroup
} from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseStore } from './BaseStore';

export class StockStore extends BaseStore implements IStockStore {
  public stockItemsMap: Map<string, IStockItem> = new Map();
  public isLoading = true;
  public error: IError = null;
  public filter: IStockFilterDTO = {};

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      stockItemsMap: observable,
      isLoading: observable,
      error: observable,
      filter: observable,
      isLoadingStockItems: computed,
      isLoadingCSV: computed,
      setInitialFilter: action.bound,
      getStockItems: action.bound,
      downloadStockData: action.bound,
      updateStockItems: action.bound,
      updateQuarantineItems: action.bound,
      updateLastShipmentDate: action.bound,
      setFilter: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get isLoadingStockItems(): boolean {
    return this.getAsyncStatus('getStockItems').loading;
  }

  public get isLoadingCSV(): boolean {
    return this.getAsyncStatus('downloadStockData').loading;
  }

  public setInitialFilter(type: string = EStockFilter.VIEW_STOCK, supplierId?: string): void {
    this.filter = createStockFilter(type, supplierId);
  }

  public async getStockItems(): Promise<void> {
    this.setLoading('getStockItems');
    try {
      const response = await this.rootStore.requester.stockService.getStockItems(this.filter);
      runInAction(() => {
        if (this.stockItemsMap.size) this.stockItemsMap.clear();

        for (const item of response) {
          this.stockItemsMap.set(
            `${item.colloGtin}_${item.batch}_${item.lastShipmentDate}_${item.bestBeforeDate} `,
            StockMapper.toState(item)
          );
        }
        this.setSuccess('getStockItems');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getStockItems');
    }
  }

  public async downloadStockData(): Promise<void> {
    this.setLoading('downloadStockData');

    try {
      const data = await this.rootStore.requester.stockService.downloadStockData();
      const blob = new Blob([data], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(blob, `export_stock_${dayjs().format(EDate.YYYY_MM_DD_HHMMSS)}.csv`);

      runInAction(() => {
        this.setSuccess('downloadStockData');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('downloadStockData');
    }
  }

  public async updateStockItems(data: IFormSubmitData, rows: IStockSubgroup[]): Promise<void> {
    this.setLoading('updateStockItems');
    try {
      const payload: UpdateStockDTO = StockMapper.toUpdateStockDTO(data, rows);
      const response = await this.rootStore.requester.stockService.updateStockItems(payload);
      if (response) {
        if (this.stockItemsMap.size) this.stockItemsMap.clear();

        this.setInitialFilter(EStockFilter.BLOCK_STOCK);
        this.getStockItems();
        this.setSuccess('updateStockItems');
      }
    } catch (e) {
      this.errorHandler(e, false);
      this.setError('updateStockItems');
    }
  }

  public async updateQuarantineItems(data: IFormSubmitData, rows: IStockSubgroup[]): Promise<void> {
    this.setLoading('updateQuarantineItems');
    try {
      const payload: UpdateStockDTO = StockMapper.toUpdateStockDTO(data, rows);
      const response = await this.rootStore.requester.stockService.updateQuarantineItems(payload);
      if (response) {
        if (this.stockItemsMap.size) this.stockItemsMap.clear();

        this.setInitialFilter(EStockFilter.QUARANTAINE_MANAGEMENT);
        this.getStockItems();
        this.setSuccess('updateQuarantineItems');
      }
    } catch (e) {
      this.errorHandler(e, false);
      this.setError('updateQuarantineItems');
    }
  }

  public async updateLastShipmentDate(
    data: IFormSubmitData,
    rows: IStockSubgroup[],
    supplierId?: string,
    isCodeDispensation?: boolean
  ): Promise<void> {
    this.setLoading('updateLastShipmentDate');
    try {
      const payload: UpdateStockDTO = StockMapper.toUpdateStockDTO(data, rows, supplierId);
      const response = await this.rootStore.requester.stockService.updateLastShipmentDate(payload);
      if (response) {
        if (this.stockItemsMap.size) this.stockItemsMap.clear();
        const filter = isCodeDispensation ? undefined : EStockFilter.CODE_DISPENSATION;

        this.setInitialFilter(filter, supplierId);
        this.getStockItems();
        this.setSuccess('updateLastShipmentDate');
      }
    } catch (e) {
      this.errorHandler(e, false);
      this.setError('updateLastShipmentDate');
    }
  }

  public setFilter(filter: IStockFilterDTO): void {
    this.filter = {
      ...this.filter,
      ...filter
    };

    this.getStockItems();
  }
}
