import dayjs from 'dayjs';
import { SlotbookingDTO } from 'dtos';
import { EDate } from 'enums';
import { IMapper, ISlotbooking } from 'interfaces';

export class SlotbookingMapper implements IMapper<ISlotbooking> {
  static toState(data: SlotbookingDTO[]): ISlotbooking[] {
    const result: ISlotbooking[] = [];
    for (const item of data) {
      const date: string = dayjs(item.date).locale('en').format(EDate.DDDD);
      const key = `date${date}`;

      for (let index = 0; index < item.timeslots.length; index++) {
        const slot = item.timeslots[index];
        const normalizedSlot = {
          id: index.toString(),
          [key]: slot
        };

        result[index] = {
          ...(result[index] || {}),
          ...normalizedSlot
        };
      }
    }

    return result;
  }
}
