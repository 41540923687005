import { AxiosInstance, AxiosResponse } from 'axios';
import { IMailinglistsItem, IMailinglistsSubscriptions } from 'interfaces';

import { BaseApiService } from './BaseApiService';

export class MailinglistsService extends BaseApiService<IMailinglistsItem> {
  constructor(readonly instance: AxiosInstance, readonly url: string, readonly additionalUrl: string) {
    super(instance, url, {
      getAllUrl: additionalUrl,
      updateUrl: additionalUrl,
      createUrl: additionalUrl
    });
  }

  public async getMailinglistsSubscriptions(organizationId: number): Promise<IMailinglistsSubscriptions[]> {
    return this.instance
      .get(`${this.url}/subscriptions/${organizationId}`)
      .then((res: AxiosResponse<IMailinglistsSubscriptions[]>) => {
        return res.data;
      });
  }

  public async updateMailinglistsSubscriptions(
    organizationId: number,
    body: {
      mailinglistId: number;
      subscribe: boolean;
      userIds: number[];
    }
  ): Promise<IMailinglistsSubscriptions[]> {
    return this.instance
      .post(`${this.url}/subscriptions/${organizationId}`, body)
      .then((res: AxiosResponse<IMailinglistsSubscriptions[]>) => {
        return res.data;
      });
  }

  public async deleteMailinglistsSubscriptions(id: string): Promise<void> {
    const apiUrl = `${this.additionalUrl}/${id}`;
    return await this.instance.delete(apiUrl).then(({ data }: AxiosResponse<void>) => data);
  }
}
