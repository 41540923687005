import { AxiosInstance, AxiosResponse } from 'axios';
import { AuthNewSupplierDTO, CustomerSuppliersDTO } from 'dtos';
import { IBaseEntity } from 'interfaces';
import { ICarrier } from 'interfaces/models/ICarrier';
import { ICarrierResponse } from 'interfaces/models/ICarrierResponse';

import { BaseApiService } from './BaseApiService';

export class SuppliersService extends BaseApiService<IBaseEntity> {
  private additionalUrl: string = null;
  constructor(instance: AxiosInstance, url: string, additionalUrl: string) {
    super(instance, url);
    this.additionalUrl = additionalUrl;
  }

  public async getCarriers(supplierId: string): Promise<ICarrier[]> {
    return await this.instance
      .get(`${this.url}/${supplierId}/carriers/AUTH_PLAN_APPOINTMENTS/unauthorized`)
      .then((res: AxiosResponse<ICarrierResponse>) => {
        return res.data.carriers;
      });
  }

  public async createCarrier(
    body: AuthNewSupplierDTO,
    orgId: string,
    carrierId: string
  ): Promise<CustomerSuppliersDTO> {
    return await this.instance
      .post(`${this.additionalUrl}/${orgId}/carriers/${carrierId}`, body)
      .then(({ data }: AxiosResponse<CustomerSuppliersDTO>) => data);
  }

  public async getAllCarriers(supplierId: string): Promise<CustomerSuppliersDTO> {
    return await this.instance
      .get(`${this.additionalUrl}/${supplierId}/carriers`)
      .then((res: AxiosResponse<CustomerSuppliersDTO>) => {
        return res.data;
      });
  }
}
