import { AUTH_HEADER, LOCAL_STORAGE_PREFIX, SEPARATOR } from 'const';
import { ILocalStorageStore } from 'interfaces';
import SimpleCrypto, { PlainData } from 'simple-crypto-js';

const crypto = new SimpleCrypto(`${LOCAL_STORAGE_PREFIX}${AUTH_HEADER}${SEPARATOR}`);

export class LocalStorageStore implements ILocalStorageStore {
  public static KEYS = {
    TOKEN: 'token'
  };

  public setItem(key: string, value: PlainData): void {
    const internalKey: string = encodeURIComponent(`${LOCAL_STORAGE_PREFIX}${key}`);
    localStorage.setItem(`${internalKey}`, `${crypto.encrypt(value)}`);
  }

  public getItem(key: string): PlainData | null {
    const internalKey: string = encodeURIComponent(`${LOCAL_STORAGE_PREFIX}${key}`);
    const value = localStorage.getItem(`${internalKey}`);
    if (!value) {
      return null;
    }
    return crypto.decrypt(value);
  }

  public removeItem(key: string): void {
    const internalKey: string = encodeURIComponent(`${LOCAL_STORAGE_PREFIX}${key}`);
    localStorage.removeItem(`${internalKey}`);
  }

  public clearStorage(): void {
    localStorage.clear();
  }
}
