import { ENABLE_LOGGER } from 'config';
import { IMailinglistsItem, IMailinglistsStore, IMailinglistsSubscriptions, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { Row } from 'react-table';

import { BaseStore } from './BaseStore';

export class MailinglistsStore extends BaseStore implements IMailinglistsStore {
  public mailinglists: IMailinglistsItem[] = [];
  public mailinglistsSubscriptions: IMailinglistsSubscriptions[] = [];

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      mailinglists: observable,
      mailinglistsSubscriptions: observable,
      getMailinglists: action.bound,
      newMailinglistsItem: action.bound,
      deleteMailinglistsItem: action.bound,
      getMailinglistsSubscriptions: action.bound,
      updateMailinglistsSubscriptionsItem: action.bound,
      isMailinglistsLoading: computed,
      isMailinglistsSubscriptionsLoading: computed
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public async getMailinglists(): Promise<void> {
    this.setLoading('getMailinglists');
    try {
      const mailinglistsItems = await this.rootStore.requester.mailinglistsService.getAll();
      if (mailinglistsItems) {
        runInAction(() => {
          this.mailinglists = mailinglistsItems;
          this.setSuccess('getMailinglists');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getMailinglists');
    }
  }

  public async newMailinglistsItem(name: string): Promise<void> {
    this.setLoading('newMailinglistsItem');
    try {
      const mailinglistsItem = await this.rootStore.requester.mailinglistsService.create({
        name
      });
      if (mailinglistsItem) {
        runInAction(() => {
          this.mailinglists = [...this.mailinglists, mailinglistsItem];
          this.setSuccess('newMailinglistsItem');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('newMailinglistsItem');
    }
  }

  public async deleteMailinglistsItem(id: string): Promise<void> {
    this.setLoading('deleteMailinglistsItem');
    try {
      await this.rootStore.requester.mailinglistsService.deleteMailinglistsSubscriptions(id);
      await this.getMailinglists();

      runInAction(() => {
        this.setSuccess('deleteMailinglistsItem');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('deleteMailinglistsItem');
    }
  }

  public async getMailinglistsSubscriptions(): Promise<void> {
    this.setLoading('getMailinglistsSubscriptions');
    try {
      const currentTenant = this.rootStore.tenantStore.currentTenantId;
      const organizationId = this.rootStore.authStore.user.tenantOrganizationInfo[currentTenant].organizationId;
      const mailinglistsSubscriptions = await this.rootStore.requester.mailinglistsService.getMailinglistsSubscriptions(
        organizationId
      );

      runInAction(() => {
        this.mailinglistsSubscriptions = mailinglistsSubscriptions;
        this.setSuccess('getMailinglistsSubscriptions');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getMailinglistsSubscriptions');
    }
  }

  public async updateMailinglistsSubscriptionsItem(
    rows: Row<IMailinglistsSubscriptions>[],
    subscribe: boolean,
    mailinglistId: number
  ): Promise<void> {
    this.setLoading('updateMailinglistsSubscriptionsItem');
    try {
      const currentTenant = this.rootStore.tenantStore.currentTenantId;
      const organizationId = this.rootStore.authStore.user.tenantOrganizationInfo[currentTenant].organizationId;

      await this.rootStore.requester.mailinglistsService.updateMailinglistsSubscriptions(organizationId, {
        subscribe,
        mailinglistId: +mailinglistId,
        userIds: rows.map(e => e.original.userId)
      });

      await this.getMailinglistsSubscriptions();

      runInAction(() => {
        this.setSuccess('updateMailinglistsSubscriptionsItem');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('updateMailinglistsSubscriptionsItem');
    }
  }

  public get isMailinglistsLoading(): boolean {
    return (
      this.getAsyncStatus('getMailinglists').loading ||
      this.getAsyncStatus('newMailinglistsItem').loading ||
      this.getAsyncStatus('deleteMailinglistsItem').loading
    );
  }

  public get isMailinglistsSubscriptionsLoading(): boolean {
    return this.getAsyncStatus('getMailinglistsSubscriptions').loading;
  }
}
