import { ENABLE_LOGGER } from 'config';
import isEqual from 'fast-deep-equal';
import { IBaseEntity, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseManager } from './BaseManager';

export class SupplierManager extends BaseManager {
  public currentSupplierId: string = null;
  public isDeleted: boolean = false;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      currentSupplierId: observable,
      isDeleted: observable,
      currentSupplier: computed,
      setCurrentSupplierId: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);

    reaction(
      () => this.currentSupplier?.id,
      (value, previousValue, reaction) => {
        if (ENABLE_LOGGER) console.log({ previousValue, value, reaction });
      },
      {
        equals: isEqual
      }
    );
  }

  public get currentSupplier(): IBaseEntity {
    return this.rootStore.supplierStore.suppliersMap.get(this.currentSupplierId) || null;
  }

  public setCurrentSupplierId(id: string): void {
    this.currentSupplierId = id;
    this.rootStore.tableDataManager.setCurrentRowId(id);
  }
}
