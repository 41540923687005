import { VehicleTypeDTO } from 'dtos';
import { IMapper, IVehicleType } from 'interfaces';

export class VehicleTypeMapper implements IMapper<IVehicleType> {
  static toState(data: VehicleTypeDTO): IVehicleType {
    return {
      ...data,
      id: data.id.toString(),
      name: data.displayCode
    };
  }
}
