import { LoadDTO } from 'dtos';
import { ILoadSummary } from 'interfaces';

export const toSummary = (data: LoadDTO[]): ILoadSummary => {
  let capacity = 0;
  let rollies = 0;
  let available = 0;

  data.map((item: any) => {
    capacity = capacity + item.transportTypeCapacity;
    rollies = rollies + item.rollies;
    available = available + item.emptyRollies;
  });

  return {
    trips: data.length,
    capacity,
    rollies,
    available
  };
};
