import { AxiosInstance } from 'axios';
import { VehicleTypeDTO } from 'dtos';

import { BaseApiService } from './BaseApiService';

export class VehicleTypeService extends BaseApiService<VehicleTypeDTO> {
  constructor(instance: AxiosInstance, url: string) {
    super(instance, url);
  }
}
