import {AxiosInstance, AxiosResponse} from 'axios';
import {REPORTS_SSE} from "config";
import {AUTH_HEADER} from 'const';
import {fetchEventSource} from '@microsoft/fetch-event-source';
import {parseSseError} from "../../helpers/parseSseError";

export class ReportsService {
    constructor(readonly instance: AxiosInstance, readonly url: string, readonly additionalUrl: string) {}

    public async downloadReports<T>(id: number, filters: T): Promise<string> {
        if (REPORTS_SSE) {
            return new Promise((resolve, reject) => {
              fetchEventSource(`${this.url}/downloadV2/${id}`, {
                    method: 'POST',
                    headers: {
                        'x-auth-token': this.instance.defaults.headers[AUTH_HEADER],
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({filters} ),
                    onmessage(msg) {
                      if (msg.event === 'Message') {
                            resolve(msg.data);
                        } else if(msg.event === 'Error') {
                        const error = parseSseError(msg);
                        reject(error);
                      }
                    },
                    onerror(err) {
                        reject(err);
                    }
                })
            });
        } else {
            return this.instance.post(`${this.url}/download/${id}`, { filters }).then((res: AxiosResponse<string>) => {
                return res.data;
            });
        }
    }
}

