import dayjs from 'dayjs';
import { NewLogisticalGuidanceDTO } from 'dtos';
import { EDate } from 'enums';
import { IFormData, ILogisticalGuidance, IMapper, INewLogisticalGuidance } from 'interfaces';

export class LogisticalGuidanceMapper implements IMapper<ILogisticalGuidance> {
  static toDto(formData: IFormData): NewLogisticalGuidanceDTO {
    const { itemDetails, ...other } = formData;

    return {
      ...itemDetails['value'],
      ...other,
      deliveryDateFrom: dayjs(other['deliveryDateFrom'] as Date).format(EDate.YYYY_MM_DD),
      deliveryTimeFrom: dayjs(other['deliveryTimeFrom'] as Date).format(EDate.HH_MM),
      deliveryDateTo: dayjs(other['deliveryDateTo'] as Date).format(EDate.YYYY_MM_DD),
      deliveryTimeTo: dayjs(other['deliveryTimeTo'] as Date).format(EDate.HH_MM)
    };
  }

  static toUpdateLogisticalGuidanceDto(formData: IFormData | ILogisticalGuidance): NewLogisticalGuidanceDTO {
    const {
      activityCode,
      correctiveFactor,
      deliveryDateFrom,
      deliveryDateTo,
      deliveryTimeFrom,
      deliveryTimeTo,
      itemCode,
      itemLvCode
    } = formData;

    return {
      activityCode,
      correctiveFactor: `${+correctiveFactor}`,
      deliveryDateFrom,
      deliveryDateTo,
      deliveryTimeFrom,
      deliveryTimeTo,
      itemCode,
      itemLvCode
    } as INewLogisticalGuidance;
  }
}
