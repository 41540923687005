import { useContext } from 'react';

import { storesContext } from '../contexts/stores-context';
import { RootStore } from '../stores';

interface IContext {
  rootStore: RootStore;
}

export const useStores = (): IContext => useContext(storesContext);
