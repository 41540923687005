import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { lazy } from 'react';
import { render } from 'react-dom';

import { LazyLoader } from './src/components/LazyLoader';
import { Root } from './src/modules/Root';

const App = LazyLoader(lazy(() => import(/* webpackChunkName: 'App' */ './src/modules/App')));

const AppCmp = observer(() => {
  return (
    <Root>
      <App />
    </Root>
  );
});

render(<AppCmp />, document.getElementById('app'));
