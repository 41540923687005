import { AxiosError } from 'axios';
import { NODE_ENV } from 'config';
import { makeNotification } from 'helpers';
import { INotification, IResponseError, IRootStore } from 'interfaces';
import { action, makeObservable, observable } from 'mobx';

import { AsyncStore } from './AsyncStore';

export class BaseStore extends AsyncStore {
  public rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;
    makeObservable(this, {
      rootStore: observable,
      notificationHandler: action.bound,
      errorHandler: action.bound
    });
  }

  public notificationHandler(notification: INotification): void {
    this.rootStore.notificationStore.showMessage(notification);
  }

  public errorHandler(errorInstance: AxiosError, withRedirect: boolean = true): void {
    if (NODE_ENV !== 'test') console.error(errorInstance);
    // get translation code of error message when it comes from API (depends on a server response)
    // or pass a regular error message
    const errorMessage: string =
      (errorInstance?.response?.data as IResponseError)?.error?.code?.toString() ||
      (errorInstance?.response?.data as IResponseError)?.code?.toString() ||
      errorInstance.message;
    const notification = makeNotification(errorMessage, 'error');
    this.notificationHandler(notification);

    if (!withRedirect) return;

    const statusCode = errorInstance?.response?.status || 0;

    if (statusCode === 401 || statusCode === 403) this.rootStore.authStore.logout();
  }
}
