import { AxiosInstance } from 'axios';
import { SlotbookingDTO } from 'dtos';

import { BaseApiService } from './BaseApiService';

export class SlotbookingService extends BaseApiService<SlotbookingDTO> {
  constructor(instance: AxiosInstance, url: string) {
    super(instance, url);
  }
}
