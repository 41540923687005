import { ENABLE_LOGGER } from 'config';
import isEqual from 'fast-deep-equal';
import { IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { CSSProperties } from 'react';
import { TTableData } from 'types';

import { BaseManager } from './BaseManager';

export class TableDataManager extends BaseManager {
  public row: TTableData = null;
  private rowId: string = null;
  private highlightedRowsIds: Array<number | string> = [];
  private rowStyles: CSSProperties = {};

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      row: observable,
      currentRow: computed,
      setCurrentRow: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);

    reaction(
      () => this.currentRow?.id,
      (value, previousValue, reaction) => {
        if (ENABLE_LOGGER) console.log({ previousValue, value, reaction });
      },
      {
        equals: isEqual
      }
    );
  }

  public get currentRow(): TTableData {
    return this.row;
  }

  public get currentRowId(): string {
    return this.rowId;
  }

  public setCurrentRow(row: TTableData): void {
    this.row = row;
  }

  public setCurrentRowId(rowId: string): void {
    this.rowId = rowId;
  }

  public get highlightedRows(): Array<string | number> {
    return this.highlightedRowsIds;
  }

  public get rowCSS(): CSSProperties {
    return this.rowStyles;
  }

  public updateHighlightedRowsIds(ids: Array<string | number>): void {
    this.highlightedRowsIds = ids;
  }

  public setRowStyles(css: CSSProperties): void {
    this.rowStyles = css;
  }
}
