import { ProfileDTO, UserDTO } from 'dtos';
import { IFormSubmitData, ITenant, ITenantOrganizationInfo, IUser } from 'interfaces';

export class FormDataNormalizer {
  static toProfile({ formData }: IFormSubmitData, tenants: ITenant[], user: IUser | null): ProfileDTO {
    const tenantOrganizationInfo: ITenantOrganizationInfo = {};
    for (const tenant of tenants) {
      if (formData[`organizationId-${tenant.id}`] || !formData.isGlobalAdmin) {
        tenantOrganizationInfo[`${tenant.id}`] = {
          organizationId:
            +formData[`organizationId-${tenant.id}`] ||
            user?.tenantOrganizationInfo[tenant.id]?.organizationId ||
            undefined
        };
      }
    }

    return {
      active: formData.active as boolean,
      email: formData.email.toString(),
      firstName: formData.firstName.toString(),
      lastName: formData.lastName.toString(),
      role: formData.role.toString(),
      ...(formData.isEdit ? {} : { tenantOrganizationInfo })
    };
  }

  static parseToFormData = (props: any): FormData => {
    const formData = new FormData();

    Object.keys(props).forEach((key: string): void => {
      props[key] && formData.append(key, props[key]);
    });

    return formData;
  };

  static toUser({ formData, id }: IFormSubmitData, tenants: ITenant[], user: IUser | null): UserDTO {
    return {
      ...FormDataNormalizer.toProfile({ formData, id }, tenants, user),
      organizationType: user ? user.organizationType : formData.organizationType.toString()
    };
  }
}
