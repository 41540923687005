import { AxiosInstance, AxiosResponse } from 'axios';
import { IAppointment } from 'interfaces/models/IAppointment';
import { IAppointmentsResponse } from 'interfaces/models/IAppointmentsResponse';
import { IEmailConfirmation } from 'interfaces/models/IEmailConfirmation';
import { INewAppointment } from 'interfaces/models/INewAppointment';
import { ITimeslotResponse } from 'interfaces/models/ITimeslotResponse';

export class AppointmentsService {
  constructor(readonly instance: AxiosInstance, readonly url: string, readonly additionalUrl: string) {}

  public async getAppointments(date: string): Promise<IAppointment[]> {
    return await this.instance.get(`${this.url}?date=${date}`).then((res: AxiosResponse<IAppointmentsResponse>) => {
      return res.data.appointments;
    });
  }

  public async getTimeslots(endDate: string, startDate: string, vehicleTypeCode: string): Promise<ITimeslotResponse[]> {
    return await this.instance
      .get(
        `${this.additionalUrl}/timeslots?endDate=${endDate}&startDate=${startDate}&vehicleTypeCode=${vehicleTypeCode}`
      )
      .then((res: AxiosResponse<ITimeslotResponse[]>) => {
        return res.data;
      });
  }

  public async newAppointment(data: INewAppointment): Promise<IAppointment> {
    return await this.instance.post(this.url, data).then((res: AxiosResponse<{ appointment: IAppointment }>) => {
      return res.data.appointment;
    });
  }

  public async emailConfirmation(data: IEmailConfirmation): Promise<void> {
    return await this.instance.post(`${this.additionalUrl}/confirmation`, data).then((res: AxiosResponse<void>) => {
      return res.data;
    });
  }

  public async updateAppointment(data: INewAppointment, appYear: string, appNo: string): Promise<IAppointment> {
    return await this.instance
      .put(`${this.url}/${appYear}/${appNo}`, data)
      .then((res: AxiosResponse<{ appointment: IAppointment }>) => {
        return res.data.appointment;
      });
  }

  public async deleteAppointment(appYear: string, appNo: string): Promise<void> {
    return await this.instance.delete(`${this.url}/${appYear}/${appNo}`).then((res: AxiosResponse<void>) => {
      return res.data;
    });
  }

  public async getAppointment(appYear: string, appNo: string): Promise<IAppointment> {
    return await this.instance
      .get(`${this.url}/${appYear}/${appNo}`)
      .then((res: AxiosResponse<{ appointment: IAppointment }>) => {
        return res.data.appointment;
      });
  }

  public async downloadAppointments(startDate: string, endDate: string): Promise<string> {
    return this.instance
      .get(`${this.url}/download?startDate=${startDate}&endDate=${endDate}`)
      .then((res: AxiosResponse<string>) => {
        return res.data;
      });
  }
}
