import {EventSourceMessage} from "@microsoft/fetch-event-source";
import {AxiosError} from "axios";

export const parseSseError = (msg: EventSourceMessage) : AxiosError => {
  const errorData = JSON.parse(msg.data);
  const error = new Error("SSE error") as AxiosError;
  error.isAxiosError = true;
  error.response = {
    config: {},
    headers: {},
    status: errorData.code,
    statusText: errorData.message,
    data: errorData
  };
  error.message = errorData.message
  return error;
}
