import { ENABLE_LOGGER } from 'config';
import isEqual from 'fast-deep-equal';
import { ILoad, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseManager } from './BaseManager';

export class LoadManager extends BaseManager {
  public currentLoadId: string = null;
  public isDeleted: boolean = false;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      currentLoadId: observable,
      isDeleted: observable,
      currentLoad: computed,
      setCurrentLoadId: action.bound,
      onDelete: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);

    reaction(
      () => this.currentLoad?.loadCode,
      (value, previousValue, reaction) => {
        if (ENABLE_LOGGER) console.log({ previousValue, value, reaction });
      },
      {
        equals: isEqual
      }
    );
  }

  public get currentLoad(): ILoad {
    return this.rootStore.loadStore.loadsMap.get(this.currentLoadId) || null;
  }

  public setCurrentLoadId(id: string): void {
    this.currentLoadId = id;
    this.rootStore.tableDataManager.setCurrentRowId(id);
  }

  public onDelete(id?: string): void {
    this.rootStore.loadStore.loadsMap.delete(id || this.currentLoadId);
    this.isDeleted = true;
  }
}
