import * as enLocale from 'dayjs/locale/en-gb';
import * as nlLocale from 'dayjs/locale/nl';

export const DEFAULT_LOCALE = 'en';

export const LIST_OF_LOCALE = {
  'en-US': enLocale, // no local formats if importing directly from 'dayjs/locale/en'
  'nl-NL': nlLocale,
  en: enLocale,
  nl: nlLocale
};

export const localeNormalizer = (lang: string): string => {
  const langs = {
    'en-US': 'en',
    'en-GB': 'en',
    en: 'en',
    'nl-NL': 'nl',
    nl: 'nl'
  };

  return langs[lang] || DEFAULT_LOCALE;
};
