import { AxiosInstance } from 'axios';
import { DistributionCenterDTO } from 'dtos';

import { BaseApiService } from './BaseApiService';

export class DistributionCenterService extends BaseApiService<DistributionCenterDTO> {
  constructor(instance: AxiosInstance, url: string) {
    super(instance, url);
  }
}
