import { ENABLE_LOGGER } from 'config';
import { DEFAULT_SELECT_VALUE } from 'const';
import { BaseDTO } from 'dtos';
import { ESelectOptionsData } from 'enums';
import { createSelectOptionsData } from 'helpers';
import { IBaseData, IError, IOrganizationStore, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { SelectData } from 'mui-rff';

import { BaseStore } from './BaseStore';

export class OrganizationStore extends BaseStore implements IOrganizationStore {
  private currentOrganization: IBaseData = null;
  public organizationTypesMap: Map<string, IBaseData> = new Map();
  public organizationNamesMap: Map<string, IBaseData> = new Map();
  /**
   * structure of `organizationPerTenantMap`:
   * parent Map is a Map of tenants
   * children are Maps of companies
   */
  public organizationPerTenantMap: Map<number, Map<string, IBaseData>> = new Map();
  public organizationNameSelectData: SelectData[][] = [
    [DEFAULT_SELECT_VALUE],
    [DEFAULT_SELECT_VALUE],
    [DEFAULT_SELECT_VALUE],
    [DEFAULT_SELECT_VALUE]
  ];
  public isLoading = true;
  public error: IError = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      organizationTypesMap: observable,
      organizationNameSelectData: observable,
      organizationPerTenantMap: observable,
      isLoading: observable,
      error: observable,
      isLoadingOrganizations: computed,
      isLoadingOrganization: computed,
      getOrganizations: action.bound,
      getOrganizationById: action.bound,
      getOrganizationNameSelectData: action.bound,
      getOrganizationTypeSelectData: action.bound,
      updateOrganisation: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get isLoadingOrganizations(): boolean {
    return this.getAsyncStatus('getOrganizations').loading;
  }

  public get isLoadingOrganization(): boolean {
    return this.getAsyncStatus('getOrganizationById').loading;
  }

  public async getOrganizations(): Promise<void> {
    this.setLoading('getOrganizations');
    try {
      const response = await this.rootStore.requester.organizationService.getAll();
      runInAction(() => {
        for (const organizationType of response['data']) {
          this.organizationTypesMap.set(organizationType, {
            id: organizationType,
            name: organizationType
          });
        }
        this.setSuccess('getOrganizations');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getOrganizations');
    }
  }

  public async getOrganizationById(id: string, tenantId: number, index: number): Promise<void> {
    if (this.currentOrganization?.id === id) return;

    this.setLoading('getOrganizationById');
    try {
      const response = await this.rootStore.requester.organizationService.getByTypeId(id, tenantId);
      if (response) {
        const tempMap: Map<string, IBaseData> = new Map();
        for (const organizationName of response as BaseDTO[]) {
          tempMap.set(organizationName.id, organizationName);
        }
        this.organizationPerTenantMap.set(tenantId, tempMap);
        this.organizationNameSelectData[index] = this.getOrganizationNameSelectData(tenantId);
        this.setSuccess('getOrganizationById');
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getOrganizationById');
    }
  }

  public updateOrganisation(data: IBaseData): void {
    this.currentOrganization = data;
  }

  public getOrganizationNameSelectData(tenantId: number): SelectData[] {
    const organizationNamesMap: Map<string, IBaseData> = this.organizationPerTenantMap.get(tenantId) || new Map();
    return createSelectOptionsData(organizationNamesMap, ESelectOptionsData.ORGANIZATION_NAME);
  }

  public getOrganizationTypeSelectData(): SelectData[] {
    return createSelectOptionsData(this.organizationTypesMap, ESelectOptionsData.ORGANIZATION_TYPE);
  }
}
