import { LOGISTICAL_GUIDANCE } from 'const/Router';
import { LogisticalGuidanceMapper } from 'helpers/mappers/LogisticalGuidanceMapper';
import { IFormData, ILogisticalGuidance, ILogisticalGuidanceStore, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { BaseStore } from './BaseStore';

export class LogisticalGuidanceStore extends BaseStore implements ILogisticalGuidanceStore {
  public logisticalGuidance: Map<string, ILogisticalGuidance> = new Map();
  public selectedLogisticalGuidance: ILogisticalGuidance[] = [];

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      logisticalGuidance: observable,
      selectedLogisticalGuidance: observable,
      getLogisticalGuidance: action.bound,
      setSelectedLogisticalGuidance: action.bound,
      deleteLogisticalGuidance: action.bound,
      createLogisticalGuidance: action.bound,
      updateLogisticalGuidance: action.bound,
      isLogisticalGuidanceLoading: computed
    });
  }

  public setSelectedLogisticalGuidance(newSelectedLogisticalGuidance: ILogisticalGuidance[]): void {
    this.selectedLogisticalGuidance = newSelectedLogisticalGuidance;
  }

  public get isLogisticalGuidanceLoading(): boolean {
    return (
      this.getAsyncStatus('getLogisticalGuidance').loading ||
      this.getAsyncStatus('getLogisticalGuidance').loading ||
      this.getAsyncStatus('deleteLogisticalGuidance').loading
    );
  }

  public async getLogisticalGuidance(): Promise<void> {
    this.setLoading('getLogisticalGuidance');
    try {
      const logisticalGuidance = await this.rootStore.requester.logisticalGuidanceService.getLogisticalGuidance();
      if (logisticalGuidance) {
        runInAction(() => {
          this.logisticalGuidance.clear();
          for (const item of logisticalGuidance) {
            this.logisticalGuidance.set(item.colloGtin.toString(), item);
          }

          this.setSuccess('getLogisticalGuidance');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getLogisticalGuidance');
    }
  }

  public async deleteLogisticalGuidance(items: ILogisticalGuidance[]): Promise<void> {
    this.setLoading('deleteLogisticalGuidance');
    try {
      const parsedItems = items.map(LogisticalGuidanceMapper.toUpdateLogisticalGuidanceDto);
      await this.rootStore.requester.logisticalGuidanceService.deleteLogisticalGuidance(parsedItems);
      await this.getLogisticalGuidance();

      this.setSuccess('deleteLogisticalGuidance');
      this.rootStore.routeStore.navigate(LOGISTICAL_GUIDANCE);
    } catch (e) {
      this.errorHandler(e);
      this.setError('deleteLogisticalGuidance');
    }
  }

  public async createLogisticalGuidance(formData: IFormData): Promise<void> {
    this.setLoading('createLogisticalGuidance');
    try {
      const data = LogisticalGuidanceMapper.toDto(formData);
      const newLogisticalGuidance = await this.rootStore.requester.logisticalGuidanceService.createLogisticalGuidance(
        data
      );

      runInAction(() => {
        this.logisticalGuidance.set(newLogisticalGuidance.colloGtin, newLogisticalGuidance);
      });
      this.setSuccess('createLogisticalGuidance');
      this.rootStore.routeStore.navigate(LOGISTICAL_GUIDANCE);
    } catch (e) {
      this.errorHandler(e);
      this.setError('createLogisticalGuidance');
    }
  }

  public async updateLogisticalGuidance(formData: IFormData): Promise<void> {
    this.setLoading('updateLogisticalGuidance');
    try {
      const data = LogisticalGuidanceMapper.toUpdateLogisticalGuidanceDto(formData);
      const updatedLogisticalGuidance =
        await this.rootStore.requester.logisticalGuidanceService.updateLogisticalGuidance(data);
      await this.getLogisticalGuidance();

      runInAction(() => {
        this.logisticalGuidance.set(updatedLogisticalGuidance.colloGtin, updatedLogisticalGuidance);
      });

      this.setSuccess('updateLogisticalGuidance');
      this.rootStore.routeStore.navigate(LOGISTICAL_GUIDANCE);
    } catch (e) {
      this.errorHandler(e);
      this.setError('updateLogisticalGuidance');
    }
  }
}
