import { AxiosInstance, AxiosResponse } from 'axios';
import { CustomerSuppliersDTO } from 'dtos';
import { IVehicleTypes } from 'interfaces/models/IVehicleTypes';

export class CalendarService {
  constructor(readonly instance: AxiosInstance, readonly url: string) {}

  public async getVehicleTypes(): Promise<IVehicleTypes[]> {
    return await this.instance.get(`${this.url}/vehicleTypes`).then((res: AxiosResponse<IVehicleTypes[]>) => {
      return res.data;
    });
  }

  public async getSuppliers(supplierId: string): Promise<CustomerSuppliersDTO['authorizedOrgs']> {
    return await this.instance
      .get(`${this.url}/carriers/${supplierId}/suppliers`)
      .then((res: AxiosResponse<CustomerSuppliersDTO>) => {
        return res.data.authorizedOrgs;
      });
  }
}
