import {ENABLE_LOGGER} from 'config';
import {Dayjs} from 'dayjs';
import {EDate} from 'enums';
import {createEmailConfirmationTemplate, makeNotification} from 'helpers';
import {IRootStore} from 'interfaces';
import {IAppointment} from 'interfaces/models/IAppointment';
import {IAppointmentFormData} from 'interfaces/models/IAppointmentFormData';
import {IAppointmentSlotData} from 'interfaces/models/IAppointmentSlotData';
import {ICarrier} from 'interfaces/models/ICarrier';
import {ICustomerSupplier} from 'interfaces/models/ICustomerSupplier';
import {INewAppointment} from 'interfaces/models/INewAppointment';
import {ITimeslotResponse} from 'interfaces/models/ITimeslotResponse';
import {IVehicleTypes} from 'interfaces/models/IVehicleTypes';
import {IAppointmentsStore} from 'interfaces/stores/IAppointmentsStore';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {makeLoggable} from 'mobx-log';

import {BaseStore} from './BaseStore';

export class AppointmentsStore extends BaseStore implements IAppointmentsStore {
  public appointments: IAppointment[] = [];
  public vehicleTypes: IVehicleTypes[] = [];
  public customerSuppliers: ICustomerSupplier[] = [];
  public timeslots: ITimeslotResponse[] = [];
  public carriers: ICarrier[] = [];
  public selectedDay: number;
  public currentAppointment: IAppointment = null;
  public currentNewAppointment: IAppointment = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    const { dayjsInstance } = this.rootStore.localizationStore;
    this.selectedDay = dayjsInstance().tz('Europe/Amsterdam').valueOf();

    makeObservable(this, {
      appointments: observable,
      vehicleTypes: observable,
      carriers: observable,
      customerSuppliers: observable,
      selectedDay: observable,
      timeslots: observable,
      currentAppointment: observable,
      currentNewAppointment: observable,
      getAppointments: action.bound,
      getTimeSlots: action.bound,
      getVehicleTypes: action.bound,
      getCarriers: action.bound,
      getSuppliers: action.bound,
      setSelectedDay: action.bound,
      newAppointment: action.bound,
      deleteAppointment: action.bound,
      updateAppointment: action.bound,
      getAppointment: action.bound,
      emailConfirmation: action.bound,
      clearCurrentAppointment: action.bound,
      isLoadingAppointments: computed,
      isLoadingTimeSlots: computed
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public async getAppointments(date: string): Promise<void> {
    this.setLoading('getAppointments');

    try {
      const appointments = await this.rootStore.requester.appointmentsService.getAppointments(date);
      if (appointments) {
        runInAction(() => {
          this.appointments = appointments;
          this.setSuccess('getAppointments');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getAppointments');
    }
  }
  public async emailConfirmation(): Promise<void> {
    this.setLoading('emailConfirmation');

    try {
      const currentAppointment = this.currentNewAppointment;
      const user = this.rootStore.userStore.userProfile;
      const { dayjsInstance } = this.rootStore.localizationStore;

      const currentDay = dayjsInstance(currentAppointment.date).format(EDate.DD_MMMM_YYYY);

      const emailTemplate = createEmailConfirmationTemplate(user, currentAppointment, currentDay);

      const data = {
        subject: `Afspraak bevestiging ${currentAppointment.appYearNo} / Appointment se ${currentAppointment.appYearNo} `,
        htmlBody: emailTemplate
      };

      await this.rootStore.requester.appointmentsService.emailConfirmation(data);
      const notification = makeNotification('Email sent succesfully', 'success');

      this.notificationHandler(notification);
      this.setSuccess('emailConfirmation');
    } catch (e) {
      this.errorHandler(e);
      this.setError('emailConfirmation');
    }
  }

  public async getTimeSlots(start: string, end: string, typeCode: string): Promise<void> {
    this.setLoading('getTimeSlots');

    try {
      const timeslots = await this.rootStore.requester.appointmentsService.getTimeslots(end, start, typeCode);
      if (timeslots) {
        runInAction(() => {
          this.timeslots = timeslots;
          this.setSuccess('getTimeSlots');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getTimeSlots');
    }
  }

  public get isLoadingTimeSlots(): boolean {
    return this.getAsyncStatus('getTimeSlots').loading;
  }

  public get isLoadingAppointments(): boolean {
    return (
      this.getAsyncStatus('getAppointments').loading ||
      this.getAsyncStatus('newAppointment').loading ||
      this.getAsyncStatus('updateAppointment').loading ||
      this.getAsyncStatus('deleteAppointment').loading
    );
  }

  public async getVehicleTypes(): Promise<void> {
    this.setLoading('getVehicleTypes');

    try {
      const vehicleTypes = await this.rootStore.requester.calendarService.getVehicleTypes();
      if (vehicleTypes) {
        runInAction(() => {
          this.vehicleTypes = vehicleTypes;
          this.setSuccess('getVehicleTypes');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getVehicleTypes');
    }
  }

  public async getCarriers(): Promise<void> {
    this.setLoading('getCarriers');

    try {
      const currentTenant = this.rootStore.tenantStore.currentTenantId;
      const supplierId = this.rootStore.authStore.user.tenantOrganizationInfo[currentTenant].organizationId.toString();
      const carriers = await this.rootStore.requester.suppliersService.getCarriers(supplierId);
      if (carriers) {
        runInAction(() => {
          this.carriers = carriers;
          this.setSuccess('getCarriers');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getCarriers');
    }
  }

  public async getSuppliers(): Promise<void> {
    this.setLoading('getSuppliers');

    try {
      const currentTenant = this.rootStore.tenantStore.currentTenantId;
      const supplierId = this.rootStore.authStore.user.tenantOrganizationInfo[currentTenant].organizationId.toString();

      const customerSuppliers = await this.rootStore.requester.calendarService.getSuppliers(supplierId);
      if (customerSuppliers) {
        runInAction(() => {
          this.customerSuppliers = customerSuppliers;
          this.setSuccess('getSuppliers');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getSuppliers');
    }
  }

  public async newAppointment(formData: IAppointmentFormData, slotData: IAppointmentSlotData): Promise<string> {
    this.setLoading('newAppointment');
    try {
      const data: INewAppointment = {
        appointmentDetails: formData.rowsData,
        carrierId: formData.selectedCarrier,
        date: slotData.date,
        time: slotData.time,
        vehicleTypeCode: formData.selectedTransport
      };

      const appointment = await this.rootStore.requester.appointmentsService.newAppointment(data);
      if (appointment) {
        runInAction(() => {
          this.currentNewAppointment = appointment;
          this.setSuccess('newAppointment');
        });
        return appointment.appYearNo;
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('newAppointment');
    }
  }

  public async updateAppointment(formData: IAppointmentFormData, slotData: IAppointmentSlotData): Promise<string> {
    this.setLoading('updateAppointment');

    try {
      const data: INewAppointment = {
        appointmentDetails: formData.rowsData,
        carrierId: Number(formData.selectedCarrier),
        date: slotData.date,
        time: slotData.time,
        appReference: this.currentAppointment.appReference,
        vehicleTypeCode: formData.selectedTransport
      };
      const { appYear, appNo } = this.currentAppointment;

      const appointment = await this.rootStore.requester.appointmentsService.updateAppointment(data, appYear, appNo);
      if (appointment) {
        runInAction(() => {
          this.setSuccess('updateAppointment');
        });
        return appointment.appYearNo;
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('updateAppointment');
    }
  }

  public async deleteAppointment(appYear: string, appNo: string): Promise<void> {
    this.setLoading('deleteAppointment');

    try {
      await this.rootStore.requester.appointmentsService.deleteAppointment(appYear, appNo);
      runInAction(() => {
        this.setSuccess('deleteAppointment');
      });
      const { dayjsInstance } = this.rootStore.localizationStore;
      const date = dayjsInstance(this.selectedDay).format(EDate.YYYY_MM_DD);
      await this.getAppointments(date);
    } catch (e) {
      this.errorHandler(e);
      this.setError('deleteAppointment');
    }
  }

  public async getAppointment(appYear: string, appNo: string): Promise<void> {
    this.setLoading('getAppointment');

    try {
      const appointment = await this.rootStore.requester.appointmentsService.getAppointment(appYear, appNo);
      if (appointment) {
        runInAction(() => {
          this.currentAppointment = appointment;
          const { dayjsInstance } = this.rootStore.localizationStore;
          this.selectedDay = dayjsInstance(appointment.date, EDate.YYYY_MM_DD).valueOf();
          this.setSuccess('getAppointment');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getAppointment');
    }
  }

  public clearCurrentAppointment(): void {
    runInAction(() => {
      this.currentAppointment = null;
    });
  }

  public setSelectedDay(newDay: Dayjs): void {
    const { dayjsInstance } = this.rootStore.localizationStore;
    this.selectedDay = dayjsInstance(newDay).valueOf();
  }
}
