import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { MENU_ITEM_HEIGHT } from 'const/Dimentions';
import { IExtendedPalette } from 'interfaces/utils/IExtendedPalette';

import { extendedPalette } from './extendedPalette';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    customColors: IExtendedPalette;
  }

  interface PaletteOptions {
    customColors?: IExtendedPalette;
  }
}

export const theme = createTheme({
  palette: {
    customColors: extendedPalette,
    primary: {
      light: '#2e2c3d',
      main: '#23212e',
      dark: '#0e0f15',
      contrastText: '#f3f3f3'
    },
    secondary: {
      light: '#a3a1ad',
      main: '#27c24c',
      dark: '#696969',
      contrastText: '#888695'
    },
    error: {
      light: '#f05050',
      main: extendedPalette.error,
      dark: '#a94442'
    },
    warning: {
      light: '#dc2f34',
      main: extendedPalette.warning
    },
    info: {
      light: extendedPalette.silver,
      main: '#878786'
    },
    success: {
      light: '',
      main: extendedPalette.success,
      contrastText: '#fbfbfb'
    },
    text: {
      primary: '#58666e',
      secondary: '#555555'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          html: {
            width: '100%',
            height: '100%',
            fontSize: 16
          },
          body: {
            fontSize: '1rem',
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          },
          '@keyframes autofill': {
            to: {
              color: 'inherit',
              background: 'transparent'
            }
          },
          '*::-webkit-scrollbar': {
            background: '#fff',
            width: 16,
            opacity: 0.5
          },
          '*::-webkit-scrollbar-track': {
            background: '#fff'
          },
          '*::-webkit-scrollbar-track:hover': {
            background: '#f4f4f4'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#babac0',
            borderRadius: 16,
            minHeight: 50,
            border: '5px solid #fff'
          },
          '*:-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a0a0a5',
            border: '4px solid #f4f4f4'
          },
          '*:-webkit-scrollbar-button': {
            backgroundColor: 'none'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: 0,
          paddingBottom: 0,
          minHeight: '38px'
        },
        popper: {
          width: 'fit-content !important' // override default behavior
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          lineHeight: 2.5
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#a3a1ad',
          minWidth: 'auto'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(16px, 8px) scale(1)'
        },
        shrink: {
          transform: 'translate(16px, -14px) scale(0.75)'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&': {
            width: '100%',
            minHeight: '34px',
            fontSize: '14px',
            lineHeight: '1.428571429',
            color: '#555555',
            backgroundColor: ' #fff',
            border: `1px solid ${extendedPalette.silver}`,
            borderRadius: '4px',
            boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)'
          },
          '& textarea': {
            padding: 0,
            lineHeight: 1
          },
          '&.Mui-focused': {
            boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
            border: '1px solid #66afe9'
          },
          '&.Mui-error': {
            border: '1px solid #a94442',
            '&.Mui-focused': {
              boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483'
            }
          },
          '&.Mui-disabled': {
            '& input': {
              cursor: 'not-allowed'
            },
            background: '#eee'
          }
        },
        input: {
          padding: '6px 12px',
          '&::placeholder': {
            color: grey[500],
            opacity: 1
          }
        },
        notchedOutline: {
          display: 'none'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {}
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            cursor: 'not-allowed'
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 0
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          height: MENU_ITEM_HEIGHT,
          padding: '20px 15px',
          margin: 0,
          '&:hover .MuiListItemText-root, &:hover .MuiSvgIcon-root': {
            color: '#fff'
          },
          '&.Mui-selected': {
            background: extendedPalette.darkBlue,
            '&:hover': {
              background: '#2e2c3d'
            },
            '& .MuiListItemText-root, .MuiSvgIcon-root': {
              color: '#fff'
            }
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-h3': {
            fontSize: '18px',
            lineHeight: ' 20px',
            padding: '10px 0'
          },
          '&.MuiTypography-h4': {
            fontSize: '16px',
            lineHeight: ' 20px',
            padding: '10px 0'
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 700,
          color: '#e0e0e0',
          textTransform: 'uppercase',
          marginLeft: 15,
          whiteSpace: 'pre-wrap',
          '& .MuiTypography-root': {
            fontSize: 14,
            fontWeight: 700
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 37,
          height: 22,
          padding: 0,
          '& + .MuiTypography-root': {
            display: 'none'
          }
        },
        track: {
          borderRadius: 22 / 2,
          background: '#fff',
          border: '1px solid #f0f0f0',
          opacity: 1
        },
        thumb: {
          height: '18px',
          width: '18px',
          boxSizing: 'border-box',
          color: '#fff'
        },
        switchBase: {
          padding: 0,
          margin: 2,
          '&.Mui-disabled + .MuiSwitch-track': {
            cursor: 'not-allowed',
            background: '#000'
          },
          '&.Mui-checked': {
            transform: 'translateX(15px)',
            '& + .MuiSwitch-track': {
              backgroundColor: '#27c24c',
              opacity: 1
            }
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: extendedPalette.alto
        },
        light: {
          borderColor: extendedPalette.darkBlue
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 12px',
          fontSize: 14,
          lineHeight: 1.428571429,
          textTransform: 'initial',
          '& 	.MuiButton-startIcon': {
            ' > :nth-of-type(1)': {
              fontSize: '14px'
            }
          }
        },
        text: {
          color: '#878786',
          fontSize: 14,
          lineHeight: '20px',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline'
          }
        },
        outlined: {
          color: '#58666e',
          backgroundColor: '#f7f7f7',
          borderColor: '#dee5e7',
          borderBottomColor: '#d8e1e3',
          boxShadow: '0 1px 1px rgb(90 90 90 / 10%)',
          '&:hover': {
            backgroundColor: '#eaeaea',
            borderColor: '#c7d3d6'
          }
        }
      }
    }
  }
});
