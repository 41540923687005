import { EUserRole } from 'enums';

export const globalAdminRoles: string[] = [EUserRole.SUPERADMIN];

export const slotbookingRoles: string[] = [
    EUserRole.ADMIN_CUSTOMER,
    EUserRole.USER_CUSTOMER
];

export const dashboardRoles: string[] = [
  EUserRole.ADMIN_TRANSPORTER,
  EUserRole.USER_TRANSPORTER,
  EUserRole.SUPERADMIN,
  EUserRole.ADMIN_SUPPLIER,
  EUserRole.USER_SUPPLIER,
  EUserRole.ADMIN_CUSTOMER,
  EUserRole.USER_CUSTOMER,
  EUserRole.ADMIN_CARRIER
];

export const calendarRoles: string[] = [
  EUserRole.ADMIN_CARRIER,
  EUserRole.USER_CARRIER,
  EUserRole.ADMIN_SUPPLIER,
  EUserRole.USER_SUPPLIER
];

export const itemManagementRoles: string[] = [EUserRole.ADMIN_SUPPLIER, EUserRole.USER_SUPPLIER];

export const stockManagementRoles: string[] = [
  EUserRole.ADMIN_SUPPLIER,
  EUserRole.USER_SUPPLIER,
  EUserRole.ADMIN_CUSTOMER,
  EUserRole.USER_CUSTOMER
];

export const codeDispensationRoles: string[] = [EUserRole.ADMIN_CUSTOMER, EUserRole.USER_CUSTOMER];

export const logisticalGuidanceRoles: string[] = [EUserRole.ADMIN_SUPPLIER, EUserRole.USER_SUPPLIER];

export const transportManagementRoles: string[] = [
  EUserRole.SUPERADMIN,
  EUserRole.ADMIN_TRANSPORTER,
  EUserRole.USER_TRANSPORTER
];

export const userManagementRoles: string[] = [
  EUserRole.SUPERADMIN,
  EUserRole.ADMIN_TRANSPORTER,
  EUserRole.ADMIN_SUPPLIER,
  EUserRole.ADMIN_CUSTOMER,
  EUserRole.ADMIN_CARRIER,
];

export const authorizationSupplierRoles: string[] = [EUserRole.SUPERADMIN];

export const authorizationCarrierRoles: string[] = [EUserRole.ADMIN_SUPPLIER];

export const maintenanceRoles: string[] = [EUserRole.SUPERADMIN];

export const reportsRoles: string[] = [
  EUserRole.USER_TRANSPORTER,
  EUserRole.ADMIN_TRANSPORTER,
  EUserRole.ADMIN_SUPPLIER,
  EUserRole.USER_SUPPLIER,
  EUserRole.ADMIN_CARRIER,
  EUserRole.USER_CARRIER
];

export const mailingListsRoles: string[] = [EUserRole.SUPERADMIN];
export const mailingsListsSupplierRoles: string[] = [EUserRole.ADMIN_SUPPLIER];
