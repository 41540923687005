import { AxiosInstance, AxiosResponse } from 'axios';
import { AUTH_HEADER } from 'const';
import { IAuth, ILoginDTO } from 'interfaces';

import { BaseApiService } from './BaseApiService';

export class AuthService extends BaseApiService<IAuth> {
  constructor(instance: AxiosInstance, url: string) {
    super(instance, url);
  }

  public async login(email: string, password: string): Promise<ILoginDTO> {
    let token: string;
    const user = await this.instance
      .post(`${this.url}/login`, { email, password })
      .then((res: AxiosResponse<IAuth>) => {
        token = res.headers[AUTH_HEADER];
        return res.data;
      });
    return { user, token };
  }

  public async forgotPassword(email: string): Promise<boolean> {
    return await this.instance
      .post(`${this.url}/resetPassword`, { email })
      .then(() => true)
      .catch(() => false);
  }

  public async setPassword(newPassword1: string, newPassword2: string, id: string): Promise<any> {
    return await this.instance
      .post(`${this.url}/resetPassword/confirm/${id}`, { newPassword1, newPassword2 })
      .then(({ data }) => data);
  }

  public async logout(): Promise<null> {
    return await this.instance.post(`${this.url}/logout`).then(() => {
      return null;
    });
  }

  public async changePassword(oldPassword: string, newPassword1: string, newPassword2: string): Promise<any> {
    return await this.instance
      .put(`${this.url}/userProfile/password`, { oldPassword, newPassword1, newPassword2 })
      .then(({ data }) => data);
  }
}
