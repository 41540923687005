import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { AUTO_HIDE_DURATION } from 'const';
import { INotification } from 'interfaces';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  notification: INotification;
  open: boolean;
  handleClose: () => void;
}

export const Notification = memo(({ notification, open, handleClose }: IProps): JSX.Element => {
  const { t } = useTranslation();

  if (!notification) return null;

  return (
    <Snackbar
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={notification.type} sx={{ width: 1 }}>
        {t(notification.message)}
      </MuiAlert>
    </Snackbar>
  );
});
