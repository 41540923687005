import { ENABLE_LOGGER } from 'config';
import { ESelectOptionsData } from 'enums';
import { createSelectOptionsData } from 'helpers';
import { IBaseEntity, IError, IRootStore, ISupplierStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { SelectData } from 'mui-rff';

import { BaseStore } from './BaseStore';

export class SupplierStore extends BaseStore implements ISupplierStore {
  public suppliersMap: Map<string, IBaseEntity> = new Map();
  public isLoading = true;
  public error: IError = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      suppliersMap: observable,
      isLoading: observable,
      error: observable,
      isLoadingSuppliers: computed,
      getSuppliers: action.bound,
      getSuppliersSelectData: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get isLoadingSuppliers(): boolean {
    return this.getAsyncStatus('getSuppliers').loading;
  }

  public async getSuppliers(): Promise<void> {
    this.setLoading('getSuppliers');
    try {
      const response = await this.rootStore.requester.suppliersService.getAll();
      runInAction(() => {
        for (const supplier of response) {
          this.suppliersMap.set(supplier.id.toString(), supplier);
        }
        this.setSuccess('getSuppliers');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getSuppliers');
    }
  }

  public getSuppliersSelectData(): SelectData[] {
    return createSelectOptionsData(this.suppliersMap, ESelectOptionsData.SUPPLIER, true);
  }
}
