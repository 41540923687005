import { IDataStatus, IRootStore } from 'interfaces';
import { action, computed, makeObservable, observable } from 'mobx';
import { DataStatus } from 'models/DataStatus';

export class BaseManager {
  public rootStore: IRootStore;
  public dataStatusMap: Map<string, IDataStatus> = new Map();

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      rootStore: observable,
      dataStatusMap: observable,
      isCreate: computed,
      isDelete: computed,
      isEdit: computed,
      toggleCreateStatus: action.bound,
      toggleDeleteStatus: action.bound,
      toggleEditStatus: action.bound
    });
  }

  public get isCreate(): boolean {
    return this.getDataStatus('toggleCreateStatus').create;
  }

  public get isDelete(): boolean {
    return this.getDataStatus('toggleDeleteStatus').del;
  }

  public get isEdit(): boolean {
    return this.getDataStatus('toggleEditStatus').edit;
  }

  public toggleCreateStatus(): void {
    if (this.isCreate) {
      this.removeDataStatus('toggleCreateStatus');
    } else {
      this.setCreate('toggleCreateStatus');
    }
  }

  public toggleDeleteStatus(): void {
    if (this.isDelete) {
      this.removeDataStatus('toggleDeleteStatus');
    } else {
      this.setDelete('toggleDeleteStatus');
    }
  }

  public toggleEditStatus(): void {
    if (this.isEdit) {
      this.removeDataStatus('toggleEditStatus');
    } else {
      this.setEdit('toggleEditStatus');
    }
  }

  public getDataStatus(key: string): IDataStatus {
    return this.dataStatusMap.get(key) || new DataStatus(false, false, false);
  }

  public removeDataStatus(key: string): void {
    this.dataStatusMap.delete(key);
  }

  public setCreate(key: string): void {
    this.dataStatusMap.set(key, new DataStatus(true, false, false));
  }

  public setDelete(key: string): void {
    this.dataStatusMap.set(key, new DataStatus(false, true, false));
  }

  public setEdit(key: string): void {
    this.dataStatusMap.set(key, new DataStatus(false, false, true));
  }
}
