// General
export const ROOT_ROUTE: string = '/';
export const DASHBOARD_EDIT: string = '/edit-dashboard';
export const LOGIN_ROUTE: string = '/login';
export const FORGOT_PASSWORD_ROUTE: string = '/forgotPassword';
export const ACTIVATE_USER_ROUTE: string = '/activateUser';
export const USER_PROFILE_ROUTE: string = '/userProfile';
export const AUTHORIZATION_SUPPLIER: string = '/supplierAuthorization';
export const AUTHORIZATION_NEW_SUPPLIER: string = `${AUTHORIZATION_SUPPLIER}/:id/new`;
export const USER_MANAGEMENT: string = '/adminManagement';
export const CREATE_NEW_USER: string = `${USER_MANAGEMENT}/new`;
export const EDIT_USER: string = `${USER_MANAGEMENT}/edit/:id`;
export const MAILINGLISTS: string = '/mailingList';
export const MAILINGLISTS_SUPPLIER = `${MAILINGLISTS}/admin`;
export const MAINTENANCE: string = '/maintenance';
export const NEW_DOWNTIME: string = 'new';
export const EDIT_DOWNTIME: string = 'edit/:maintenanceId';
export const LOAD_OVERVIEW: string = '/transportManagement';
export const NEW_LOAD: string = `${LOAD_OVERVIEW}/new`;
export const TRANSPORT_MANAGEMENT: string = `${LOAD_OVERVIEW}/cut`;
export const EDIT_TRIP_DETAILS_BASE: string = `${LOAD_OVERVIEW}/edit`;
export const EDIT_TRIP_DETAILS: string = `${EDIT_TRIP_DETAILS_BASE}/:date/:id`;
export const UNLINKED_ORDERS: string = `${LOAD_OVERVIEW}/unlinked`;
export const NOT_FOUND: string = '*';
export const CALENDAR_ROUTE: string = '/appointments';
export const APPOINTMENTS_WIZARD_ROUTE: string = '/appointmentsWizard';
export const ITEM_MANAGEMENT_ROUTE: string = '/itemManagement';
export const REPORTS: string = '/reports';
export const AUTHORIZATION_CARRIER: string = '/carrierAuthorization';
export const AUTHORIZATION_NEW_CARRIER: string = `${AUTHORIZATION_CARRIER}/new`;
export const STOCK_MANAGEMENT: string = '/stockManagement';
export const STOCK_MANAGEMENT_BY_SUPPLIER: string = '/stockManagement/:id';
export const SELECT_SUPPLIER: string = `${STOCK_MANAGEMENT}SelectSupplier`;
export const LOGISTICAL_GUIDANCE: string = '/logisticManagement';
export const NEW_LOGISTICAL_GUIDANCE: string = `${LOGISTICAL_GUIDANCE}/new`;
export const EDIT_LOGISTICAL_GUIDANCE: string = `${LOGISTICAL_GUIDANCE}/edit`;
export const CODE_DISPENSATION_BASE: string = '/lastShipmentDateChange';
export const CODE_DISPENSATION: string = `${CODE_DISPENSATION_BASE}/select`;
export const CODE_DISPENSATION_BY_SUPPLIER: string = `/${CODE_DISPENSATION_BASE}/:id`;
export const SLOTBOOKING: string = '/slotbooking';
