import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import { DespatchedLoadsDTO, ReceiptsDetailsDTO } from 'dtos';
import { EDate } from 'enums';

export class ReportsMapper {
  static toReceiptDetails(date: Date, reference: string): ReceiptsDetailsDTO {
    return {
      'RECEIPT DATE': [dayjs(date).format(EDate.YYYY_MM_DD)],
      'RECEIPT REFERENCE': [reference]
    };
  }

  static toStockTransactions(date: Date): AxiosRequestConfig {
    return {
      params: {
        owner: 'SUPPLIER',
        date: dayjs(date).format(EDate.YYYY_MM_DD)
      }
    };
  }

  static toStockOwner(owner: string): AxiosRequestConfig {
    return { params: { owner } };
  }

  static toDespatchedLoads(startDate: Date, endDate: Date): DespatchedLoadsDTO {
    return {
      AFLEVERDAG: [dayjs(startDate).format(EDate.YYYY_MM_DD), dayjs(endDate).format(EDate.YYYY_MM_DD)]
    };
  }
}
