import { ENABLE_LOGGER } from 'config';
import { ESelectOptionsData } from 'enums';
import { VehicleTypeMapper, createSelectOptionsData } from 'helpers';
import { IError, IRootStore, IVehicleType, IVehicleTypeStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { SelectData } from 'mui-rff';

import { BaseStore } from './BaseStore';

export class VehicleTypeStore extends BaseStore implements IVehicleTypeStore {
  public vehicleTypesMap: Map<string, IVehicleType> = new Map();
  public isLoading = true;
  public error: IError = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      vehicleTypesMap: observable,
      isLoading: observable,
      error: observable,
      isLoadingVehicleTypes: computed,
      getVehicleTypes: action.bound,
      getVehicleTypesSelectData: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get isLoadingVehicleTypes(): boolean {
    return this.getAsyncStatus('getVehicleTypes').loading;
  }

  public async getVehicleTypes(): Promise<void> {
    this.setLoading('getVehicleTypes');
    try {
      const response = await this.rootStore.requester.vehicleTypeService.getAll();
      runInAction(() => {
        for (const vehicleType of response) {
          this.vehicleTypesMap.set(vehicleType.id.toString(), VehicleTypeMapper.toState(vehicleType));
        }
        this.setSuccess('getVehicleTypes');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getVehicleTypes');
    }
  }

  public getVehicleTypesSelectData(withoutDefault: boolean = false): SelectData[] {
    return createSelectOptionsData(this.vehicleTypesMap, ESelectOptionsData.VEHICLE_TYPE, withoutDefault);
  }
}
