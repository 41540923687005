import { ENABLE_LOGGER } from 'config';
import { LOGIN_ROUTE } from 'const/Router';
import { EStorageKeys } from 'enums';
import { makeNotification } from 'helpers';
import { IAuth, IAuthStore, IError, IRootStore, IUserBase } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseStore } from './BaseStore';

const USER_THEME_KEY = 'theme';

export class AuthStore extends BaseStore implements IAuthStore {
  public user: IAuth = null;
  public isLoading = true;
  public shouldChangePassword = false;
  public error: IError = null;
  public themeType: any = this.getTheme();

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      user: observable,
      isLoading: observable,
      shouldChangePassword: observable,
      error: observable,
      themeType: observable,
      isAuthorized: computed,
      getTheme: action.bound,
      changeTheme: action.bound,
      login: action.bound,
      forgotPassword: action.bound,
      setPassword: action.bound,
      logout: action.bound,
      changePassword: action.bound,
      getMe: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public getTheme(): string {
    return (this.rootStore.localStorageStore.getItem(USER_THEME_KEY) as string) || 'light';
  }

  public changeTheme(themeType: string): void {
    this.rootStore.localStorageStore.setItem(USER_THEME_KEY, themeType);
    this.themeType = themeType;
  }

  get isAuthorized(): boolean {
    return !!this.user;
  }

  public async login(name: string, password: string): Promise<void> {
    this.setLoading('login');
    this.error = null;
    try {
      const { user, token } = await this.rootStore.requester.authService.login(name, password);

      if (user) {
        runInAction(() => {
          this.user = user;
        });

        this.rootStore.localStorageStore.setItem(EStorageKeys.USER, user);
        this.rootStore.localStorageStore.setItem(EStorageKeys.TOKEN, token);
        this.rootStore.requester.setToken(token);
        this.rootStore.userStore.getUserProfile();
        this.rootStore.menuManager.setDefaultMenu();
        this.setSuccess('login');
      }
    } catch (e) {
      if (e?.response?.data?.code === 200) {
        this.error = new Error('MESSAGE_EMAIL_PASSWORD_COMBI');
      }
      this.errorHandler(e, false);
      this.setError('login');
    }
  }

  public async forgotPassword(email: string): Promise<void> {
    this.setLoading('forgotPassword');
    try {
      const result = await this.rootStore.requester.authService.forgotPassword(email);
      const notification = result ? makeNotification('MESSAGE', 'success') : makeNotification('1', 'error');
      this.notificationHandler(notification);
      this.setSuccess('forgotPassword');
    } catch (e) {
      this.errorHandler(e);
      this.setError('forgotPassword');
    }
  }

  public async setPassword(newPassword1: string, newPassword2: string, id: string): Promise<void> {
    this.setLoading('setPassword');
    try {
      const result = await this.rootStore.requester.authService.setPassword(newPassword1, newPassword2, id);
      
      if (result === '') {
        const notification = makeNotification('USER_INIT_PASSWORD', 'success');
        this.notificationHandler(notification);
        this.setSuccess('setPassword');
        this.rootStore.routeStore.navigate(LOGIN_ROUTE);
      } else {
        throw new Error(result.data);
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('setPassword');
    }
  }

  public async changePassword(
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ): Promise<void> {
    this.setLoading('changePassword');
    try {
      await this.rootStore.requester.authService.changePassword(oldPassword, newPassword, newPasswordConfirmation);
      const notification = makeNotification('MESSAGE_PASSWORD_UPDATED', 'success');
      this.notificationHandler(notification);
      this.setSuccess('changePassword');
    } catch (e) {
      this.errorHandler(e);
      this.setError('changePassword');
    }
  }

  public async logout(): Promise<void> {
    this.setLoading('logout');
    try {
      await this.rootStore.requester.authService.logout();
    } catch (e) {
      this.errorHandler(e);
      this.setError('logout');
    } finally {
      this.rootStore.localStorageStore.removeItem(EStorageKeys.USER);
      this.rootStore.localStorageStore.removeItem(EStorageKeys.TOKEN);
      this.rootStore.localStorageStore.removeItem(EStorageKeys.USER_PROFILE);
      this.rootStore.localStorageStore.removeItem(EStorageKeys.TENANTS);

      runInAction(() => {
        this.user = null;
      });

      this.rootStore.requester.removeToken();
      this.rootStore.userStore.setUserProfile(null);
      this.rootStore.tenantStore.clearCurrentTenant();
      this.rootStore.dashboardStore.resetStore();

      this.setSuccess('logout');
    }
  }

  public async getMe(force?: boolean): Promise<void> {
    if (!force && this.user) {
      return;
    }
    this.setLoading('getMe');
    try {
      const user = this.rootStore.localStorageStore.getItem(EStorageKeys.USER) as IAuth;
      const token = this.rootStore.localStorageStore.getItem(EStorageKeys.TOKEN) as string;
      const userProfile = this.rootStore.localStorageStore.getItem(EStorageKeys.USER_PROFILE) as IUserBase;

      if (user && token && userProfile) {
        let currentTenant = this.rootStore.localStorageStore.getItem(`${EStorageKeys.CURRENT_TENANT}${userProfile.id}`);

        runInAction(() => {
          this.user = user;
        });

        if (user.maintenanceOngoing.includes(currentTenant as number)) {
          currentTenant = this.rootStore.authStore.user.availableTenants[0];
        }

        this.rootStore.requester.setToken(token);
        this.rootStore.userStore.setUserProfile(userProfile);
        currentTenant && this.rootStore.tenantStore.setCurrentTenantId(Number(currentTenant), false);
        this.setSuccess('getMe');
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getMe');
    }
  }
}
