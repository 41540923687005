import { AxiosInstance, AxiosResponse } from 'axios';
import { IDownloadsItem } from 'interfaces/models/IDownloadsItem';
import { INewDownloadsItem } from 'interfaces/models/INewDownloadsItem';

import { BaseApiService } from './BaseApiService';

export class DownloadsService extends BaseApiService<IDownloadsItem> {
  constructor(readonly instance: AxiosInstance, readonly url: string) {
    super(instance, url);
  }

  public async addDownloadsItem(item: INewDownloadsItem): Promise<IDownloadsItem> {
    return this.instance.post(`${this.url}`, item).then((res: AxiosResponse<IDownloadsItem>) => {
      return res.data;
    });
  }
}
