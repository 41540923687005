import { CONTENT_URL_BASE } from 'config';
import { IUser } from 'interfaces';
import { IAppointment } from 'interfaces/models/IAppointment';

export const createEmailConfirmationTemplate = (
  user: IUser,
  currentAppointment: IAppointment,
  currentDay: string
): string => {
  return `<div>
  <!--<body style="width: 100%; height: 100%; background: #f6f6f6" bgcolor="#f6f6f6">-->
  <table style="padding: 20px;" bgcolor="#f6f6f6" width="100%">
      <tr><td>
          <table bgcolor="white" width="800" style="margin: 0 auto; padding: 20px;" centered>
              <tr>
                  <td>
                      <!-- Title Table -->
                      <table width="800">
                          <tr>
                              <td><h3>Beste ${user.firstName} ${user.lastName}</h3></td>
                          </tr>
                          <tr><td>Uw afspraak is bevestigd met bevestigingsnummer: ${
                            currentAppointment.appYearNo
                          }</td></tr>
                      </table>
                      <!-- /End title table -->
                      <!-- Header table -->
                      <table width="800">
                          <tr>
                              <td style="border-bottom: 1px solid #ddd;" colspan="3">
                                  <h4>Algemene gegevens:</h4>
                              </td>
                          </tr>
                          <tr>
                              <td style="width: 30%; padding-top: 15px;">Datum: </td>
                              <td style="width: 30%; padding-top: 15px;">${currentDay}, ${currentAppointment.time}</td>
                              <!-- Confirmation message -->
                              <td rowspan="3" style="width: 40%; padding-top: 15px;">
                              </td>
                              <!-- /End confirmation message -->
                          </tr>
                          <tr>
                              <td style="padding-top: 5px" ng-if="vm.data.email.generalData.isSupplier">
                                 Vervoerder:
                              </td>
                              <td style="padding-top: 5px" ng-if="vm.data.email.generalData.isSupplier">
                              ${currentAppointment.carrierName}
                              </td>
                          </tr>
                          <tr>
                              <td style="padding-top: 5px">Transport Type:</td>
                              <td style="padding-top: 5px">${currentAppointment.transportType}</td>
                          </tr>
                      </table>
                      <table style="padding-top:40px; padding-bottom: 15px;" width="800">
                          <tr>
                              <td style="border-bottom: 1px solid #ddd; padding-bottom: 10px; padding-top: 30px; width: 100%" colspan="3">
                                  Leveringsoverzicht:
                              </td>
                          </tr>
                          <tr>
                              <td style="padding-top: 15px">REFERENTIE:</td>
                              <td style="padding-top: 15px">PALLETS/ROLLIES:</td>
                          </tr>

                          ${currentAppointment.appointmentDetails.map(
                            detail =>
                              `<tr ng-repeat="row in vm.data.email.gridData.grid track by $index">
                                <td>${detail.referenceNo}</td>
                                <td>${detail.noOfPallets}</td>
                                <td>${detail.transportQuantityUnit === 'ROL' ? 'Rollies' : 'Pallets'}</td>
                            </tr>`
                          )}
                      </table>
                      <!-- /end appointment data table -->
  
                      <!-- Data footer table -->
                      <table width="800">
                          <tr>
                              <td style="width: 90%; text-align: left; font-weight: bold; text-align: right; padding-top: 50px;">
                                  TOTAAL:
                              </td>
                              <td style="width: 10%; text-align: right; font-weight: bold; padding-top:50px;">
                              ${currentAppointment.totalNoOfPallets}
                              </td>
                          </tr>
                      </table>
                      <!-- /End data footer table -->
  
                      <!-- Email Footer table -->
                      <table style="padding-top: 45px; padding-bottom: 45px;">
                          <tr>
                              <td class="content-wrapper-row-container" width="600">
                                  Met vriendelijke groet,
                                  <br />
                                  GXO Logistics
                                  <br />
                                  <br />
                                  <img src="${CONTENT_URL_BASE}/dadb755c0fc26a17e780.png" alt="GXO logo" style="width: 150px;" />
                              </td>
                          </tr>
                      </table>
                      <!-- End Email tooter table -->
  
              </td>
          </tr>
          <tr>
              <td>
              <table bgcolor="white" width="800" style="margin: 0 auto; padding: 20px;" centered>
                  <tr>
                      <td>
                          <!-- Title Table -->
                          <table width="800">
                              <tr>
                                  <td><h3>Dear ${user.firstName} ${user.lastName}</h3></td>
                              </tr>
                              <tr><td>Your appointment has been confirmed with confirmation number: ${
                                currentAppointment.appYearNo
                              }</td></tr>
                          </table>
                          <!-- /End title table -->
                          <!-- Header table -->
                          <table width="800">
                              <tr>
                                  <td style="border-bottom: 1px solid #ddd;" colspan="3">
                                      <h4>General details:</h4>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="width: 30%; padding-top: 15px;">Date: </td>
                                  <td style="width: 30%; padding-top: 15px;">${currentDay}, ${
    currentAppointment.time
  }</td>
                                  <!-- Confirmation message -->
                                  <td rowspan="3" style="width: 40%; padding-top: 15px;">
                                  </td>
                                  <!-- /End confirmation message -->
                              </tr>
                              <tr>
                                  <td style="padding-top: 5px" ng-if="vm.data.email.generalData.isSupplier">
                                      Carrier:
                                  </td>
                                  <td style="padding-top: 5px" ng-if="vm.data.email.generalData.isSupplier">
                                  ${currentAppointment.carrierName}
                                  </td>
                              </tr>
                              <tr>
                                  <td style="padding-top: 5px">Transport Type:</td>
                                  <td style="padding-top: 5px">${currentAppointment.transportType}</td>
                              </tr>
                          </table>
                          <table style="padding-top:40px; padding-bottom: 15px;" width="800">
                              <tr>
                                  <td style="border-bottom: 1px solid #ddd; padding-bottom: 10px; padding-top: 30px; width: 100%" colspan="3">
                                      OVERVIEW:
                                  </td>
                              </tr>
                              <tr>
                                  <td style="padding-top: 15px">REFERENCE</td>
                                  <td style="padding-top: 15px">PALLETS/ROLLIES</td>
                              </tr>
                              ${currentAppointment.appointmentDetails.map(
                                detail =>
                                  `<tr ng-repeat="row in vm.data.email.gridData.grid track by $index">
                                    <td>${detail.referenceNo}</td>
                                    <td>${detail.noOfPallets}</td>
                                    <td>${detail.transportQuantityUnit === 'ROL' ? 'Rollies' : 'Pallets'}</td>
                                </tr>`
                              )}
                          </table>
                          <!-- /end appointment data table -->
  
                          <!-- Data footer table -->
                          <table width="800">
                              <tr>
                                  <td style="width: 90%; text-align: left; font-weight: bold; text-align: right; padding-top: 50px;">
                                      TOTAL:
                                  </td>
                                  <td style="width: 10%; text-align: right; font-weight: bold; padding-top:50px;">
                                  ${currentAppointment.totalNoOfPallets}
                                  </td>
                              </tr>
                          </table>
                          <!-- /End data footer table -->
  
                          <!-- Email Footer table -->
                          <table style="padding-top: 45px; padding-bottom: 45px;">
                              <tr>
                                  <td class="content-wrapper-row-container" width="600">
                                      Kind Regards,
                                      <br />
                                      GXO Logistics
                                      <br />
                                      <br />
                                      <img src="${CONTENT_URL_BASE}/dadb755c0fc26a17e780.png" alt="GXO logo" style="width: 150px;" />
                                  </td>
                              </tr>
                      </table>
                      <!-- End Email tooter table -->
              </td>
          </tr>
      </table>
      <!-- /End content wrapper -->
      </td></tr>
  </table>
  </div>
  <!--</body>-->`;
};
