export const enum EStorageKeys {
  TOKEN = 'token',
  USER = 'user',
  USER_PROFILE = 'userProfile',
  CURRENT_TENANT = 'currentTenant',
  TENANTS = 'tenants',
  CURRENT_LANG = 'currentLang',
  LOAD_DATE = 'loadDate',
  LOAD_FILTER_OBJECT = 'loadFilterObject',
  LOAD_FILTER_STRING = 'loadFilterString'
}
