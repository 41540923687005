import { ENABLE_LOGGER } from 'config';
import { UserDTO } from 'dtos';
import { EStorageKeys } from 'enums';
import { FormDataNormalizer } from 'helpers';
import { IError, IFormSubmitData, IRootStore, ITenantOrganizationInfo, IUser, IUserBase, IUserStore } from 'interfaces';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseStore } from './BaseStore';

export class UserStore extends BaseStore implements IUserStore {
  public usersMap: Map<string, IUser> = new Map();
  public userProfile: IUser = null;
  public isLoading = true;
  public error: IError = null;

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      usersMap: observable,
      userProfile: observable,
      isLoading: observable,
      error: observable,
      isLoadingUsers: computed,
      getUsers: action.bound,
      getUserById: action.bound,
      getUserProfile: action.bound,
      updateUserProfile: action.bound,
      createUser: action.bound,
      updateUser: action.bound,
      deleteUser: action.bound,
      setUserProfile: action.bound
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public get isLoadingUsers(): boolean {
    return this.getAsyncStatus('getUsers').loading;
  }

  public async getUsers(): Promise<void> {
    this.setLoading('getUsers');
    try {
      const response = await this.rootStore.requester.userService.getAll();
      runInAction(() => {
        for (const user of response) {
          this.usersMap.set(user.id.toString(), this.normalizeUser(user));
        }
        this.setSuccess('getUsers');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getUsers');
    }
  }

  public async getUserById(id: string): Promise<void> {
    this.setLoading('getUserById');
    try {
      const response = (await this.rootStore.requester.userService.getById(id)) as UserDTO;
      runInAction(() => {
        this.usersMap.set(response.id.toString(), this.normalizeUser(response));
        this.setSuccess('getUserById');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getUserById');
    }
  }

  public async getUserProfile(): Promise<void> {
    this.setLoading('getUserProfile');
    try {
      const response = await this.rootStore.requester.userService.getProfile();
      if (response) {
        await this.rootStore.tenantStore.getAvailableTenants(response.id);

        runInAction(() => {
          this.userProfile = this.normalizeUser(response);
        });

        this.rootStore.localStorageStore.setItem(EStorageKeys.USER_PROFILE, response);
        this.setSuccess('getUserProfile');
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('getUserProfile');
    }
  }

  public async updateUserProfile(data: IFormSubmitData): Promise<void> {
    this.setLoading('updateUserProfile');
    try {
      const tenants = this.rootStore.tenantStore.getTenantsList();
      const body = FormDataNormalizer.toProfile(data, tenants, null);
      const response = await this.rootStore.requester.userService.updateProfile(body);
      runInAction(() => {
        this.userProfile = response;
        this.rootStore.localStorageStore.setItem(EStorageKeys.USER_PROFILE, response);
        this.setSuccess('updateUserProfile');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('updateUserProfile');
    }
  }

  public async createUser(data: IFormSubmitData): Promise<void> {
    this.setLoading('createUser');
    try {
      const tenants = this.rootStore.tenantStore.getTenantsList();
      const currentUser = !data.formData.isGlobalAdmin ? this.userProfile : null;
      const body = FormDataNormalizer.toUser(data, tenants, currentUser);
      const response = await this.rootStore.requester.userService.create(body);
      if (response) {
        this.getUsers();
        this.setSuccess('createUser');
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('createUser');
    }
  }

  public async updateUser(data: IFormSubmitData): Promise<void> {
    this.setLoading('updateUser');
    try {
      const tenants = this.rootStore.tenantStore.getTenantsList();
      const currentUser = !data.formData.isGlobalAdmin ? this.userProfile : null;
      const body = FormDataNormalizer.toProfile(data, tenants, currentUser);
      const response = await this.rootStore.requester.userService.update(data.id.toString(), body);
      if (response) {
        this.getUsers();
        this.setSuccess('updateUser');
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('updateUser');
    }
  }

  public async deleteUser(data: IFormSubmitData): Promise<void> {
    this.setLoading('deleteUser');
    try {
      const userId: string = data.id.toString();
      await this.rootStore.requester.userService.deleteUser(userId);
      this.rootStore.userManager.onDelete(userId);
      this.getUsers();
      this.setSuccess('deleteUser');
    } catch (e) {
      this.errorHandler(e);
      this.setError('deleteUser');
    }
  }

  private normalizeUser(user: IUser): IUser {
    const tenants = this.rootStore.tenantStore.getTenantsList();
    const tenantOrganizationInfo: ITenantOrganizationInfo = {};
    const organizationName: string[] = [];
    for (const tenant of tenants) {
      const organization = user.tenantOrganizationInfo[tenant.id];
      if (organization) {
        tenantOrganizationInfo[`organizationId-${tenant.id}`] = organization.organizationId;
        organizationName.push(`${tenant.title}: ${organization.organizationName}`);
      }
    }

    return {
      ...user,
      name: `${user.firstName} ${user.lastName}`,
      withPassword: user.awaitingPassword ? 'NO' : 'YES',
      ...tenantOrganizationInfo,
      organizationName
    };
  }

  public setUserProfile(userProfile: IUserBase): void {
    this.userProfile = userProfile;
  }
}
