/**
 * Patterns for a `format` helper from the `dayjs` library.
 * Reference: https://day.js.org/docs/en/display/format
 */
export const enum EDate {
  DD_MMMM_YYYY = 'DD MMMM YYYY', // '20 May 2022'
  DD_MMMM_YY = 'DD MMMM `YY', // '20 May `22'
  DDDD = 'dddd', // full day name, eg: 'Monday'
  DDDD_DD_MMMM_HH_MM = 'dddd, DD MMMM HH:mm', // 'Monday, 20 May 2022 13:10'
  DDDD_MMMM_DD = 'dddd MMMM DD', // 'Monday May 20'
  DDD_DD_MM = 'ddd DD-MM', // 'Mon 20-05'
  HH_MM = 'HH:mm', // '13:10'
  YYYY_MM_DD = 'YYYY-MM-DD', // '2022-05-20'
  YYYY_MM_DD_HH = 'YYYY-MM-DD HH', // '2022-05-20 10'
  YYYY_MM_DD_CUT = 'YYYYMMDD', // '20220405'
  YYYYMMDDHHMM = 'YYYYMMDDhhmm', // '202204151526'
  YYYY_MM_DD_HHMMSS = 'YYYY-MM-DD_hhmmss', // '2022-05-24_142412',
  YYYY_MM_DD_HH_MM = 'YYYY-MM-DD HH:mm', // 2022-05-20 13:10
  DD_MM_YYYY = 'DD-MM-YYYY', // 01 07 2022
  DD_MM_YY = 'DD-MM-YY', // 20-05-22
  MMM_DD_YYYY = 'MMM DD YYYY' // Jul 05 2022
}
