import { AxiosInstance, AxiosResponse } from 'axios';
import { ILogisticalGuidance, ILogisticalGuidanceResponse, INewLogisticalGuidance } from 'interfaces';

import { BaseApiService } from './BaseApiService';

export class LogisticalGuidanceService extends BaseApiService<ILogisticalGuidance> {
  constructor(readonly instance: AxiosInstance, readonly url: string) {
    super(instance, url, {});
  }

  public async getLogisticalGuidance(): Promise<ILogisticalGuidance[]> {
    return this.instance.get(this.url).then((res: AxiosResponse<ILogisticalGuidanceResponse>) => {
      return res.data.logisticalGuidance;
    });
  }

  public async createLogisticalGuidance(body: INewLogisticalGuidance): Promise<ILogisticalGuidance> {
    return this.instance.post(this.url, body).then((res: AxiosResponse<ILogisticalGuidance>) => {
      return res.data;
    });
  }

  public async updateLogisticalGuidance(body: INewLogisticalGuidance): Promise<ILogisticalGuidance> {
    return this.instance.put(this.url, body).then((res: AxiosResponse<ILogisticalGuidance>) => {
      return res.data;
    });
  }

  public async deleteLogisticalGuidance(body: INewLogisticalGuidance[]): Promise<ILogisticalGuidance> {
    return this.instance
      .delete(this.url, {
        data: body
      })
      .then((res: AxiosResponse<ILogisticalGuidance>) => {
        return res.data;
      });
  }
}
