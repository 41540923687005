import { ENABLE_LOGGER } from 'config';
import { FormDataNormalizer, makeNotification } from 'helpers';
import { IRootStore } from 'interfaces';
import { IContact } from 'interfaces/models/IContact';
import { IDownloadsItem } from 'interfaces/models/IDownloadsItem';
import { INewDownloadsItem } from 'interfaces/models/INewDownloadsItem';
import { INewsItem } from 'interfaces/models/INewsItem';
import { IDashboardStore } from 'interfaces/stores/IDashboardStore';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { BaseStore } from './BaseStore';

export class DashboardStore extends BaseStore implements IDashboardStore {
  public news: INewsItem[] = [];
  public contact: IContact = null;
  public downloads: IDownloadsItem[] = [];

  constructor(rootStore: IRootStore) {
    super(rootStore);
    makeObservable(this, {
      news: observable,
      downloads: observable,
      contact: observable,
      requestNews: action.bound,
      requestDownloads: action.bound,
      deleteDownloadsItem: action.bound,
      addDownloadsItem: action.bound,
      updateNewsItem: action.bound,
      deleteNewsItem: action.bound,
      getContact: action.bound,
      updateContact: action.bound,
      resetStore: action.bound,
      isLoadingDownloads: computed,
      isLoadingNews: computed,
      isUpdatingNews: computed,
      isUpdatingContact: computed
    });

    if (ENABLE_LOGGER) makeLoggable(this);
  }

  public async requestNews(): Promise<void> {
    this.setLoading('requestNewsItem');
    try {
      const newsItems = await this.rootStore.requester.newsService.getAll();
      if (newsItems) {
        runInAction(() => {
          this.news = newsItems;
          this.setSuccess('requestNewsItem');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('requestNewsItem');
    }
  }

  public async requestDownloads(): Promise<void> {
    this.setLoading('requestDownloadsItem');

    try {
      const downloadsItems = await this.rootStore.requester.downloadsService.getAll();
      if (downloadsItems) {
        runInAction(() => {
          this.downloads = downloadsItems;
          this.setSuccess('requestDownloadsItem');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('requestDownloadsItem');
    }
  }

  public async deleteDownloadsItem(id: number): Promise<void> {
    this.setLoading('deleteDownloadsItem');

    try {
      await this.rootStore.requester.downloadsService.delete(`${id}`);
      runInAction(() => {
        this.downloads = this.downloads.filter(item => item.id !== id);
        this.setSuccess('deleteDownloadsItem');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('deleteDownloadsItem');
    }
  }

  public async addDownloadsItem(item: INewDownloadsItem): Promise<void> {
    this.setLoading('addDownloadsItem');

    try {
      const newItem = await this.rootStore.requester.downloadsService.addDownloadsItem(item);
      runInAction(() => {
        this.downloads.push(newItem);
        this.setSuccess('addDownloadsItem');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('addDownloadsItem');
    }
  }

  public async updateNewsItem(news: INewsItem): Promise<void> {
    this.setLoading('updateNewsItem');

    try {
      const body = FormDataNormalizer.parseToFormData(news);
      const newsItem = await this.rootStore.requester.newsService.updateNewsItem(body);
      if (newsItem) {
        const foundedIndex = this.news.findIndex(item => item.id === newsItem.id);
        runInAction(() => {
          if (foundedIndex !== -1) this.news[foundedIndex] = newsItem;
          else this.news.push(newsItem);
          const notification = makeNotification('NEWS_UPDATED', 'success');
          this.notificationHandler(notification);
          this.setSuccess('updateNewsItem');
        });
      }
    } catch (e) {
      this.errorHandler(e);
      this.setError('updateNewsItem');
    }
  }

  public async deleteNewsItem(id: number): Promise<void> {
    this.setLoading('deleteNewsItem');

    try {
      await this.rootStore.requester.newsService.delete(`${id}`);
      runInAction(() => {
        this.news = this.news.filter(item => item.id !== id);
        const notification = makeNotification('NEWS_REMOVED', 'success');
        this.notificationHandler(notification);
        this.setSuccess('deleteNewsItem');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('deleteNewsItem');
    }
  }

  public async getContact(): Promise<void> {
    this.setLoading('getContact');

    try {
      const newContact = await this.rootStore.requester.contactService.getAll();
      runInAction(() => {
        this.contact = newContact as unknown as IContact;
        this.setSuccess('getContact');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('getContact');
    }
  }

  public async updateContact(contact: IContact): Promise<void> {
    this.setLoading('updateContact');

    try {
      const newContact = await this.rootStore.requester.contactService.update('', contact);
      runInAction(() => {
        this.contact = newContact;
        const notification = makeNotification('CONTACT_UPDATED', 'success');
        this.notificationHandler(notification);
        this.setSuccess('updateContact');
      });
    } catch (e) {
      this.errorHandler(e);
      this.setError('updateContact');
    }
  }

  public get isUpdatingContact(): boolean {
    return this.getAsyncStatus('updateContact').loading;
  }

  public get isLoadingDownloads(): boolean {
    return (
      this.getAsyncStatus('requestDownloadsItem').loading ||
      this.getAsyncStatus('deleteDownloadsItem').loading ||
      this.getAsyncStatus('addDownloadsItem').loading
    );
  }

  public get isLoadingNews(): boolean {
    return this.getAsyncStatus('requestNewsItem').loading;
  }

  public get isUpdatingNews(): boolean {
    return this.getAsyncStatus('updateNewsItem').loading;
  }

  public resetStore(): void {
    this.news = [];
    this.contact = null;
    this.downloads = [];
  }
}
