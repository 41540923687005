import { AxiosInstance, AxiosResponse } from 'axios';
import { MaintenanceStatusDTO } from 'dtos';
import { IMaintenance } from 'interfaces';

import { BaseApiService } from './BaseApiService';

export class MaintenanceService extends BaseApiService<IMaintenance> {
  private additionalUrl: string = null;
  constructor(instance: AxiosInstance, url: string, additionalUrl: string) {
    super(instance, url);
    this.additionalUrl = additionalUrl;
  }

  public async getStatus(): Promise<MaintenanceStatusDTO> {
    return await this.instance.get(`${this.additionalUrl}`).then((res: AxiosResponse<MaintenanceStatusDTO>) => {
      return res.data;
    });
  }
}
